import React from 'react';

import RecentlyViewedCarousel from '@nm-namshi-frontend/core/page_components/shared/RecentlyViewedCarousel';

import { ModuleHeader } from './shared';
import { TRecentlyViewedWidget } from '../../types/CarouselWidgets';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';

type TProps = {
    analyticsData: TModuleAnalytics;
    moduleData: TRecentlyViewedWidget;
    renderModuleLayout: TModuleLayout;
};

const RecentlyViewedWidget: React.FC<TProps> = ({ analyticsData, moduleData, renderModuleLayout: ModuleLayout }) => {
    const { moduleHeader, textColor, titleText, type, widgetId } = moduleData;

    const Wrapper: React.FC<{ children: React.ReactNode; isLoading?: boolean }> = ({ children, isLoading = false }) => (
        <ModuleLayout>
            <ModuleHeader
                moduleHeader={moduleHeader || { titleText }} // Title text is contained in root object for some modules
                textColor={textColor}
                isLoading={isLoading}
                analyticsData={{
                    ...analyticsData,
                    contentModuleType: type,
                    widgetId,
                    column: 0,
                }}
            />
            {children}
        </ModuleLayout>
    );

    return (
        <RecentlyViewedCarousel
            analyticsData={{ pageType: analyticsData.pageType, widgetId }}
            renderWrapper={Wrapper}
            hideTitle
        />
    );
};

export default RecentlyViewedWidget;
