import { useState } from 'react';

import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import {
    copyShareText,
    shareOnEmail,
    shareOnFacebook,
    shareOnWhatsapp,
} from '@nm-namshi-frontend/core/utils/shareUtils';

import styles from './ShareOptions.module.scss';

type TShareIconsProps = {
    referralMessage: string;
    shareUrl: string;
    onShare?: () => void;
};

const ShareOptions: React.FC<TShareIconsProps> = ({ onShare, referralMessage, shareUrl }) => {
    const [linkCopied, setLinkCopied] = useState(false);
    const { t } = useTranslation();

    const tooltipRef = useOnclickOutside(() => {
        setLinkCopied(false);
    });

    const copyToClipboard = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (!linkCopied) {
            setLinkCopied(true);
            setTimeout(() => {
                setLinkCopied(false);
            }, 2000);
        }
        copyShareText({ shareUrl, referralMessage });
        onShare?.();
    };

    return (
        <>
            <button type="button" className={styles.roundedIcon} onClick={copyToClipboard}>
                <Icon size={16} name="link" />
                <div ref={tooltipRef} className={clsx(styles.tooltip, linkCopied && styles.active)}>
                    {t('copied')}
                </div>
            </button>
            <button
                type="button"
                className={styles.roundedIcon}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    shareOnFacebook({ shareUrl, referralMessage });
                    onShare?.();
                }}
            >
                <Icon size={12} name="facebook" />
            </button>
            <button
                type="button"
                className={styles.roundedIcon}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    shareOnWhatsapp({ shareUrl, referralMessage });
                    onShare?.();
                }}
            >
                <Icon size={20} name="whatsapp" />
            </button>
            <button
                type="button"
                className={styles.roundedIcon}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    shareOnEmail({ subject: t('shareEmailSubject'), shareUrl, referralMessage });
                    onShare?.();
                }}
            >
                <Icon size={22} name="mail" />
            </button>
        </>
    );
};

export default ShareOptions;
