import React, { useRef } from 'react';

import useIsInViewport from '@nm-namshi-frontend/core/hooks/useIsInViewport';

type TProps = {
    children: React.ReactNode;
    placeholderHeight: string;
};

const RenderOnView: React.FC<TProps> = ({ children, placeholderHeight }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { isViewed } = useIsInViewport({ ref });

    return (
        <>
            <div ref={ref} data-testid="render-on-view" style={isViewed ? {} : { minHeight: placeholderHeight }} />

            {isViewed && children}
        </>
    );
};

export default RenderOnView;
