import React from 'react';

import { useTranslation } from 'react-i18next';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import useCatalogStore from '@nm-namshi-frontend/core/stores/useCatalogStore';
import Button from '@nm-namshi-frontend/core/components/Button';

import styles from './EmptySizesModal.module.scss';

type TProps = {
    open: boolean;
    onClose: () => void;
};

const EmptySizesModal: React.FC<TProps> = ({ onClose, open }) => {
    const { t } = useTranslation('catalog');
    const { setEditSizesModal } = useCatalogStore();

    return (
        <Modal title={t('mySizes')} open={open} onClose={onClose}>
            <div className={styles.details}>{t('emptySizes')}</div>
            <Button variant="tertiary" onClick={() => setEditSizesModal({ show: true, onSuccess: onClose })}>
                {t('addMySizes')}
            </Button>
        </Modal>
    );
};

export default EmptySizesModal;
