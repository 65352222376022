import React from 'react';

import clsx from 'clsx';

import { isExternalLink } from '@nm-namshi-frontend/widgets/src/utils/url';
import { getWidgetImageUrl } from '@nm-namshi-frontend/core/utils/images';
import { TModuleHeader } from '@nm-namshi-frontend/widgets/src/types/common';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { shouldRenderModuleHeader } from '@nm-namshi-frontend/widgets/src/utils/rules';
import Skeleton from '@nm-namshi-frontend/core/components/loaders/Skeleton';

import { TModuleAnalytics } from '../../../../types/ModulesRenderer';
import ModuleLink from '../ModuleLink';
import CouponHeader from './CouponHeader';
import ModuleBrandFollowToggle from './ModuleBrandFollowToggle';
import ModuleImage from '../ModuleImage';
import ModuleShare from '../ModuleShare';
import styles from './ModuleHeader.module.scss';

type TProps = {
    moduleHeader?: TModuleHeader;
    textColor?: string;
    isCarouselLayout?: boolean;
    isLoading?: boolean;
    analyticsData: TModuleAnalytics & {
        contentModuleType: string;
        column?: number;
        widgetId?: number;
    };
};

const ModuleHeader: React.FC<TProps> = ({
    analyticsData,
    isCarouselLayout,
    isLoading,
    moduleHeader = {},
    textColor,
}) => {
    const { isMobile } = useAppContext();

    if (!shouldRenderModuleHeader(moduleHeader)) return null;

    if (isLoading)
        return (
            <div className={clsx(styles.container, isCarouselLayout && styles.noPadding, !isMobile && styles.desktop)}>
                <Skeleton width="150px" height="30px" />
            </div>
        );

    const carouselId = moduleHeader?.assetId?.toString();
    const color = moduleHeader?.titleColor;

    const renderLogo = () => {
        const { logoLinkUrl, logoSize = 40, logoUrl, titleText } = moduleHeader;

        if (!logoUrl) return null;

        if (logoLinkUrl) {
            return (
                <ModuleLink href={logoLinkUrl} style={{ width: logoSize }}>
                    <ModuleImage className={styles.logo} src={logoUrl} alt={titleText || ''} loading="lazy" />
                </ModuleLink>
            );
        }

        return (
            <div style={{ width: logoSize }}>
                <ModuleImage className={styles.logo} src={logoUrl} alt={titleText || ''} loading="lazy" />
            </div>
        );
    };

    const renderTitle = () => {
        const {
            couponCode,
            couponLabel,
            logoUrl,
            subtitleColor,
            subtitleText,
            subtitleTextFontSize,
            titleAlign,
            titleColor,
            titleImageUrl,
            titleText,
            titleTextFontSize,
        } = moduleHeader;

        if (titleText || logoUrl)
            return (
                <div className={clsx(styles.headerWrapper, titleAlign === 'center' && styles.centered)}>
                    {renderLogo()}
                    {titleText && (
                        <div>
                            <h3
                                id={carouselId}
                                className={styles.title}
                                style={{ color: titleColor || textColor, fontSize: titleTextFontSize }}
                            >
                                {titleText}
                            </h3>
                            {subtitleText && (
                                <span style={{ color: subtitleColor, fontSize: subtitleTextFontSize }}>
                                    {subtitleText}
                                </span>
                            )}
                        </div>
                    )}
                </div>
            );

        if (titleImageUrl)
            return (
                <div id={carouselId}>
                    <ModuleImage
                        className={styles.image}
                        src={getWidgetImageUrl(titleImageUrl)}
                        alt="Module Header"
                        loading="lazy"
                    />
                </div>
            );

        if (couponCode && couponLabel) return <CouponHeader moduleHeader={moduleHeader} />;

        return null;
    };

    const renderActions = () => {
        const { brandCode, linkText, linkUrl, shareUrl } = moduleHeader;
        const { pageType, widgetId } = analyticsData;

        return (
            <div className={styles.actionsWrapper}>
                {!!brandCode && (
                    <ModuleBrandFollowToggle moduleHeader={moduleHeader} analyticsData={{ widgetId, pageType }} />
                )}
                {!!shareUrl && (
                    <ModuleShare
                        color={color}
                        analyticsData={analyticsData}
                        utmSource={`module_share${widgetId ? `_w${widgetId}` : ''}`}
                    />
                )}
                {!!(linkText && linkUrl) && (
                    <ModuleLink
                        id={carouselId}
                        href={linkUrl || ''}
                        linkClass={styles.link}
                        style={{ color, '--color': color || 'black' } as any}
                        external={isExternalLink(linkUrl)}
                    >
                        {linkText}
                    </ModuleLink>
                )}
            </div>
        );
    };

    return (
        <div className={clsx(styles.container, isCarouselLayout && styles.noPadding, !isMobile && styles.desktop)}>
            {renderTitle()}
            {renderActions()}
        </div>
    );
};

export default ModuleHeader;
