import React from 'react';

import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Autoplay } from 'swiper/modules';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './EasyBannerScroller.module.scss';
import { BannerContent, ModuleHeader } from './shared';
import { TEasyBannerScroller } from '../../types/EasyBannerScroller';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';

SwiperCore.use([Autoplay]);

type TProps = {
    moduleData: TEasyBannerScroller;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const EasyBannerScroller: React.FC<TProps> = ({ analyticsData, moduleData, renderModuleLayout: ModuleLayout }) => {
    const {
        banners = [],
        duration = 5000,
        moduleHeader,
        numPerView = 3.2,
        rowOffset = 40,
        rowsPerGroup = 2,
        textColor,
        type,
        widgetId,
    } = moduleData || {};

    const { isMobile } = useAppContext();

    // for cms builder
    const key = `${duration}-${numPerView}-${rowOffset}-${rowsPerGroup}`;

    const validBanners = banners.filter((banner) => !!banner.imageUrl || !!banner.imagePath);

    if (!validBanners.length) return null;

    const bannersRows: React.ReactElement[] = [];

    banners.forEach((_, index: number) => {
        if (index % rowsPerGroup !== 0) return;

        const bannersInRow = banners.slice(index, index + rowsPerGroup).map((banner, innerIndex) => (
            <div
                key={`banner-${index + innerIndex}`}
                className={clsx(styles.banner, {
                    [styles.rowOffsetStyle]: innerIndex % 2 === 0,
                })}
                style={
                    { '--row-offset': `${rowOffset}px`, '--inner-offset': isMobile ? 8.5 : 32 } as React.CSSProperties
                }
            >
                <BannerContent bannerData={banner} />
            </div>
        ));

        const bannersRow = (
            <SwiperSlide key={`row-${index + 1}`}>
                <div>{bannersInRow}</div>
            </SwiperSlide>
        );

        bannersRows.push(bannersRow);
    });

    // doing .map() to fix repeated key error
    const duplicatedBannersRows = [
        ...[...bannersRows, ...bannersRows].map((item, idx) => ({ ...item, key: `${item.key}-${idx}` })),
    ];
    bannersRows.push(...duplicatedBannersRows);

    return (
        <ModuleLayout>
            <div className={styles.wrapper}>
                <ModuleHeader
                    moduleHeader={moduleHeader}
                    textColor={textColor}
                    analyticsData={{
                        ...analyticsData,
                        contentModuleType: type,
                        widgetId,
                        column: 0,
                    }}
                />
                <div className={styles.bannerContainer}>
                    <Swiper
                        key={key}
                        loop
                        slidesPerView={numPerView}
                        loopAdditionalSlides={numPerView}
                        speed={duration}
                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,
                        }}
                    >
                        {bannersRows}
                    </Swiper>
                </div>
            </div>
        </ModuleLayout>
    );
};

export default EasyBannerScroller;
