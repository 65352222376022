import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import Counter from '@nm-namshi-frontend/core/page_components/shared/Counter';
import { TCountdownTimerProps } from '@nm-namshi-frontend/widgets/src/types/CountdownTimer';
import { sanitizeDimension } from '@nm-namshi-frontend/widgets/src/utils/static';
import { isCMS } from '@nm-namshi-frontend/core/utils/helper';

import styles from './BannerTimer.module.scss';

type TTime = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
};

const TimerUnitValues = {
    TIMER_UNITS: {
        DAYS: 'd',
        HOURS: 'h',
        MINUTES: 'm',
        SECONDS: 's',
    },
};

const calculateDistance = (date: string) => {
    const countDownDate = new Date(date).getTime();
    const now = new Date().getTime();
    return countDownDate - now;
};

const calculateTime = (distance: number, disableDays: boolean): TTime => {
    if (distance < 0)
        return {
            days: 0,
            minutes: 0,
            hours: 0,
            seconds: 0,
        };

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    // If the days are disabled, add them to hours
    if (disableDays) {
        hours = days * 24 + hours;
    }
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
        days,
        hours,
        minutes,
        seconds,
    };
};

function useTimer(expiry: string, disableDays = false): TTime {
    // settings seconds to 0 to avoid hydration failure
    const [time, setTime] = useState<TTime>({ ...calculateTime(calculateDistance(expiry), disableDays), seconds: 0 });

    useEffect(() => {
        const callback = (interval: number): void => {
            const distance = calculateDistance(expiry);
            const calcTime = calculateTime(distance, disableDays);
            setTime(calcTime);

            if (distance < 0 && interval) {
                clearInterval(interval);
            }
        };
        const interval = setInterval(callback, 1000);

        return () => clearInterval(interval);
    }, [disableDays, expiry]);

    return time;
}

const zerofill = (value = 0): string => (value < 10 && value > -1 ? `0${value}` : `${value}`);

const BannerTimer: React.FC<TCountdownTimerProps> = ({
    bgColor,
    disableDays = true,
    displayUnitsBottom,
    expiry,
    fontColor,
    fontFamily,
    fontSize,
    showLabels = true,
}) => {
    const time = useTimer(expiry, disableDays);

    // timer expired
    if (!Object.values(time).some(Boolean)) {
        if (isCMS()) return <div>Empty Timer</div>;

        return null;
    }

    const { days, hours, minutes, seconds } = time;

    const displayUnit = displayUnitsBottom;
    const separator = true;
    const showTimerUnits = showLabels && !displayUnit;

    const style = {
        ...(bgColor
            ? {
                  backgroundColor: bgColor,
                  borderRadius: '30px',
                  padding: '8px 24px',
              }
            : {}),

        ...(fontFamily
            ? {
                  fontFamily: `var(--font-${fontFamily}), var(--font-alexandria)`,
              }
            : {}),
    };

    return (
        <div className={styles.bannerTimerWrapper} style={style}>
            {!!days && days > 0 && !disableDays && (
                <div className={styles.container}>
                    <div className={styles.card}>
                        <div
                            className={styles.digits}
                            style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                        >
                            {zerofill(days) + (showTimerUnits ? TimerUnitValues.TIMER_UNITS.DAYS : '')}
                        </div>
                        {displayUnit && (
                            <div
                                className={styles.timeunit}
                                style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                            >
                                {days === 1 ? 'Day' : 'Days'}
                            </div>
                        )}
                    </div>
                    {separator && (
                        <span
                            className={styles.separator}
                            style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                        >
                            :
                        </span>
                    )}
                </div>
            )}

            {(!!hours || hours === 0) && (
                <div className={styles.container}>
                    <div className={styles.card}>
                        <div
                            className={styles.digits}
                            style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                        >
                            {zerofill(hours) + (showTimerUnits ? TimerUnitValues.TIMER_UNITS.HOURS : '')}
                        </div>
                        {displayUnit && (
                            <div
                                className={styles.timeunit}
                                style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                            >
                                {hours === 1 ? 'Hour' : 'Hours'}
                            </div>
                        )}
                    </div>
                    {separator && (
                        <span
                            className={styles.separator}
                            style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                        >
                            :
                        </span>
                    )}
                </div>
            )}

            {(!!minutes || minutes === 0) && (
                <div className={styles.container}>
                    <div className={styles.card}>
                        <div
                            className={styles.digits}
                            style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                        >
                            {zerofill(minutes) + (showTimerUnits ? TimerUnitValues.TIMER_UNITS.MINUTES : '')}
                        </div>
                        {displayUnit && (
                            <div
                                className={styles.timeunit}
                                style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                            >
                                {minutes === 1 ? 'Minute' : 'Minutes'}
                            </div>
                        )}
                    </div>
                    {separator && (
                        <span
                            className={styles.separator}
                            style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                        >
                            :
                        </span>
                    )}
                </div>
            )}

            {(!!seconds || seconds === 0) && (
                <div className={styles.container}>
                    <div className={styles.card}>
                        <div
                            style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                            className={clsx(styles.secondsUnit, styles.digits)}
                        >
                            <Counter color={fontColor} value={seconds} size={`${sanitizeDimension(fontSize)}px`} />
                            {showTimerUnits && <span>{TimerUnitValues.TIMER_UNITS.SECONDS}</span>}
                        </div>
                        {displayUnit && (
                            <div
                                className={styles.timeunit}
                                style={{ color: fontColor, fontSize: `${sanitizeDimension(fontSize)}px` }}
                            >
                                {seconds === 1 ? 'Second' : 'Seconds'}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BannerTimer;
