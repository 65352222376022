import { useMemo } from 'react';

import { TCrossProduct } from '@nm-namshi-frontend/widgets/src/types/common';
import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './CrossSearchProductBox.module.scss';
import ProductImage from './ProductImage';
import AlteredLink from '../AlteredLink';

type TCrossSearchProduct = {
    product: TCrossProduct;
    onClick?: () => void;
    url: string;
};

const CrossSearchProductBox: React.FC<TCrossSearchProduct> = ({ onClick, product, url }) => {
    const { brand, imageKeys, title } = product;
    const { locale } = useAppPathname();
    const { isMobile } = useAppContext();

    const content = useMemo(
        () => (
            <>
                <ProductImage altText={title || ''} imageKey={imageKeys?.[0]} size={IMAGE_SIZE.ML} />
                <div className={styles.titleBrandContainer}>
                    <span className={styles.brand}>{brand}</span>
                    <span className={styles.title}>{title}</span>
                </div>
            </>
        ),
        [brand, imageKeys, title],
    );

    if (isMobile) {
        return <div className={styles.container}>{content}</div>;
    }
    return (
        <AlteredLink href={url} locale={locale} className={styles.container} onClick={onClick}>
            {content}
        </AlteredLink>
    );
};

export default CrossSearchProductBox;
