import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { useQuery } from '@tanstack/react-query';

const useGetProducts = ({ skus, uri }: { uri?: string; skus?: string[] }) => {
    const res = useQuery(
        [uri],
        () =>
            getApiInstance().product.products({
                requestBody: {
                    uri,
                    skus,
                },
            }),
        { enabled: !!(uri || skus) },
    );

    return res;
};

export default useGetProducts;
