import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { SponsoredRequest } from '@nm-namshi-frontend/services/models/SponsoredRequest';
import { useQuery } from '@tanstack/react-query';

const useGetSponsoredProducts = (payload: SponsoredRequest) => {
    const res = useQuery(
        [REACT_QUERY_KEYS.GET_SPONSORED, payload],
        () =>
            getApiInstance().sponsored.getSponsoredForPdp({
                requestBody: payload,
            }),
        {
            staleTime: 0,
            cacheTime: 0,
        },
    );

    return res;
};

export default useGetSponsoredProducts;
