import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import useIsInViewport from '@nm-namshi-frontend/core/hooks/useIsInViewport';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { getCustomerProfilePercentageWithSizeResponse, isCMS } from '@nm-namshi-frontend/core/utils/helper';
import { getExternalHref } from '@nm-namshi-frontend/core/utils/url';
import AccountProfileIcon from '@nm-namshi-frontend/core/components/AccountProfileIcon';
import { TModuleAnalytics, TModuleLayout } from '@nm-namshi-frontend/widgets/src/types/ModulesRenderer';
import { TProfileProgressModule } from '@nm-namshi-frontend/widgets/src/types/ProfileProgressModule';
import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { ClientApp } from '@nm-namshi-frontend/core/types';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './ProfileProgressBarModule.module.scss';

type TProps = {
    analyticsData?: TModuleAnalytics;
    moduleData?: TProfileProgressModule;
    showEmail?: boolean;
    showModule?: boolean;
    iconSize?: number;
    containerClassName?: string;
    disabled?: boolean;
    renderModuleLayout?: TModuleLayout;
    moduleSize?: 'badge' | 'desktop';
};

const ArrowOutwardIcon = ({ color, isArabic }: { color?: string; isArabic: boolean }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(styles.arrowStyles, isArabic && styles.isArabic)}
    >
        <path
            d="M5 19L19 5"
            stroke={color || 'currentColor'}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M9 5H19V15"
            stroke={color || 'currentColor'}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export const getCmsStaticData = () => ({
    customerName: 'Namshi Namshi',
    customerEmail: 'namshi@gmail.com',
    initials: 'N.M',
    percentage: 76,
});

const ProfileProgressBarModule: React.FC<TProps> = ({
    analyticsData = {},
    containerClassName,
    disabled = false,
    iconSize = 55,
    moduleData = {} as TProfileProgressModule,
    moduleSize = 'badge',
    renderModuleLayout: ModuleLayout,
    showEmail = !!isCMS(),
    showModule = true,
}) => {
    const { t } = useTranslation(['account', 'common']);
    const { isArabic, isMobile, locale } = useAppContext();

    const ref = useRef(null);
    const { isViewed } = useIsInViewport({ ref });

    const {
        arrowIconColor,
        backgroundColor,
        subTitleFontColor,
        subTitleFontSize,
        type,
        userNameFontColor,
        userNameFontSize,
        widgetId,
    } = moduleData;

    const { data: userSizeProfileInfo, isLoading: isUserSizeProfileInfoLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_USER_SIZE_PROFILE_INFO],
        () => getApiInstance().preferences.getProfileInfo({}),
    );

    const { data: customerDataV2, isLoading: isCustomerDataLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_V2_CUSTOMER],
        () => getApiInstance().customer.getV2Customer({}),
    );

    useEffect(() => {
        if (isViewed) {
            trackEvent({
                event: 'impression_content',
                widgetId,
                contentModuleType: type,
                ...analyticsData,
            });
        }
    }, [isViewed]);

    const href = getExternalHref(isMobile ? ClientApp.SCHMATALOG : ClientApp.BIGALOG, ClientApp.ACCOUNT, locale, '/');

    if (!customerDataV2 && !isCMS()) return null;
    if (!showModule && !isCMS()) return null;

    const doesCustomerHasName = customerDataV2?.firstName !== '' || customerDataV2.lastName !== '';

    // Default CMS Data to preview for onSite
    const {
        customerEmail = customerDataV2?.email,
        customerName = `${customerDataV2?.firstName || ''} ${customerDataV2?.lastName || ''}`.trim(),
        percentage = getCustomerProfilePercentageWithSizeResponse(customerDataV2, userSizeProfileInfo),
    } = isCMS() ? getCmsStaticData() : {};

    const renderSubTitleDesktop = () => (
        <span className={styles.profileDetailsDesktop} style={{ fontSize: subTitleFontSize, color: subTitleFontColor }}>
            {t('tap-personalized-experience')}
            <span className={styles.completeNow}>
                {t('complete-now')} <Icon name="arrowForward" size={15} />
            </span>
        </span>
    );

    const renderContent = () => (
        <AlteredLink
            ref={ref}
            href={href}
            locale={locale}
            className={clsx(styles.container, containerClassName, {
                [styles.disabled]: disabled,
                [styles.mobile]: isMobile,
            })}
            disabled={disabled}
            {...(moduleSize === 'badge' ? { dir: 'ltr' } : {})}
        >
            <AccountProfileIcon
                isLoading={isCustomerDataLoading || isUserSizeProfileInfoLoading}
                userData={customerDataV2}
                userSizeProfileInfo={userSizeProfileInfo}
                width={iconSize}
                height={iconSize}
            />

            <div
                className={clsx(styles.customerInfo, {
                    [styles.customerInfoDesktop]: moduleSize === 'desktop',
                })}
            >
                <div className={styles.customerInfoDetails}>
                    {doesCustomerHasName && (
                        <b
                            style={{
                                fontSize: userNameFontSize,
                                color: userNameFontColor,
                            }}
                            className={styles.customerName}
                        >
                            {customerName}
                        </b>
                    )}
                    {showEmail && !isMobile && <span className={styles.customerEmail}>{customerEmail}</span>}
                </div>
                <div className={styles.customerDetails}>
                    {percentage < 100 &&
                        (moduleSize === 'badge' ? (
                            <span
                                className={styles.profileDetails}
                                style={{ fontSize: subTitleFontSize, color: subTitleFontColor }}
                            >
                                {t('personalized-experience')}
                            </span>
                        ) : (
                            renderSubTitleDesktop()
                        ))}
                    {percentage === 100 && t('profile.profile-completed')}
                </div>
            </div>
            {(isMobile || moduleSize === 'badge') && <ArrowOutwardIcon color={arrowIconColor} isArabic={isArabic} />}
        </AlteredLink>
    );

    if (!ModuleLayout) return renderContent();
    if (percentage === 100 && !isCMS()) return null;

    return (
        <ModuleLayout>
            <div className={styles.mainContainer} style={{ backgroundColor }}>
                {renderContent()}
            </div>
        </ModuleLayout>
    );
};

export default ProfileProgressBarModule;
