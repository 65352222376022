import React, { useRef, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { showPageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import Button from '@nm-namshi-frontend/core/components/Button';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import useIsInViewport from '@nm-namshi-frontend/core/hooks/useIsInViewport';
import FiltersModal from '@nm-namshi-frontend/core/page_components/productListingPage/FiltersModal';
import getImages from '@nm-namshi-frontend/core/utils/images';

import { TMidrollFilter } from '../../types/MidrollFilter';
import styles from './MidrollFilter.module.scss';

type TProps = {
    moduleData: TMidrollFilter;
};

const MidrollFilter: React.FC<TProps> = ({ moduleData }) => {
    const { t } = useTranslation(['catalog', 'common']);
    const { data } = moduleData;
    const { pathWithoutLocale } = useAppPathname();
    const { applyFilters, filters, trackFilterClick, updateFilters } = useCatalog();

    const [expandedFilter, setExpandedFilter] = useState<string | null>(null);
    const { code: filterCode, options, seeAll, title } = data;

    const ref = useRef<HTMLDivElement>(null);
    const { isViewed } = useIsInViewport({ ref });

    useEffect(() => {
        if (isViewed) {
            trackEvent({
                event: 'impression_filter',
                pageType: 'listing',
                filterKey: `midroll-${filterCode}`,
                searchTerm: pathWithoutLocale,
            });
        }
    }, [isViewed]);

    const onApply = () => {
        applyFilters();
        const values = filters.f?.[filterCode];
        const formattedValues = Array.isArray(values) ? `[${values}]` : values;

        trackFilterClick({
            filterCode: `midroll-${filterCode}`,
            newValue: `midroll-${formattedValues}`,
        });

        showPageLoader();
    };

    const renderHeader = () => (
        <div className={styles.titleWrapper}>
            <div className={styles.titleHeader}>
                <img src={getImages().midrollFilter.threeBars} alt="filter-animation" width={20} />

                <span className={styles.title}>{title}</span>
            </div>
            {seeAll && (
                <span className={styles.titleLink} onClick={() => setExpandedFilter(filterCode)}>
                    {t('seeAll', { ns: 'common' })}
                </span>
            )}
        </div>
    );

    const handleClick = (optionCode: string) => {
        updateFilters(
            {
                [filterCode]: optionCode,
            },
            {
                apply: false,
                shouldRefresh: false,
                trackFilter: false,
            },
        );
    };

    const checkIfCodeExists = (optionCode: string) => {
        const filterItems = filters?.f?.[filterCode];
        if (Array.isArray(filterItems)) {
            return filterItems.includes(optionCode);
        }
        return filterItems === optionCode;
    };

    const showApplyButton = useMemo(() => {
        const filterItems = filters?.f?.[filterCode] as string | Array<string>;
        return filterItems && filterItems.length >= 1;
    }, [filters, filterCode]);

    const isSizeFilter = filterCode.startsWith('size_');

    if (!options.length) return null;

    return (
        <div ref={ref} className={styles.container}>
            {renderHeader()}
            <ul className={styles.optionsContainer}>
                {options.map((option) => (
                    <button
                        type="button"
                        key={option.code}
                        {...(isSizeFilter ? { dir: 'ltr' } : {})}
                        id={`${filterCode}-${option.code}`}
                        className={clsx(styles.btn, checkIfCodeExists(option.code) && styles.applied)}
                        onClick={() => handleClick(option.code)}
                    >
                        {filterCode === 'colour_family' && (
                            <img
                                className={styles.swatch}
                                src={`${getImages().filterColorSwatches}/${option.code.toLowerCase()}.svg`}
                                alt={`Color-${option.name}`}
                                width={35}
                                height={35}
                            />
                        )}
                        {option.name}
                    </button>
                ))}
            </ul>
            {showApplyButton && (
                <div className={styles.applyWrapper}>
                    <Button variant="tertiary" onClick={onApply}>
                        {t('filters-and-sort.apply-filter', { ns: 'catalog' })}
                    </Button>
                </div>
            )}
            {expandedFilter && (
                <FiltersModal
                    open={!!expandedFilter}
                    initialOpened={expandedFilter}
                    onClose={() => setExpandedFilter(null)}
                    filterKey={`midroll-${filterCode}`}
                    trackFilter={false}
                />
            )}
        </div>
    );
};

export default MidrollFilter;
