import React from 'react';

import clsx from 'clsx';

import styles from './SizeFilterPill.module.scss';

type TProps = {
    name: string;
    count: number;
    onSelect: () => void;
    isSelected: boolean;
};

const SizeFilterPill = ({ count, isSelected, name, onSelect }: TProps) => (
    <button
        dir="ltr"
        type="button"
        className={clsx(styles.container, isSelected && styles.selected)}
        onClick={onSelect}
    >
        <span>{name}</span>
        <span className={styles.count}>{`(${count.toLocaleString()})`}</span>
    </button>
);

export default SizeFilterPill;
