import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { get } from 'lodash';

import NumberInput from '@nm-namshi-frontend/core/components/forms/NumberInput';
import Button from '@nm-namshi-frontend/core/components/Button';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { showPageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { convertPrice, getEnConversionCurrency } from '@nm-namshi-frontend/core/utils/helper';

import styles from './RangeFilter.module.scss';
import Fader from '../shared/fader/Fader';

type TProps = {
    data: Record<'min' | 'max', number>;
    code: string;
    facetLocation?: 'drawer' | 'modal' | 'quickFilter';
    shouldRefreshFilters: boolean;
    trackFilter?: boolean;
};

const RangeFilter = ({
    code,
    data: { max: _max, min: _min },
    facetLocation,
    shouldRefreshFilters,
    trackFilter,
}: TProps) => {
    const { t } = useTranslation('catalog');
    const { filters, updateFilters } = useCatalog();
    const { conversionRates } = useConfigContext();
    const { locale } = useAppPathname();
    const localeCurrency = getLocaleConfig(locale).currencyCode;

    const priceConvertor = ({
        cost,
        fromCurrency = 'USD',
        toCurrency = localeCurrency,
    }: {
        cost: number;
        toCurrency?: string;
        fromCurrency?: string;
    }): number =>
        Math.round(
            convertPrice({
                conversionRates,
                toCurrency,
                price: cost,
                fromCurrency,
            }),
        );

    useEffect(() => {
        setMin(priceConvertor({ cost: Number(get(filters, `f.${code}.min`, _min)) }));
        setMax(priceConvertor({ cost: Number(get(filters, `f.${code}.max`, _max)) }));
    }, [filters?.f?.[code]]);

    const [min, setMin] = useState(priceConvertor({ cost: Number(get(filters, `f.${code}.min`, _min)) }));
    const [max, setMax] = useState(priceConvertor({ cost: Number(get(filters, `f.${code}.max`, _max)) }));

    // Dirty check comparing set filter values, or initial values
    const isDirty = min !== _min || max !== _max;

    const isError = Number.isNaN(min) || Number.isNaN(max) || min > max;

    const getToCurrency = (): string => {
        if (!['AED', 'SAR'].includes(getEnConversionCurrency(localeCurrency))) {
            return 'USD';
        }
        return localeCurrency;
    };

    // Callbacks
    const submitBoth = async () => {
        if (!isError) {
            const shouldAutoApply = facetLocation === 'drawer';

            if (shouldAutoApply) {
                showPageLoader();
            }

            const convertedMin = priceConvertor({
                cost: min,
                fromCurrency: localeCurrency,
                toCurrency: getToCurrency(),
            });

            const convertedMax = priceConvertor({
                cost: max,
                fromCurrency: localeCurrency,
                toCurrency: getToCurrency(),
            });

            updateFilters(
                {
                    [code]: [convertedMin, convertedMax],
                },
                {
                    apply: shouldAutoApply,
                    shouldRefresh: shouldRefreshFilters,
                    trackFilter,
                },
            );
        }
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !isError) {
            submitBoth();
        }
    };

    const isInDrawer = facetLocation === 'drawer';

    return (
        <section className={isInDrawer ? styles.drawerContainer : styles.container}>
            <div className={styles.inputsContainer}>
                <NumberInput
                    label={t('minimum')}
                    value={min}
                    onKeyDown={onKeyDown}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMin(e.target.valueAsNumber);
                    }}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        if (!isError && facetLocation !== 'drawer') {
                            updateFilters(
                                {
                                    [code]: [e.target.valueAsNumber, max],
                                },
                                {
                                    apply: false,
                                    shouldRefresh: shouldRefreshFilters,
                                    trackFilter,
                                },
                            );
                        }
                    }}
                    currency={localeCurrency}
                />
                <NumberInput
                    label={t('maximum')}
                    value={max}
                    onKeyDown={onKeyDown}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMax(e.target.valueAsNumber);
                    }}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        if (!isError && facetLocation !== 'drawer') {
                            updateFilters(
                                {
                                    [code]: [min, e.target.valueAsNumber],
                                },
                                {
                                    apply: false,
                                    shouldRefresh: shouldRefreshFilters,
                                    trackFilter,
                                },
                            );
                        }
                    }}
                    currency={localeCurrency}
                />
            </div>
            {isInDrawer && (
                <Fader isActive={isDirty}>
                    <Button variant="secondary" onClick={submitBoth} disabled={isError}>
                        {t('go-cta')}
                    </Button>
                </Fader>
            )}
            <span className={clsx(styles.errorContainer, isError && styles.isVisible)}>
                {t('price-filter-error-description')}
            </span>
        </section>
    );
};

export default RangeFilter;
