/* eslint-disable no-restricted-syntax */
import { CategoryTreeResponse } from '@nm-namshi-frontend/services';

/**
 * extract the selected nodes of a category tree by the isSelected values within the tree
 * if a selected node has children that are also selected, its subsequent siblings are ignored since we have gone deeper
 *   into the tree
 * @param {CategoryTreeResponse[]} categoryTree
 * @param {CategoryTreeResponse[]} result
 * @returns {CategoryTreeResponse[]}
 */
export const extractSelectedCategoriesFromTree = (
    categoryTree: CategoryTreeResponse[],
    result: CategoryTreeResponse[] = [],
) => {
    for (let i = 0; i < categoryTree.length; i += 1) {
        const category = categoryTree[i];

        if (category.isSelected) {
            result.push(category);

            if (category.children?.some((child) => child.isSelected)) {
                extractSelectedCategoriesFromTree(category.children as CategoryTreeResponse[], result);
                break;
            }
        }
    }
    return result;
};

/**
 * get the parents/grandparents of a selected category
 * @param {CategoryTreeResponse[]} data
 * @param {CategoryTreeResponse} category
 * @returns {CategoryTreeResponse[] | null}
 */
export const getCategoriesBreadcrumbPath = (
    data: CategoryTreeResponse[],
    category: CategoryTreeResponse,
): CategoryTreeResponse[] | null => {
    for (const categoryTree of data) {
        if (categoryTree.code === category.code) {
            return categoryTree.children && categoryTree.children.length > 0 ? [categoryTree] : [];
        }
        if (categoryTree.children) {
            for (const child of categoryTree.children) {
                const result = getCategoriesBreadcrumbPath([child], category);
                if (result) {
                    return [categoryTree, ...result];
                }
            }
        }
    }
    return null;
};

/**
 * get the filterkey part of a path - we stack the filterKey ie; last segment of the URL
 * @param {string} path
 * @returns {string}
 */
export const extractFilterKeyFromPath = (path: string) => {
    const [withoutParams] = path.split('?');
    const splittedUrl = withoutParams.split('/');
    return splittedUrl && splittedUrl[splittedUrl.length - 1];
};

/**
 * build the path from selected categories, whilst stacking filterkey if necessary
 * @param {CategoryTreeResponse[]} selectedCategories
 * @param {string} filterKeyToStack
 * @returns {string} '/women-clothing-dresses' or '/women-clothing-dresses-evening__women-clothing-dresses-casual/new-arrivals'
 */
export const buildPathFromSelectedCategories = (
    selectedCategories: CategoryTreeResponse[],
    filterKeyToStack?: string,
) => {
    const last = selectedCategories[selectedCategories.length - 1];
    let newPath;

    if ((last?.children || []).length > 0) {
        // if last has children, it means last is an 'all' and not a multiselect
        newPath = last?.code;
    } else {
        // find multiselect siblings
        newPath = selectedCategories
            .filter((cat) => cat.idCategoryParent === last.idCategoryParent)
            .map((c) => c.code)
            .join('__');
    }
    const pathWithFilterKey = `/${newPath}/${filterKeyToStack}`;
    return filterKeyToStack ? pathWithFilterKey : `/${newPath}`;
};
