import React from 'react';

const Placeholder = (): JSX.Element => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        width="67"
        height="20"
        viewBox="0 0 73.1 20"
    >
        <path
            fill="#231f20"
            d="M43.6 0h-.3c-.3 0-.4.2-.5.4-.1.4-.7 2-1 2.8-.4 1-.8 2.2-.9 2.5-1.2 3.1-3.7 9.5-3.7 9.5-.5-1.2-.9-2.2-1.3-3.3-1.5-3.9-3-7.7-4.6-11.6-.2-.1-.3-.3-.6-.3h-2.4c.9 2.2 2 5.1 3.2 8 2.4 5.9 4.7 11.9 4.7 11.9h.3s1.4-3.5 2.9-7.5c.9-2.2 1.8-4.5 2.6-6.4l2.4-6h-.8zM63.2 0h-.3c-.3 0-.4.2-.5.4-.1.4-.7 2-1 2.8-.4 1-.8 2.2-.9 2.5-1.2 3.1-3.7 9.5-3.7 9.5-.5-1.2-.9-2.2-1.3-3.3C54 8 52.5 4.2 50.9.3c-.1-.1-.3-.3-.6-.3h-2.4c.9 2.2 2 5.1 3.2 8 2.4 5.9 4.7 11.9 4.7 11.9h.3s1.4-3.5 2.9-7.5c.9-2.2 1.8-4.5 2.6-6.4L64 0h-.8zM19.1 0h2.7v19.9h-2.7V0zM70.4 0h2.7v19.9h-2.7V0zM2.1 4.1C2.1 1.6 4.4.5 6.2.5c.9 0 1.4.1 2 .3 1.9.5 3 1.5 3.4 1.7l-.2-1.4c0-.3-.2-.4-.4-.5 0 0-.9-.2-2-.4h-.1c-.2 0-.4-.1-.5-.1C7.6 0 7 0 6.3 0 3.2 0 .1 1.5.1 5.1c0 6.3 10.4 5 10.4 10.2 0 2.6-2 4.1-4.9 4.1-1.1 0-2.3-.3-3.3-.8-.8-.4-2.3-1.5-2.3-1.5s.3 1.6.3 1.7c0 .3.5.5.5.5s.5.1.7.2c.1 0 .9.2 1.3.3.9.1 1.9.2 2.7.2 3.8 0 7.1-1.7 7.1-5.5C12.6 8 2.1 8.9 2.1 4.1z"
        />
    </svg>
);

export default Placeholder;
