export const sanitizeDimension = (dimension?: string | number | null): number => {
    if (!dimension) return 0;

    if (typeof dimension === 'number') return parseFloat(dimension.toString());

    let newDimension;
    if (typeof dimension === 'string') {
        if (dimension === 'undefined' || dimension === 'null') return 0;

        ['px', '%'].forEach((unit) => {
            if (dimension.toLowerCase().includes(unit)) {
                newDimension = dimension.toLowerCase().split(unit).filter(Boolean).join('');
            }
        });
    }

    if (!newDimension) return 0;

    try {
        newDimension = parseFloat(newDimension);
    } catch (e) {
        return 0;
    }

    return newDimension;
};
