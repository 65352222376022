import React from 'react';

import Carousel from 'nuka-carousel/packages/nuka/lib/carousel';
import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import { sanitizeDimension } from '@nm-namshi-frontend/widgets/src/utils/static';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

// eslint-disable-next-line import/no-cycle
import { Module } from '../ModulesRenderer';
import styles from './CarouselLayout.module.scss';
import type { TCarouselLayout } from '../../../types/CarouselLayout';
import type { TModule, TModuleAnalytics } from '../../../types/ModulesRenderer';

type TProps = {
    layout: TCarouselLayout;
    analyticsData: TModuleAnalytics;
    plpIdx?: string;
    cdnTemplate?: string;
};

const CarouselLayout: React.FC<TProps> = (props) => {
    const { layout, ...moduleProps } = props;
    const { innerSpacing = 15, numPerView = 1.6 } = layout || {};

    const { isArabic, isMobile } = useAppContext();

    const renderSlides = (column: TModule[]) => (
        <>
            {column.slice(0, 3).map((module, moduleIndex) => {
                const moduleId = `module-${moduleIndex + 1}-type-${module.type}`;

                return (
                    <Module
                        key={moduleId}
                        moduleId={moduleId}
                        moduleData={module}
                        isSiteWidth={false}
                        {...moduleProps}
                    />
                );
            })}
        </>
    );

    if (isMobile) {
        const paddingPx = sanitizeDimension(innerSpacing);

        const slideStyle: React.CSSProperties = {
            flex: `0 0 calc(${(1 / numPerView) * 100}% - 15px)`,
            ...(paddingPx
                ? {
                      padding: isArabic ? `0 0 0 ${paddingPx}px` : `0 ${paddingPx}px 0 0`,
                  }
                : {}),
        };

        return (
            <div className={styles.mobileContainer}>
                {layout.slides.map((column, colIdx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={colIdx} style={slideStyle}>
                        {renderSlides(column)}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className={styles.desktopContainer}>
            <Carousel
                isRtl={isArabic}
                swiping
                slidesToShow={numPerView}
                slideIndex={0}
                cellSpacing={innerSpacing}
                renderCenterLeftControls={({ previousDisabled, previousSlide }) => (
                    <button
                        type="button"
                        onClick={previousSlide}
                        disabled={previousDisabled}
                        className={clsx(styles.buttonContainer, previousDisabled && styles.disabled)}
                    >
                        <Icon name="chevronBack" />
                    </button>
                )}
                renderCenterRightControls={({ nextDisabled, nextSlide }) => (
                    <button
                        type="button"
                        onClick={nextSlide}
                        disabled={nextDisabled}
                        className={clsx(styles.buttonContainer, nextDisabled && styles.disabled)}
                    >
                        <Icon name="chevronForward" />
                    </button>
                )}
                renderBottomCenterControls={({ currentSlide, goToSlide, slideCount }) => (
                    <ul className={styles.dotsContainer}>
                        {[...Array(slideCount)].map((ele, index) => (
                            <li key={`${index + 1}-dot`}>
                                <button
                                    type="button"
                                    className={clsx(styles.dot, currentSlide === index && styles.current)}
                                    aria-label={`slide ${index} bullet`}
                                    onClick={() => goToSlide(index)}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            >
                {layout.slides.map((column, colIdx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={colIdx}>{renderSlides(column)}</div>
                ))}
            </Carousel>
        </div>
    );
};

export default CarouselLayout;
