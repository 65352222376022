import React from 'react';

import styles from './LoaderSpinner.module.scss';

type TProps = {
    color?: string;
    size?: number;
};

const LoaderSpinner = ({ color = '#999', size = 20 }: TProps) => {
    const divStyle = {
        width: size,
        height: size,
        margin: size / 8,
        border: `${size / 8}px solid #fff`,
        borderColor: `${color} transparent transparent`,
    };

    const containerStyle = {
        width: (size / 4) * 5,
        height: (size / 4) * 5,
    };

    return (
        <div style={containerStyle} className={styles.ldsRing}>
            <div style={divStyle} />
            <div style={divStyle} />
            <div style={divStyle} />
            <div style={divStyle} />
        </div>
    );
};

export default LoaderSpinner;
