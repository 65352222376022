import React from 'react';

import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { ProductSimpleResponse } from '@nm-namshi-frontend/services';
import ProductCarousel from '@nm-namshi-frontend/core/page_components/shared/product/carousel/ProductCarousel';
import useGetSponsoredProducts from '@nm-namshi-frontend/widgets/src/hooks/useGetSponsoredProducts';

import { ModuleHeader } from './shared';
import { TSponsoredProductsWidget } from '../../types/CarouselWidgets';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';

type TProps = {
    analyticsData: TModuleAnalytics;
    moduleData: TSponsoredProductsWidget;
    renderModuleLayout: TModuleLayout;
};

const SponsoredProductsWidget: React.FC<TProps> = ({ analyticsData, moduleData, renderModuleLayout: ModuleLayout }) => {
    const { moduleHeader, textColor, title, type, widgetId } = moduleData;

    const { catalogResponse } = useCatalog() || {};

    if (catalogResponse?.type !== 'details' || !('product' in catalogResponse.data)) return null;

    const { normalPrice, parentSku, salePrice } = catalogResponse.data.product as ProductSimpleResponse;

    const { data, isLoading } = useGetSponsoredProducts({
        normalPrice,
        parentSku,
        salePrice,
    });

    const products = data?.products || [];
    if (!isLoading && !products.length) return null;

    return (
        <ModuleLayout>
            <ModuleHeader
                moduleHeader={
                    moduleHeader || {
                        titleText: title,
                    }
                }
                textColor={textColor}
                analyticsData={{ ...analyticsData, contentModuleType: type, widgetId, column: 0 }}
            />
            <ProductCarousel
                products={products}
                isLoading={isLoading}
                analyticsData={{
                    pageType: analyticsData.pageType,
                    widgetId,
                }}
                isRecos
            />
        </ModuleLayout>
    );
};

export default SponsoredProductsWidget;
