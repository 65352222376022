import React, { useEffect, useMemo, useRef } from 'react';

import template from 'lodash/template';
import clsx from 'clsx';

import { TTrackEvent } from '@nm-namshi-frontend/core/utils/analytics.types';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useIsInViewport from '@nm-namshi-frontend/core/hooks/useIsInViewport';
import { isExternalLink } from '@nm-namshi-frontend/widgets/src/utils/url';
import { getWidgetImageUrl } from '@nm-namshi-frontend/core/utils/images';
import { TBanner } from '@nm-namshi-frontend/widgets/src/types/common';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './BannerContent.module.scss';
import MediaPreloader from '../MediaPreloader/MediaPreloader';
import BannerTimer from '../BannerTimer';
import ModuleLink from '../ModuleLink';
import ModuleImage from '../ModuleImage';

type TProps = {
    widgetId?: number;
    bannerStyle?: string;
    bannerData: TBanner;
    onAddToBag?: (sku: string) => void;
    cdnTemplate?: string;
};

const atcLink = (url: string) => /^addToCart:\/\//.test(url);

const BannerContent = ({
    bannerData = {},
    bannerStyle,
    // eslint-disable-next-line no-template-curly-in-string
    cdnTemplate = 'https://f.nooncdn.com/${path}',
    onAddToBag,
    widgetId,
}: TProps): JSX.Element | null => {
    const {
        analytics = {},
        assetId,
        ctaBgColor,
        ctaColor,
        ctaText,
        height,
        imagePath,
        ratio,
        summaryText,
        supportLinks,
        timer,
        titleText,
        videoControls,
        videoPath,
        width,
    } = bannerData || {};

    let { imageUrl, videoUrl } = bannerData;
    const { linkUrl } = bannerData;

    const { isArabic } = useAppContext();
    const bannerRef = useRef<HTMLDivElement>(null);
    const { isViewed } = useIsInViewport({ ref: bannerRef });
    const { isMobile } = useAppContext();

    useEffect(() => {
        if (isViewed && linkUrl) {
            const trackParams: TTrackEvent['impression_content']['data'] = {
                event: 'impression_content',
                contentClickUrl: linkUrl,
                imagePath: imageUrl,
                contentCreative: assetId || widgetId,
                analytics,
            };
            trackEvent(trackParams);
        }
    }, [isViewed]);

    useEffect(() => {
        if (bannerRef.current) {
            bannerRef.current.onclick = () => {
                const trackParams: TTrackEvent['contentClick']['data'] = {
                    event: 'contentClick',
                    contentClickUrl: linkUrl,
                    imagePath: imageUrl,
                    contentCreative: assetId || widgetId,
                    analytics,
                    widgetId,
                };
                trackEvent(trackParams);
            };
        }
    }, []);

    const timerStyle = useMemo(() => {
        if (!timer?.coordinates) return {};

        return {
            [isArabic ? 'right' : 'left']: `${timer.coordinates.x}`,
            top: `${timer.coordinates.y}`,
            ...(!isMobile && { transform: `translateY(-${timer.coordinates.y})` }),
        };
    }, [timer?.coordinates]);

    if (imagePath && cdnTemplate) {
        const compileFromCdnTemplate = template(cdnTemplate);
        imageUrl = compileFromCdnTemplate({ path: imagePath.path });
    }

    if (videoPath && cdnTemplate) {
        const compileFromCdnTemplate = template(cdnTemplate);
        videoUrl = compileFromCdnTemplate({ path: videoPath.path });
    }

    const ctaStyles = {
        ...(ctaBgColor && { backgroundColor: ctaBgColor }),
        ...(ctaColor && { color: ctaColor }),
    };
    const bannerElement = (): JSX.Element => {
        const videoComponent = !!videoUrl && (
            <MediaPreloader>
                {videoControls ? (
                    <video controls>
                        <track default kind="captions" />
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                ) : (
                    <video autoPlay loop muted playsInline>
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                )}
            </MediaPreloader>
        );

        const imageComponent = !!imageUrl && (
            <MediaPreloader>
                <ModuleImage
                    {...(assetId ? { id: assetId.toString() } : {})}
                    className={styles.image}
                    src={getWidgetImageUrl(imageUrl)}
                    alt="Banner Content"
                    loading="lazy"
                    height={height}
                    width={width}
                />
            </MediaPreloader>
        );

        return (
            <div className={styles.banner}>
                {videoComponent}
                {imageComponent}
                {titleText && (
                    <div>
                        <p className={styles.title}>{titleText}</p>
                        {summaryText && <p className={styles.summary}>{summaryText}</p>}
                    </div>
                )}
                {ctaText && <span style={ctaStyles}>{ctaText}</span>}
            </div>
        );
    };

    const bannerElementWrapper = (banner: JSX.Element): JSX.Element => {
        if (!linkUrl) {
            return banner;
        }
        if (atcLink(linkUrl) && onAddToBag) {
            const sku = linkUrl.split('addToCart://')[1];

            return (
                <div
                    onKeyDown={() => {
                        onAddToBag(sku);
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }}
                    role="button"
                    tabIndex={-1}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        onAddToBag(sku);
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }}
                >
                    {banner}
                </div>
            );
        }

        return (
            <ModuleLink href={linkUrl} external={isExternalLink(linkUrl)}>
                {banner}
            </ModuleLink>
        );
    };

    let bannerContent = (
        <div ref={bannerRef}>
            {bannerElementWrapper(bannerElement())}
            {supportLinks && (
                <ul className={styles.supportLinks} style={{ color: ctaColor || '#e2e5f1' }}>
                    {supportLinks.map((link) => (
                        <li
                            key={link.linkUrl}
                            style={{ color: ctaColor || '#2DAF32' }}
                            className={clsx(styles.supportLink, link.emphasis && styles.emphasis)}
                        >
                            <ModuleLink
                                href={link.linkUrl}
                                linkClass="--namshi-linkBody"
                                external={isExternalLink(link.linkUrl)}
                            >
                                {link.linkText}
                            </ModuleLink>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

    if (bannerStyle === 'bgImage') {
        bannerContent = (
            <div ref={bannerRef}>
                <ModuleLink href={linkUrl || ''} linkClass="--namshi-linkBody" external={isExternalLink(linkUrl)}>
                    <div className={styles.bgImageBannerCtr} style={{ backgroundImage: imageUrl }}>
                        {titleText && (
                            <div>
                                <p className={styles.title}>{titleText}</p>
                                {summaryText && <p className={styles.summary}>{summaryText}</p>}
                            </div>
                        )}
                        {ctaText && (
                            <span className={styles.cta} style={{ backgroundColor: ctaBgColor || '#FFF' }}>
                                {ctaText}
                            </span>
                        )}
                    </div>
                </ModuleLink>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {bannerContent}
            {timer && (
                <div dir="ltr" className={clsx(styles.timerWrapper, { [styles.mobile]: isMobile })} style={timerStyle}>
                    <div className={styles.timerContainer}>
                        <BannerTimer {...timer} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default BannerContent;
