import { ProductSimpleResponse } from '@nm-namshi-frontend/services';

export type TOuterSpacing = {
    top: number | string;
    bottom: number | string;
};

export type TLinkData = {
    linkText: string;
    linkUrl: string;
};

export enum ProductBoxVariant {
    FULL = 'full',
    NORMAL = 'normal',
    SEARCHSUGGESTION = 'searchSuggestion',
    WISHLIST = 'wishlist',
}

export type TCrossProduct = Pick<ProductSimpleResponse, 'sku' | 'brand' | 'title' | 'imageKeys'>;

// END OF MIRRORING CURRENT BE SERVICE TYPES

export type TSupportLink = TLinkData & {
    emphasis: boolean;
};

export type TPromotion = {
    id: string;
    name: string;
    position: string;
    curl?: string;
};

type TBannerBase = {
    assetId?: number;
    externalLink?: boolean;
    linkUrl?: string;
    titleText?: string;
    titleTextFontSize?: number;
    titleTextFontWeight?: 'normal' | 'bold';
    supportLinks?: TSupportLink[];
    analytics?: Record<string, any>;
};

export type TTimerPositions =
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'middle-left'
    | 'center'
    | 'middle-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right';

export type TBannerLabel = {
    position?: {
        top?: number;
        bottom?: number;
        right?: number;
        left?: number;
    };
    bgColor?: string;
    textColor?: string;
    text?: string;
};

export type TContextualBannerProps = {
    id?: string;
    viewUrls?: string[];
    impUrls?: string[];
    clickUrls?: string[];
};

export type TCountdownTimer = {
    expiry: string;
    enabled?: boolean;
    position?: TTimerPositions;
    bgColor?: string;
    disableDays?: boolean;
    displayUnitsBottom?: boolean;
    fontColor?: string;
    fontSize?: number;
    fontFamily?: string;
};

export type TAssetPath = {
    assetCode: string;
    assetGroupCode: string;
    path: string;
    assetId: string;
    contentType: string;
    extension: string;
    lang: string;
    trackingCode: string;
};

export type TBanner = TBannerBase & {
    imageUrl?: string;
    imagePath?: TAssetPath;
    videoUrl?: string;
    videoPath?: TAssetPath;
    ratio?: number;
    slotId?: string;
    label?: TBannerLabel;
    timer?: TModuleTimer;
    countdown?: TCountdownTimer;
    height?: number;
    width?: number;

    // ?? extras?
    ctaBgColor?: string;
    ctaColor?: string;
    ctaText?: string;
    summaryText?: string;
    summaryTextFontSize?: number;
    summaryTextFontWeight?: 'normal' | 'bold';
    textAlign?: 'left' | 'center' | 'right';
    videoControls?: boolean;
} & TContextualBannerProps;

export type TModuleHeader = {
    textAlign?: 'left' | 'center' | 'right';
    assetId?: number;
    // link
    linkText?: string;
    linkUrl?: string;
    // title
    titleText?: string;
    titleTextFontSize?: string;
    titleColor?: string;
    titleAlign?: 'left' | 'center' | 'right';
    titleImageUrl?: string;
    // subtitle
    subtitleText?: string;
    subtitleTextFontSize?: string;
    subtitleColor?: string;
    // coupon
    couponLabel?: string;
    couponCode?: string;
    couponCodeTextColor?: string;
    couponCodeBgColor?: string;
    couponLayoutInCenter?: boolean;
    // brand
    logoUrl?: string;
    logoSize?: number | string;
    logoLinkUrl?: string;
    brandCode?: string;
    shareUrl?: string;
};

export type TModuleTimer = {
    coordinates: {
        x: string;
        y: string;
    };
    enabled?: boolean;
    expiry: string;
    imageUrl?: string;
    bgColor?: string;
    fontColor?: string;
    disableDays?: boolean;
    displayUnitsBottom?: boolean;
    fontSize?: number;
    showLabels?: boolean;
    position?: TTimerPositions;
    fontFamily?: string;
};

export type TRecentlyViewedLocation = 'hp_recently-viewed' | null;
