import React, { useMemo } from 'react';

import parse from 'html-react-parser';

import { THtmlModule } from '../../types/HtmlModule';
import { TModuleLayout } from '../../types/ModulesRenderer';

type THtmlModuleProps = {
    moduleData: THtmlModule;
    renderModuleLayout: TModuleLayout;
};

const HtmlModule: React.FC<THtmlModuleProps> = ({ moduleData, renderModuleLayout: ModuleLayout }) => {
    const parsedHtml = useMemo(() => {
        const { data } = moduleData;

        if (!data) return null;

        return parse(data);
    }, [moduleData]);

    if (!parsedHtml) return <></>;

    return <ModuleLayout>{parsedHtml}</ModuleLayout>;
};

export default HtmlModule;
