import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';

import { IMG_BASE } from '@nm-namshi-frontend/core/config';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { CategoryTreeResponse } from '@nm-namshi-frontend/services';
import { buildPathFromSelectedCategories } from '@nm-namshi-frontend/core/utils/filters';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { isServer } from '@nm-namshi-frontend/core/utils/helper';
import { resetPageNumber } from '@nm-namshi-frontend/core/utils/catalog';

import styles from './CompleteCategoryFilter.module.scss';
import AlteredLink from '../shared/AlteredLink';

type TProps = {
    data: CategoryTreeResponse[];
    facetLocation?: 'drawer' | 'modal' | 'quickFilter';
    shouldRefreshFilters: boolean;
};

const FALLBACK_CAT_IMG = `${IMG_BASE}/namshi/images/namshi-placeholder-2.png`;

const CompleteCategoryFilter = ({ data: categoryData, facetLocation, shouldRefreshFilters }: TProps) => {
    const { locale } = useAppContext();
    const { catalogResponse, localSelectedCategories, updateLocalCategories } = useCatalog();
    const { pathWithoutLocale } = useAppPathname();
    const searchParams = useSearchParams();

    const shouldAutoApply = facetLocation === 'drawer';

    const canStack = !!catalogResponse.stack;
    const decodedPathWithoutLocale = decodeURIComponent(pathWithoutLocale);
    const splittedUrl = decodedPathWithoutLocale.split('/');
    const filterKey = splittedUrl && splittedUrl[splittedUrl.length - 1];

    const getNodeHref = (path: string) => {
        const basePath = `/${locale}${path}/`;

        if (isServer()) {
            return canStack && filterKey ? `${basePath}${filterKey}` : basePath;
        }

        const searchedText = searchParams.get('q');

        return canStack && filterKey
            ? `${basePath}${filterKey}`
            : `${basePath}${searchedText ? 'search/' : ''}${window.location.search}`;
    };

    const getUpdatedCategories = (nextSelection: CategoryTreeResponse) => {
        const foundIdx = localSelectedCategories?.findIndex((cat) => cat.code === nextSelection.code);

        if (foundIdx === -1) {
            return [...localSelectedCategories, nextSelection];
        }

        const isExpandable = !!nextSelection.children?.length;

        if (isExpandable) {
            return [...localSelectedCategories.slice(0, foundIdx)];
        }

        const newCats = [...localSelectedCategories];
        newCats.splice(foundIdx, 1);

        return newCats;
    };

    const updateSelection = (nextSelection: CategoryTreeResponse) => {
        updateLocalCategories({
            updatedCategories: getUpdatedCategories(nextSelection),
            shouldRefresh: shouldRefreshFilters,
            apply: false,
        });
    };

    const isCategorySelected = (cat: CategoryTreeResponse) =>
        !!localSelectedCategories.find(({ code }) => code === cat.code);

    const renderCategoryRow = (cat: CategoryTreeResponse, depth = 0) => {
        const isSelected = isCategorySelected(cat);
        const isExpandable = !!cat.children?.length;

        const path = buildPathFromSelectedCategories(getUpdatedCategories(cat));
        const href = resetPageNumber(getNodeHref(path));

        return (
            <AlteredLink
                locale={locale}
                href={href}
                className={clsx(styles.cat, { [styles.expanded]: isSelected })}
                style={{ top: `${depth * 40}px`, zIndex: isExpandable ? 5 - depth : 0 }}
                disabled={!shouldAutoApply}
                onClick={() => updateSelection(cat)}
            >
                <img alt={cat.name} className={styles.thumb} src={cat.imageUrl || FALLBACK_CAT_IMG} />
                <div className={styles.content}>
                    <div className={styles.name}>{cat.name}</div>
                    <div className={styles.count}>({cat.count?.toLocaleString()})</div>
                </div>
                {isExpandable ? <Icon name="chevronForwardBold" /> : isSelected && <Icon name="check" size={14} />}
            </AlteredLink>
        );
    };

    const renderSegment = (cats: CategoryTreeResponse[], depth = 0) =>
        cats.map((cat) => (
            <div key={cat.idCategory} className={clsx(styles.segment, { [styles.indented]: !!depth })}>
                {renderCategoryRow(cat, depth)}
                {isCategorySelected(cat) && cat.children && renderSegment(cat.children, depth + 1)}
            </div>
        ));

    const categories = categoryData.length === 1 ? categoryData[0].children : categoryData;

    if (!categories?.length) return null;

    return <div className={facetLocation === 'drawer' ? styles.containerDrawer : ''}>{renderSegment(categories)}</div>;
};

export default CompleteCategoryFilter;
