import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { findLastIndex, get, isArray } from 'lodash';
import clsx from 'clsx';

import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';
import TextInput from '@nm-namshi-frontend/core/components/forms/TextInput';
import Checkbox from '@nm-namshi-frontend/core/components/forms/Checkbox';
import { MultiselectFilter } from '@nm-namshi-frontend/services/';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import Radio from '@nm-namshi-frontend/core/components/forms/Radio';
import { hidePageLoader, showPageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import getImages from '@nm-namshi-frontend/core/utils/images';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { TConfigIcons } from '@nm-namshi-frontend/core/theme/icon';

import styles from './SelectableFacetItemList.module.scss';
import SizeFilterPill from './SizeFilterPill';
import AlteredLink from '../shared/AlteredLink';

const COLOR_IMAGE_CDN = 'https://a.namshicdn.com/cms/vogue/misc/color_swatches/';

type TFacetProps = {
    code: string;
    facetLocation?: 'drawer' | 'modal' | 'quickFilter';
    items: MultiselectFilter[];
    limit?: number;
    sort?: (a: MultiselectFilter, b: MultiselectFilter) => number;
    autoApply?: boolean;
    facetType: 'singleselect' | 'multiselect';
    trackFilter?: boolean;
    groupCode?: string;
    shouldRefreshFilters: boolean;
};

const SEARCHABLE_FILTER_CODES = ['brand_code'];
const FacetItems = ({
    autoApply,
    code: facetCode,
    facetLocation,
    facetType,
    groupCode,
    items,
    limit,
    shouldRefreshFilters,
    sort,
    trackFilter,
}: TFacetProps) => {
    const IS_MOBILE = isSchmatalog();
    const { t } = useTranslation('catalog');
    const { locale } = useAppContext();
    const { catalogResponse, filters, getUpdatedFiltersLink, trackFilterClick, updateFilters } = useCatalog();

    const [showAll, setShowAll] = useState(limit && limit >= items.length);

    const [path] = catalogResponse.canonicalUrl.split('?');
    const locationIsDrawer = facetLocation === 'drawer';

    const onSelect = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, filterLink: string, itemCode: string) => {
            e.preventDefault();

            if (autoApply) {
                showPageLoader();
                window.location.href = filterLink;

                if (filterLink.indexOf(itemCode) >= 0) {
                    if (trackFilter)
                        trackFilterClick({
                            filterCode: facetCode,
                            newValue: itemCode,
                        });
                }
            } else {
                e.stopPropagation();
                hidePageLoader();
                updateFilters(
                    {
                        [facetCode]: itemCode,
                    },
                    {
                        apply: autoApply,
                        shouldRefresh: shouldRefreshFilters,
                        trackFilter,
                    },
                );
            }
        },
        [facetCode, filters],
    );

    const handleOnChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
        if (e) {
            e.preventDefault();
        }
    };

    // Renderers
    const renderMultiSelect = useCallback(
        (type?: string) => {
            const selected = filters?.f?.[facetCode];
            const selectedArr = Array.isArray(selected) ? selected : [selected];
            const isSizeFilter = facetCode.startsWith('size_');

            let renderList = items;
            if (sort) {
                renderList = items.sort(sort); // only desktop should do this?
            }
            const activeList = renderList.filter(({ isActive }) => isActive);
            const inactiveList = renderList.filter(({ isActive }) => !isActive);
            renderList = IS_MOBILE ? items : [...activeList, ...inactiveList];

            const isLimited = typeof limit === 'number' && limit > 0 && renderList.length > limit;
            if (isLimited && !showAll) {
                renderList = renderList.slice(0, limit);
            }

            return (
                <>
                    <ul className={locationIsDrawer ? styles.itemList : ''}>
                        {renderList.map(({ code: itemCode, count, iconCode: _iconCode, name }) => {
                            const iconCode = _iconCode as TConfigIcons;
                            let isActive = selectedArr.includes(itemCode);

                            const inclusions = get(filters, `f.${facetCode}`);
                            if (inclusions) {
                                if (isArray(inclusions)) {
                                    isActive = !!inclusions.find((af) => af === itemCode);
                                } else {
                                    isActive = inclusions === itemCode;
                                }
                            }
                            const filterLink = getUpdatedFiltersLink({ [facetCode]: itemCode });
                            const href = `${path}/${facetCode !== 'brand_code' ? `${facetCode}/` : ''}${itemCode}`;

                            const renderMultiSelectLeadingImage = () => {
                                if (type === 'brand_code') {
                                    return iconCode ? (
                                        <img src={iconCode} alt={name} className={styles.brandImage} />
                                    ) : (
                                        <span className={styles.brandLetter}>{name?.slice?.(0, 1)}</span>
                                    );
                                }

                                if (type === 'colour_family' || type === 'nms_colour') {
                                    const swatchUrl =
                                        type === 'colour_family'
                                            ? `${getImages().filterColorSwatches}/${itemCode?.toLowerCase()}.svg`
                                            : `${COLOR_IMAGE_CDN}color_${itemCode.toLowerCase()}_web.png`;
                                    return <img className={styles.swatch} src={swatchUrl} alt={`Color-${name}`} />;
                                }

                                if (iconCode)
                                    return <Icon className={styles.leadingImageInRow} name={iconCode} size={20} />;

                                return null;
                            };

                            const renderItemContent = () => (
                                <div className={styles.multiselectContent}>
                                    {renderMultiSelectLeadingImage()}
                                    <div className={styles.textContainer}>
                                        <span {...(isSizeFilter ? { dir: 'ltr' } : {})} className={styles.label}>
                                            {name}
                                        </span>
                                        <span className={styles.count}>({count.toLocaleString()})</span>
                                    </div>
                                </div>
                            );

                            return (
                                <li key={`Filter-${facetCode}-${itemCode}-item`}>
                                    <AlteredLink
                                        locale={locale}
                                        href={href}
                                        onClick={(e) => onSelect(e, filterLink, itemCode)}
                                        disabled={!autoApply}
                                    >
                                        {IS_MOBILE ? (
                                            <button
                                                id={`${facetCode}-${itemCode}`}
                                                onClick={() => handleOnChange()}
                                                className={styles.multiselectButton}
                                                type="button"
                                            >
                                                {renderItemContent()}
                                                <Icon
                                                    className={clsx(
                                                        styles.indicator,
                                                        isActive && styles.indicatorSelected,
                                                    )}
                                                    size={16}
                                                    name="check"
                                                />
                                            </button>
                                        ) : (
                                            <Checkbox
                                                checked={isActive}
                                                id={`${facetCode}-${itemCode}`}
                                                onChange={handleOnChange}
                                                name={itemCode}
                                                containerClass={styles.multiselectCheckbox}
                                            >
                                                {renderItemContent()}
                                            </Checkbox>
                                        )}
                                    </AlteredLink>
                                </li>
                            );
                        })}
                    </ul>
                    {!showAll && isLimited ? (
                        <button className={styles.showAll} onClick={() => setShowAll(true)} type="button">
                            {`${t('showAll')} ${items.length}`}
                        </button>
                    ) : null}
                </>
            );
        },
        [items, onSelect, filters, facetCode, showAll],
    );

    const renderSingleSelect = useCallback(
        () => (
            <ul className={locationIsDrawer ? styles.itemList : ''}>
                {items.map(({ code: itemCode, count, isActive, name }) => {
                    const filterLink = getUpdatedFiltersLink({ [facetCode]: itemCode });

                    const selected = filters?.f?.[facetCode];
                    const active = selected ? selected === itemCode : isActive;

                    return (
                        <li key={`Filter-${facetCode}-${itemCode}-item`}>
                            <AlteredLink
                                locale=""
                                href={filterLink}
                                onClick={(e) => onSelect(e, filterLink, itemCode)}
                                disabled={!autoApply}
                            >
                                <Radio
                                    checked={active}
                                    id={`${facetCode}-${itemCode}`}
                                    name={itemCode}
                                    onChange={handleOnChange}
                                    containerClass={styles.singleSelectRadio}
                                    iconPosition={locationIsDrawer ? 'before' : 'after'}
                                >
                                    <div className={styles.singleSelectContent}>
                                        <div className={styles.textContainer}>
                                            <span className={styles.label}>{name}</span>
                                            <span className={styles.count}>({count.toLocaleString()})</span>
                                        </div>
                                    </div>
                                </Radio>
                            </AlteredLink>
                        </li>
                    );
                })}
            </ul>
        ),
        [items, onSelect, filters, facetCode],
    );

    const renderSizePills = useCallback(
        () => <SizePills items={items} facetCode={facetCode} onSelect={onSelect} autoApply={autoApply} />,
        [items, onSelect, filters, facetCode, autoApply],
    );

    if (facetType === 'singleselect') {
        return renderSingleSelect();
    }
    if (facetCode === 'size' || groupCode === 'size') {
        return renderSizePills();
    }
    return renderMultiSelect(facetCode);
};

const SelectableFacetItemList = ({
    autoApply,
    code,
    facetLocation,
    facetType,
    groupCode,
    items,
    limit,
    shouldRefreshFilters,
    sort,
    trackFilter = true,
}: TFacetProps) => {
    const { t } = useTranslation('catalog');

    const [searchText, setSearchText] = useState('');
    const trimmedSearch = searchText.toLowerCase().trim();

    const locationIsDrawer = facetLocation === 'drawer';

    let filteredFacetItemList = items;
    if (searchText) {
        filteredFacetItemList = items.filter(({ name }) => name.toLowerCase().includes(trimmedSearch));
    }

    const sortItems: (a: MultiselectFilter, b: MultiselectFilter) => number = (a, b) => {
        if (!searchText) return sort?.(a, b) || 0;

        const priorityLetter = trimmedSearch.slice(0, 1);

        const aPriority = a.name.toLowerCase().startsWith(priorityLetter);
        const bPriority = b.name.toLowerCase().startsWith(priorityLetter);

        if (aPriority && !bPriority) return -1;
        if (!aPriority && bPriority) return 1;

        return 0;
    };

    const isSearchable = SEARCHABLE_FILTER_CODES.includes(code);

    // Renderers
    const renderFacetItems = () => {
        if (filteredFacetItemList?.length) {
            return (
                <FacetItems
                    autoApply={autoApply}
                    code={code}
                    facetLocation={facetLocation}
                    facetType={facetType}
                    groupCode={groupCode}
                    items={filteredFacetItemList}
                    limit={limit}
                    shouldRefreshFilters={shouldRefreshFilters}
                    sort={sortItems}
                    trackFilter={trackFilter}
                />
            );
        }

        return <span className={styles.noSearchMatchText}>{t('empty-matching-brand-description')}</span>;
    };

    return (
        <section
            className={clsx(styles.container, locationIsDrawer ? styles.containerInDrawer : styles.containerStandard)}
        >
            {isSearchable && (
                <div className={styles.searchContainer}>
                    <TextInput
                        displayIcon="search"
                        displayIconSize={20}
                        displayIconColor="grey1"
                        value={searchText}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                        onClear={() => setSearchText('')}
                        placeholder={t('shared.search')}
                        inputClassName={styles.searchInput}
                    />
                </div>
            )}
            {renderFacetItems()}
        </section>
    );
};

const SIZE_PILLS_LIMIT = 9;

const SizePills: React.FC<{
    items: MultiselectFilter[];
    autoApply?: boolean;
    facetCode: string;
    onSelect: (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        filterLink: string,
        itemCode: string,
    ) => Promise<void>;
}> = ({ autoApply = false, facetCode, items, onSelect }) => {
    const { t } = useTranslation('catalog');
    const { filters, getUpdatedFiltersLink } = useCatalog();

    const selected = get(filters, `f.${facetCode}`);
    const selectedArr = Array.isArray(selected) ? selected : [selected];

    const [isExpanded, setIsExpanded] = useState(
        Array.isArray(items) && findLastIndex(items, ({ code }) => selectedArr.includes(code)) > SIZE_PILLS_LIMIT,
    );

    const isExpandable = items.length > SIZE_PILLS_LIMIT;

    const renderPills = ({ code: itemCode, count, name }: MultiselectFilter) => {
        const isActive = selectedArr.includes(itemCode);
        const filterLink = getUpdatedFiltersLink({ [facetCode]: itemCode });

        return (
            <li key={`SizeFilter-${facetCode}-${itemCode}-item`}>
                <AlteredLink
                    locale=""
                    href={filterLink}
                    onClick={(e) => onSelect(e, filterLink, itemCode)}
                    disabled={!autoApply}
                >
                    <SizeFilterPill
                        name={name}
                        count={count}
                        isSelected={isActive}
                        onSelect={(e?: React.ChangeEvent<HTMLInputElement>) => {
                            if (e) e.preventDefault();
                        }}
                    />
                </AlteredLink>
            </li>
        );
    };
    return (
        <ul className={styles.sizeItemsList}>
            {items.slice(0, SIZE_PILLS_LIMIT).map(renderPills)}
            {isExpandable && (
                <>
                    {isExpanded && items.slice(SIZE_PILLS_LIMIT).map(renderPills)}
                    <li>
                        <button type="button" onClick={() => setIsExpanded(!isExpanded)} className={styles.viewAllBtn}>
                            {isExpanded ? t('hide') : t('viewAll')}
                        </button>
                    </li>
                </>
            )}
        </ul>
    );
};

export default SelectableFacetItemList;
