import React from 'react';

import clsx from 'clsx';

import LoaderBounce from '@nm-namshi-frontend/core/components/loaders/LoaderBounce';

import styles from './Radio.module.scss';

type TProps = {
    checked?: boolean;
    children?: React.ReactNode;
    containerClass?: string;
    disabled?: boolean;
    iconPosition?: 'before' | 'after';
    id: string;
    inline?: boolean;
    loading?: boolean;
    name?: string;
    onChange: (e: any) => void;
};

const Radio = ({
    checked,
    children,
    containerClass,
    disabled,
    iconPosition = 'before',
    id,
    inline = false,
    loading,
    name,
    onChange,
}: TProps): JSX.Element => {
    const renderContent = () => (
        <span className={styles.contentContainer}>
            {loading && (
                <span className={styles.loaderContainer}>
                    <LoaderBounce size={6} />
                </span>
            )}
            <span className={styles.content}>{children}</span>
        </span>
    );
    const isIconAtStart = iconPosition === 'before';
    return (
        <label
            className={clsx(styles.radioLabel, containerClass, disabled && styles.disabled, inline && styles.inline)}
            htmlFor={id}
        >
            {!isIconAtStart && renderContent()}
            <input
                type="radio"
                checked={checked}
                className={clsx(checked && styles.checked)}
                disabled={disabled}
                id={id}
                name={name}
                onChange={onChange}
            />
            <span
                className={clsx(
                    styles.fauxRadioIcon,
                    iconPosition === 'before' && styles.iconPositionBefore,
                    iconPosition === 'after' && styles.iconPositionAfter,
                )}
            >
                <span className={styles.fauxRadio} />
            </span>
            {isIconAtStart && renderContent()}
        </label>
    );
};

export default Radio;
