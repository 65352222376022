import React, { useMemo, useRef } from 'react';

import clsx from 'clsx';
import useOnclickOutside from 'react-cool-onclickoutside';

import { TConfigIcons } from '@nm-namshi-frontend/core/theme/icon';

import styles from './StickyAlert.module.scss';
import Icon from './Icon';

type TAlertVariant = 'error' | 'success';

type TStickyAlert = {
    variant?: TAlertVariant;
    title?: string;
    description?: string;
    ctaLabel?: string;
    icon?: TConfigIcons;
    ctaClick?: () => void;
    closeIcon?: boolean;
    shown?: boolean;
    clickOutside?: () => void;
    size?: 'normal' | 'small';
    className?: string;
};
const StickyAlert = ({
    className,
    clickOutside,
    closeIcon,
    ctaClick,
    ctaLabel,
    description,
    icon,
    shown = true,
    size = 'normal',
    title,
    variant = 'error',
}: TStickyAlert) => {
    const ref = useOnclickOutside(() => clickOutside?.());
    const alertHeight = useMemo(() => {
        switch (size) {
            case 'small':
                return '40px';
            case 'normal':
            default:
                return '55px';
        }
    }, []);

    return (
        <button
            style={
                {
                    '--alert-shown-height': alertHeight,
                } as React.CSSProperties
            }
            ref={ref}
            onClick={ctaClick}
            type="button"
            className={clsx(styles.wrapper, shown && styles.shown, shown && className)}
        >
            <div className={styles.firstPart}>
                {icon && <Icon name={icon} size={24} />}
                <div className={styles.mainContent}>
                    {title && <div className={styles.title}>{title}</div>}
                    {description && <div className={styles.description}>{description}</div>}
                </div>
            </div>
            {ctaLabel && (
                <button type="button" onClick={ctaClick} className={styles.cta}>
                    {ctaLabel}
                </button>
            )}
            {closeIcon && <Icon size={16} name="close" />}
        </button>
    );
};

export default StickyAlert;
