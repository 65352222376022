import React, { useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { TModuleHeader } from '@nm-namshi-frontend/widgets/src/types/common';
import useToggleFollowBrand from '@nm-namshi-frontend/core/hooks/useToggleFollowBrand';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';
import HeartIcon from '@nm-namshi-frontend/core/components/HeartIcon';
import { getApiInstance } from '@nm-namshi-frontend/core/api';

type TBrandHeaderProps = {
    moduleHeader: TModuleHeader;
    analyticsData: {
        widgetId?: number;
        pageType: TPageType;
    };
};

const ModuleBrandFollowToggle: React.FC<TBrandHeaderProps> = ({ analyticsData, moduleHeader }) => {
    const { brandCode, titleColor = '#000' } = moduleHeader;

    const queryClient = useQueryClient();

    const { data, isLoading: isLoadingFollowStatus } = useQuery(
        [REACT_QUERY_KEYS.BRAND_LIST, 'following'],
        () =>
            getApiInstance().brand.getBrandList({
                requestBody: {
                    brandListType: 'following',
                },
            }),
        {
            refetchOnMount: false,
        },
    );

    const { isLoading, toggleFollow } = useToggleFollowBrand({
        invalidate: () => queryClient.invalidateQueries([REACT_QUERY_KEYS.BRAND_LIST, 'following']),
        onSuccess: () => {
            // Typescript is misbehaving, so writing non-DRY code below
            if (!isFollowed) {
                trackEvent({
                    event: 'brandFollow',
                    brandCode: brandCode as string,
                    widgetId: analyticsData.widgetId,
                    pageType: analyticsData.pageType,
                });
            } else {
                trackEvent({
                    event: 'brandUnfollow',
                    brandCode: brandCode as string,
                    widgetId: analyticsData.widgetId,
                    pageType: analyticsData.pageType,
                });
            }
        },
    });

    const isFollowed = useMemo(() => !!data?.brands.find((item) => item.brandCode === brandCode)?.isFollowing, [data]);

    if (!brandCode) return null;

    return (
        <div style={{ display: 'flex', alignItems: 'center', maxHeight: 'fit-content' }}>
            <HeartIcon
                isLoading={isLoading || isLoadingFollowStatus}
                size={25}
                color={titleColor}
                active={isFollowed}
                onClick={() => toggleFollow({ brandCode, isFollowing: !isFollowed })}
            />
        </div>
    );
};

export default ModuleBrandFollowToggle;
