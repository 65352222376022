import React from 'react';

import get from 'lodash/get';

import useAddToBagMutation from '@nm-namshi-frontend/core/mutations/useAddToBagMutation';

import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';
import { TBannerModuleStrip } from '../../types/BannerModuleStrip';
import { sanitizeDimension } from '../../utils/static';
import { ModuleHeader, BannerContent } from './shared';
import styles from './BannerModuleStripWidget.module.scss';

type TBannerModuleStripProps = {
    moduleData: TBannerModuleStrip;
    cdnTemplate?: string;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const BannerModuleStrip: React.FC<TBannerModuleStripProps> = ({
    analyticsData,
    cdnTemplate,
    moduleData,
    renderModuleLayout: ModuleLayout,
}) => {
    const { banners = [] } = moduleData || {};
    const { mutate: onAddToBag } = useAddToBagMutation();

    const { innerSpacing = 0, moduleHeader, numPerRow = 1, textColor, timer, type, widgetId } = moduleData || {};

    const paddingPx = numPerRow > 1 && innerSpacing ? sanitizeDimension(innerSpacing) : null;

    const renderLabel = () => {
        if (!moduleData.label || (moduleData.label && !moduleData.label.text)) {
            return null;
        }

        const labelStyles = {
            position: 'absolute',
            padding: '0 4px',
            fontSize: '14px',
            ...get(moduleData, 'label.position', {}),
            backgroundColor: get(moduleData, 'label.bgColor', '#fff'),
            color: get(moduleData, 'label.textColor', '#828282'),
        } as React.CSSProperties;
        return (
            <div style={{ ...labelStyles }}>
                <span>{moduleData.label.text}</span>
            </div>
        );
    };

    const validBanners = banners?.filter((banner) => !!banner?.imageUrl || !!banner?.imagePath) || [];

    if (!validBanners.length) return null;

    return (
        <ModuleLayout>
            <ModuleHeader
                moduleHeader={moduleHeader}
                textColor={textColor}
                analyticsData={{
                    ...analyticsData,
                    contentModuleType: type,
                    widgetId,
                    column: 0,
                }}
            />
            <div
                className={styles.bannerWrapper}
                data-qa="widget_bannerModuleStrip"
                {...(paddingPx
                    ? {
                          style: {
                              gap: `${paddingPx}px`,
                          },
                      }
                    : {})}
            >
                {validBanners.map((banner = {}, index) => {
                    const bannerClassList =
                        typeof numPerRow === 'number' && numPerRow > 1 ? 'standardBanner' : 'featureBanner';

                    return (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={`bannerModuleStrip${timer ? 'Timer' : ''}-${
                                banner.imagePath || banner.imageUrl
                            }-${index}`}
                            className={styles.bannerContainer}
                            data-qa="widget_bannerModuleStrip"
                            // Factor in numPerRow
                            style={{
                                flex: `1 calc(${(1 / numPerRow) * 100}% - ${innerSpacing}px)`,
                            }}
                        >
                            <BannerContent
                                widgetId={widgetId}
                                bannerStyle={`${type} ${bannerClassList}`}
                                bannerData={{ timer, ...banner }}
                                onAddToBag={onAddToBag}
                                cdnTemplate={cdnTemplate}
                            />
                        </div>
                    );
                })}
            </div>
            {renderLabel()}
        </ModuleLayout>
    );
};

export default BannerModuleStrip;
