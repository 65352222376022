import React, { useCallback } from 'react';

import isInteger from 'lodash/isInteger';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './BannerModuleGroupWidget.module.scss';
import { TBannerModuleGroup } from '../../types/BannerModuleGroup';
import { TBanner } from '../../types/common';
import { BannerContent, ModuleHeader } from './shared';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';

type TBannerModuleGroupProps = {
    moduleData: TBannerModuleGroup;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const BannerModuleGroup: React.FC<TBannerModuleGroupProps> = ({
    analyticsData,
    moduleData,
    renderModuleLayout: ModuleLayout,
}) => {
    let { innerSpacing } = moduleData || {};
    if (!innerSpacing) {
        innerSpacing = 15;
    }

    const { isArabic } = useAppContext();
    const { banners = [], innerSpacingLayout = 3, moduleHeader, textColor, type, widgetId } = moduleData || {};

    // SET PADDING SIZE AND SPACING LAYOUT FOR GROUPED BANNERS
    const paddingForGroupedBanners = useCallback(
        (
            groupedBanners: TBanner[],
        ): {
            bannerContainer: React.CSSProperties[];
        } => {
            const bannerContainer: React.CSSProperties[] = [];

            const groupedBannerCount = groupedBanners.length;

            if (isInteger(innerSpacing) && isInteger(innerSpacingLayout)) {
                groupedBanners.forEach((_, index: number) => {
                    switch (innerSpacingLayout) {
                        case 1: {
                            // Right padding for 1st and 3rd, others none.
                            // Main vertical split
                            const paddingStyle = isArabic ? `0 0 0 ${innerSpacing}px` : `0 ${innerSpacing}px 0 0`;
                            bannerContainer[index] = {
                                padding: index % (groupedBannerCount / 2) === 0 ? paddingStyle : `0 0 0 0`,
                            };
                            break;
                        }

                        case 2:
                            // Bottom padding for 1st and 2nd, others none.
                            // Main horizontal split
                            bannerContainer[index] = {
                                padding: index < groupedBannerCount / 2 ? `0 0 ${innerSpacing}px 0` : `0 0 0 0`,
                            };
                            break;

                        case 3: {
                            // Bottom and right padding for 1st and 2nd, others none.
                            // Main horizontal split + vertical split between 1,2
                            const paddingStyleUpper = isArabic
                                ? `0px ${innerSpacing}px ${innerSpacing}px 0px`
                                : `0px 0px ${innerSpacing}px ${innerSpacing}px`;
                            const paddingStyleLower = isArabic
                                ? `0px ${innerSpacing}px 0 0px`
                                : `0px 0px 0 ${innerSpacing}px`;

                            bannerContainer[index] = {
                                padding: index < groupedBannerCount / 2 ? paddingStyleUpper : paddingStyleLower,
                            };
                            break;
                        }

                        case 4: {
                            // Top and left padding for 3rd, left padding for the rest
                            // Main vertical split + horizontal split between 1,3
                            const paddingStyleEven = isArabic
                                ? `${innerSpacing}px ${innerSpacing}px 0 0`
                                : `${innerSpacing}px 0 0 ${innerSpacing}px`;
                            const paddingStyleOdd = isArabic ? `0 ${innerSpacing}px 0 0` : `0 0 0 ${innerSpacing}px`;

                            bannerContainer[index] = {
                                padding: index % groupedBannerCount === 2 ? paddingStyleEven : paddingStyleOdd,
                            };
                            break;
                        }

                        case 5: {
                            // Top and left padding for 4th, right padding for the rest
                            // Main vertical split + horizontal split between 1,3
                            const paddingStyleMod3 = isArabic
                                ? `${innerSpacing}px ${innerSpacing}px 0 0`
                                : `${innerSpacing}px 0 0 ${innerSpacing}px`;
                            const paddingStyleNonMod3 = isArabic
                                ? `0 ${innerSpacing}px 0 0`
                                : `0 0 0 ${innerSpacing}px`;

                            bannerContainer[index] = {
                                padding: index % groupedBannerCount === 3 ? paddingStyleMod3 : paddingStyleNonMod3,
                            };
                            break;
                        }

                        default:
                            // Set default padding layout for the given size
                            bannerContainer[index] = {
                                padding: isArabic
                                    ? `0 ${innerSpacing}px ${innerSpacing}px 0`
                                    : `0 0 ${innerSpacing}px ${innerSpacing}px`,
                            };
                    }
                });
            }

            return {
                bannerContainer,
            };
        },
        [isArabic, innerSpacing, innerSpacingLayout],
    );

    const supportBanners = banners.slice(1);
    const { bannerContainer } = paddingForGroupedBanners(supportBanners);

    const supportBannerFlexBasis = (2 / supportBanners.length) * 100;

    return (
        <ModuleLayout>
            <div style={{ padding: '0 15px' }}>
                <ModuleHeader
                    // needed to negate default side spacing for module header
                    moduleHeader={moduleHeader}
                    textColor={textColor}
                    analyticsData={{
                        ...analyticsData,
                        contentModuleType: type,
                        widgetId,
                        column: 0,
                    }}
                />
                {Array.isArray(banners) && (
                    <div className={styles.bannerWrapper}>
                        <div className={styles.bannerWrapper}>
                            <div className={styles.bannerContainer}>
                                <BannerContent widgetId={widgetId} bannerData={banners[0]} />
                            </div>
                        </div>
                        <div className={styles.bannerWrapper}>
                            {supportBanners.map((banner, index) => (
                                <div
                                    className={styles.bannerContainer}
                                    key={`${banner.assetId || ''}-${index + 1}`}
                                    style={{
                                        ...bannerContainer[index],
                                        flex: `0 0 ${supportBannerFlexBasis}%`,
                                    }}
                                    data-qa="widget_bannerModuleGroup"
                                >
                                    <BannerContent widgetId={widgetId} bannerData={banner} />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </ModuleLayout>
    );
};

export default BannerModuleGroup;
