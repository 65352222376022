import React from 'react';

import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import { TLink } from '../../../types/ModuleLink';
import { isExternalLink } from '../../../utils/url';

const ModuleLink = ({
    ariaLabel = 'link',
    children: linkChildren,
    external,
    href,
    id,
    linkClass,
    onClick,
    style,
}: TLink): JSX.Element => {
    const { locale } = useAppContext();

    // for external links that open in a new tab
    if (external || (href && typeof href !== 'object' && isExternalLink(href))) {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={ariaLabel}
                className={linkClass}
                id={id}
                style={style}
            >
                {linkChildren}
            </a>
        );
    }

    return (
        <AlteredLink
            locale={locale}
            aria-label={ariaLabel}
            href={href}
            id={id}
            className={linkClass}
            onClick={() => {
                onClick?.();
            }}
            target={external ? '_blank' : '_self'}
            style={style}
        >
            {linkChildren}
        </AlteredLink>
    );
};

export default ModuleLink;
