import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { RecommendationRequest } from '@nm-namshi-frontend/services';
import { useQuery } from '@tanstack/react-query';

const useGetRecommendedProducts = (payload: RecommendationRequest) => {
    const { excludeTags, placement, sku, tags } = payload;

    const res = useQuery(
        [REACT_QUERY_KEYS.GET_RECOMMENDATIONS, payload],
        () =>
            getApiInstance().product.similarRecommendations({
                placement,
                tags: tags ?? [],
                excludeTags: excludeTags ?? [],
                sku: sku ?? undefined,
            }),
        {
            staleTime: 0,
            cacheTime: 0,
        },
    );

    return res;
};

export default useGetRecommendedProducts;
