import React from 'react';

import ProductBox from '@nm-namshi-frontend/core/page_components/shared/product/ProductBox';
import { ProductBoxVariant } from '@nm-namshi-frontend/core/types';
import { TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';
import { AdmonProductResponse, AnalyticsResponse, ProductSimpleResponse } from '@nm-namshi-frontend/services';

type TProductBoxProps = {
    product: AdmonProductResponse | ProductSimpleResponse;
    analyticsData: {
        pageType: TPageType;
        plpPage?: number; // In case of a PLP being rendered
        row: number;
        column: number;
        searchTerm?: string;
        attributionData?: AnalyticsResponse['td'];
    };
};

const ModuleProductBox: React.FC<TProductBoxProps> = ({ analyticsData, product }) => (
    <ProductBox
        product={product}
        variant={ProductBoxVariant.FULL}
        showWishlistIcon
        showDiscountPercentage
        analyticsData={{
            pageType: 'listing',
            plpIdx: `${analyticsData.row}:${analyticsData.column}`,
            plpPage: analyticsData?.plpPage,
            searchTerm: analyticsData?.searchTerm,
            attributionData: analyticsData?.attributionData,
        }}
    />
);

export default ModuleProductBox;
