import { useMemo } from 'react';

import { getApiInstance } from '@nm-namshi-frontend/core/api';
import useDepartmentStoreV2 from '@nm-namshi-frontend/core/stores/useDepartmentStoreV2';
import { checkSubDepartmentExistsInDepartment } from '@nm-namshi-frontend/core/utils/departments';
import { useQuery } from '@tanstack/react-query';

const useGetVideos = ({ url }: { url: string }) => {
    const params = new URLSearchParams(url);
    const codes = params.get('codes') as any as string[];
    const hashtags = params.get('hashtags') as any as string[];

    const navigationData = useDepartmentStoreV2((store) => store.navigationData);
    const selectedDepartmentId = useDepartmentStoreV2((store) => store.selectedDepartmentId);
    const selectedSubDepartmentId = useDepartmentStoreV2((store) => store.selectedSubDepartmentId);

    const department = useMemo(() => {
        // Default case, as we cannot verify if subDepartmentId exists for that departmentId
        if (!navigationData) return selectedDepartmentId;

        if (
            checkSubDepartmentExistsInDepartment({
                navigationData,
                selectedDepartmentId,
                selectedSubDepartmentId,
            })
        ) {
            // Expected format, aligned with app
            return `${selectedDepartmentId}-${selectedSubDepartmentId}`;
        }

        // Back to default case when  subDepartmentId does not exist for that departmentId
        return selectedDepartmentId;
    }, [navigationData, selectedDepartmentId, selectedSubDepartmentId]);

    const res = useQuery([url], () => getApiInstance().videos.listVideos({ codes, hashtags }));

    return res;
};

export default useGetVideos;
