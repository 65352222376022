import React from 'react';

import { useTranslation } from 'react-i18next';

import { ShareCatalog } from '@nm-namshi-frontend/core/page_components/shared/share/ShareCatalog';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import { TModuleShare } from '../../../types/ModuleShare';

const ModuleShare = ({ analyticsData, color = '#000', utmSource }: TModuleShare) => {
    const { t } = useTranslation('common');

    return (
        <ShareCatalog
            pageType="static"
            color={color}
            size={20}
            utmSource={utmSource}
            referralMessage={t('shareLinkMessage')}
            onShare={({ url }) => {
                trackEvent({
                    event: 'contentShare',
                    pageType: 'static',
                    utmParam: url,
                    ...analyticsData,
                });
            }}
        />
    );
};

export default ModuleShare;
