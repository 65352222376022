import React from 'react';

import { isArray, isString } from 'lodash';

import {
    CategoryTreeResponse,
    FilterType,
    MultiselectFilter,
    SearchFilterResponse,
} from '@nm-namshi-frontend/services';
import { BooleanSelectFilter } from '@nm-namshi-frontend/services/models/BooleanSelectFilter';
import InformationDrawer from '@nm-namshi-frontend/core/page_components/productDetailPage/InformationDrawer';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import CategoryFilterV2 from './CategoryFilterV2';
import SelectableFacetItemList from './SelectableFacetItemList';
import RangeFilter from './RangeFilter';
import BooleanFilter from './BooleanFilter';
import styles from './Facet.module.scss';
import CompleteCategoryFilter from './CompleteCategoryFilter';

type TProps = {
    facetData: SearchFilterResponse;
    facetLocation?: 'drawer' | 'modal' | 'quickFilter';
    trackFilter?: boolean;
    shouldRefreshFilters?: boolean;
};

const Facet: React.FC<TProps> = ({ facetData, facetLocation, shouldRefreshFilters = false, trackFilter }) => {
    const { isMobile } = useAppContext();

    const isInDrawer = facetLocation === 'drawer'; // (desktop only)
    const DEFAULT_DISPLAY_COUNT = isInDrawer ? 10 : 0;
    const shouldAutoApply = isInDrawer;
    const { code, data, type } = facetData;

    // Renderers
    const renderFilterContent = () => {
        switch (type as FilterType | 'grouped') {
            case 'range':
                // I shouldn't need to check this, ts being buggy
                if (!isString(data) && !isArray(data) && 'max' in data) {
                    return (
                        <RangeFilter
                            code={code}
                            data={data}
                            facetLocation={facetLocation}
                            shouldRefreshFilters={shouldRefreshFilters}
                            trackFilter={trackFilter}
                        />
                    );
                }
                break;
            case 'boolean':
                return (
                    <BooleanFilter
                        facetCode={code}
                        itemCode={(data as BooleanSelectFilter).code}
                        iconCode={(data as BooleanSelectFilter).iconCode}
                        name={(data as BooleanSelectFilter).name}
                        isActive={(data as BooleanSelectFilter).isActive}
                        {...(shouldAutoApply ? { autoApply: true } : {})}
                        shouldRefreshFilters={shouldRefreshFilters}
                        trackFilter={trackFilter}
                    />
                );
            case 'singleselect':
            case 'multiselect':
                // I shouldn't need to check this, ts being buggy
                if (!isString(data) && !('max' in data)) {
                    if (code === 'category') {
                        return isMobile ? (
                            <CategoryFilterV2
                                data={data as Array<CategoryTreeResponse>}
                                facetLocation={facetLocation}
                                shouldRefreshFilters={shouldRefreshFilters}
                            />
                        ) : (
                            <CompleteCategoryFilter
                                data={data as Array<CategoryTreeResponse>}
                                facetLocation={facetLocation}
                                shouldRefreshFilters={shouldRefreshFilters}
                            />
                        );
                    }
                    return (
                        <SelectableFacetItemList
                            code={code}
                            facetType={type as 'singleselect' | 'multiselect'}
                            facetLocation={facetLocation}
                            items={data as Array<MultiselectFilter>}
                            limit={DEFAULT_DISPLAY_COUNT}
                            {...(shouldAutoApply ? { autoApply: true } : {})}
                            shouldRefreshFilters={shouldRefreshFilters}
                            trackFilter={trackFilter}
                        />
                    );
                }
                break;
            case 'grouped': {
                // e.g. size, data is only manipulated to be used like this in FilterModal
                const hasSelection = (data as unknown as SearchFilterResponse[]).some(({ isSelected }) => !!isSelected);

                return (
                    <>
                        {(data as unknown as SearchFilterResponse[]).map((group, idx) => (
                            <InformationDrawer
                                heading={group.name}
                                headingClassName={styles.groupHeader}
                                className={styles.group}
                                isOpen={hasSelection ? group.isSelected : idx === 0}
                                key={group.code}
                            >
                                <SelectableFacetItemList
                                    code={group.code}
                                    facetLocation={facetLocation}
                                    facetType={group.type as 'multiselect'}
                                    groupCode={group.groupCode}
                                    items={group.data as Array<MultiselectFilter>}
                                    limit={DEFAULT_DISPLAY_COUNT}
                                    {...(shouldAutoApply ? { autoApply: true } : {})}
                                    shouldRefreshFilters={shouldRefreshFilters}
                                    trackFilter={trackFilter}
                                />
                            </InformationDrawer>
                        ))}
                    </>
                );
            }
            default:
                // #TODO: Report
                return <></>;
        }
        return <div />;
    };

    return renderFilterContent();
};

export default Facet;
