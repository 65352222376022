import React from 'react';

type TNativeShareProps = {
    url: string;
    referralMessage: string;
    renderIcon: () => React.ReactNode;
    onShare: () => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const NativeShare: React.FC<TNativeShareProps> = ({ onShare, referralMessage, renderIcon, setIsLoading, url }) => {
    const share = () => {
        onShare();

        const shareData = { title: referralMessage, text: referralMessage, url };
        if (navigator.canShare && navigator.canShare(shareData)) {
            setIsLoading(true);
            navigator
                .share(shareData)
                .catch(() => {
                    /* */
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <div onClick={share} style={{ display: 'flex', alignItems: 'center' }}>
            {renderIcon()}
        </div>
    );
};

export default NativeShare;
