import { toast } from 'react-toastify';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { REACT_QUERY_KEYS } from '../constants/reactQueryKeys';
import { getApiInstance } from '../api';

const useAddToBagMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        [REACT_QUERY_KEYS.ADD_ITEM_TO_SESSION],
        (sku: string) =>
            getApiInstance().session.addItem({
                requestBody: {
                    sku,
                },
            }),
        {
            onSuccess: (response) => {
                queryClient.setQueryData([REACT_QUERY_KEYS.GET_ACTIVE_SESSION], response);
            },
            onError: (error: any) => {
                toast.error(error.toString());
            },
        },
    );
};

export default useAddToBagMutation;
