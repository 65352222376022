import React, { useCallback, useEffect, useState } from 'react';

import clsx from 'clsx';

import styles from './MediaPreloader.module.scss';
import Placeholder from './Placeholder';

type TProps = {
    ratio?: number;
    productImage?: boolean;
    children: JSX.Element;
    type?: 'iframe';
    paddingBottom?: string;
    onError?: () => void;
    onLoad?: () => void;
};

const MediaPreloader = ({
    children,
    onError,
    onLoad,
    paddingBottom = '136.375%',
    productImage,
    ratio,
    type,
}: TProps): JSX.Element => {
    const [isMounted, setIsMounted] = useState<boolean>(false);

    useEffect(() => {
        setIsMounted(true);

        return () => {
            setIsMounted(false);
        };
    }, []);

    const [loaded, setLoaded] = useState<boolean>(false);
    const handleOnLoad = useCallback((): void => {
        if (!isMounted) return;
        setLoaded(true);
        if (typeof onLoad === 'function') {
            onLoad();
        }
    }, [isMounted]);

    const [errored, setErrored] = useState<boolean>(false);
    const handleOnError = useCallback((): void => {
        if (!isMounted) return;
        setErrored(true);
        if (typeof onError === 'function') {
            onError();
        }
    }, [isMounted]);

    const handleRef = (ref: HTMLImageElement): void => {
        let element = ref;

        if (!element) {
            return;
        }

        // If image is wrapped (in a div) get the image element
        if (element.tagName && element.tagName !== 'IMG') {
            element = ref.querySelector('img') as HTMLImageElement;
        }

        // Element is not an image
        if (!element || element.tagName !== 'IMG') {
            return;
        }

        // Check if image is already loaded before script
        if (element.complete) {
            handleOnLoad();
            return;
        }

        element.addEventListener('load', handleOnLoad);
        element.addEventListener('error', handleOnError);
    };

    const childrenWithProps = React.Children.map(children, (child) =>
        React.cloneElement(child, {
            innerRef: handleRef, // Currently no child uses this - but renaming and not removing it for future use
        }),
    );

    if (ratio || productImage) {
        return (
            <div
                className={clsx(styles.container, loaded && styles.loaded)}
                style={{ paddingBottom: productImage ? paddingBottom : `${ratio}%` }}
            >
                {(!loaded || errored) && (
                    <div className={styles.placeholderContainer}>
                        <Placeholder />
                    </div>
                )}
                <div className={clsx(styles.mediaContainer, type === 'iframe' && styles.iframe)}>
                    {childrenWithProps}
                </div>
            </div>
        );
    }
    return <div className={styles.otherContainer}>{childrenWithProps}</div>;
};

export default MediaPreloader;
