import React, { useCallback, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import Button from '@nm-namshi-frontend/core/components/Button';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { TCrossSearchCarousel } from '@nm-namshi-frontend/widgets/src/types/CrossSearchCarousel';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useIsInViewport from '@nm-namshi-frontend/core/hooks/useIsInViewport';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { WIDGET_IDS } from '@nm-namshi-frontend/core/config';
import { TModuleAnalytics } from '@nm-namshi-frontend/widgets/src/types/ModulesRenderer';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import Carousel from '@nm-namshi-frontend/core/components/Carousel';
import CrossSearchProductBox from '@nm-namshi-frontend/core/page_components/shared/product/CrossSearchProductBox';

import styles from './CrossSearchWidget.module.scss';

type TProps = {
    moduleData: TCrossSearchCarousel;
    analyticsData: TModuleAnalytics;
};

const CrossSearchWidget: React.FC<TProps> = ({ analyticsData, moduleData }) => {
    const { t } = useTranslation('common');

    const { products, tab_image_url, text, type, url } = moduleData;
    const { column, row, searchTerm } = analyticsData;
    const { locale, pathWithoutLocale } = useAppPathname();
    const { isMobile } = useAppContext();
    const ref = useRef(null);
    const { isViewed } = useIsInViewport({ ref });
    const { listingPagehasNoProducts } = useCatalog();

    const shouldProductsCentered = listingPagehasNoProducts && products.length < 5;

    useEffect(() => {
        if (isViewed) {
            trackEvent({
                event: 'impression_content',
                pageType: 'listing',
                contentModuleType: type,
                widgetId: WIDGET_IDS.CROSS_SEARCH_CAROUSEL,
                row,
                column,
                searchTerm,
            });
        }
    }, [isViewed]);

    const handleClick = useCallback(() => {
        trackEvent({
            event: 'contentClick',
            contentClickUrl: url,
            contentModuleType: type,
            pagePath: pathWithoutLocale,
            widgetId: WIDGET_IDS.CROSS_SEARCH_CAROUSEL,
        });
    }, [url, type, pathWithoutLocale]);

    const renderCarousel = () => {
        let numPerView;

        if (isMobile) {
            if (listingPagehasNoProducts) {
                numPerView = 2.5;
            } else {
                numPerView = products.length === 1 ? 1 : 1.1;
            }
        } else if (listingPagehasNoProducts) {
            numPerView = products.length < 5 ? 4 : 5;
        } else {
            numPerView = 1.2;
        }

        return (
            <Carousel
                disableNative
                className={clsx(styles.carouselContainer, shouldProductsCentered ? styles.carouselClass : undefined)}
                numPerView={numPerView}
                // eslint-disable-next-line no-nested-ternary
                innerSpacing={isMobile ? 6 : shouldProductsCentered ? 40 : 10}
            >
                {products.map((product) => (
                    <CrossSearchProductBox key={product.sku} product={product} onClick={handleClick} url={url} />
                ))}
            </Carousel>
        );
    };

    if (isMobile) {
        return (
            <AlteredLink
                href={url}
                locale={locale}
                className={clsx(styles.container, listingPagehasNoProducts && styles.emptyContainer)}
                onClick={handleClick}
            >
                <span className={clsx(styles.title, listingPagehasNoProducts && styles.emptyTitleStyles)} ref={ref}>
                    {text}
                </span>
                {renderCarousel()}
                <Button
                    variant="tertiary"
                    buttonStyles={clsx(styles.btnMobile, listingPagehasNoProducts && styles.emptyBtnStyles)}
                >
                    <span>{t('switch-to')}</span>
                    <Icon name="chevronForwardBold" size={15} color="white" />
                    <img
                        className={clsx(styles.iconImage, styles.emptyIconStyles)}
                        src={tab_image_url}
                        alt={tab_image_url}
                    />
                </Button>
            </AlteredLink>
        );
    }

    return (
        <div className={styles.desktopContainer} ref={ref}>
            <AlteredLink
                locale={locale}
                href={url}
                className={clsx(styles.title, listingPagehasNoProducts && styles.emptyTitleStyles)}
                onClick={handleClick}
            >
                {text}
            </AlteredLink>
            {renderCarousel()}
            <AlteredLink locale={locale} href={url} className={styles.buttonContainer} onClick={handleClick}>
                <Button
                    variant="tertiary"
                    buttonStyles={clsx(styles.btn, listingPagehasNoProducts && styles.emptyDestkopBtnStyles)}
                >
                    {t('switch-to')}
                    <Icon name="chevronForwardBold" size={20} color="white" />
                    <img className={styles.iconImage} src={tab_image_url} alt={tab_image_url} />
                </Button>
            </AlteredLink>
        </div>
    );
};

export default CrossSearchWidget;
