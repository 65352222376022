import React from 'react';

import useGetVideos from '@nm-namshi-frontend/widgets/src/hooks/useGetVideos';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './VideoModuleCarousel.module.scss';
import { TVideoModuleCarousel } from '../../types/VideoModuleCarousel';
import ModuleImage from './shared/ModuleImage';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';
import { ModuleHeader } from './shared';

export type TProps = {
    moduleData: TVideoModuleCarousel;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const VideoModuleCarousel: React.FC<TProps> = (props) => {
    const { analyticsData, moduleData, renderModuleLayout: ModuleLayout } = props;
    const { moduleHeader, textColor, type, videoCarouselUrl, widgetId } = moduleData || {};

    const { data, isLoading } = useGetVideos({ url: videoCarouselUrl });

    const videos = data?.videos || [];

    if (!videoCarouselUrl) return null;
    if (!isLoading && !videos.length) return null;

    return (
        <ModuleLayout>
            <ModuleHeader
                isLoading={isLoading}
                moduleHeader={moduleHeader}
                textColor={textColor}
                analyticsData={{
                    ...analyticsData,
                    contentModuleType: type,
                    widgetId,
                    column: 0,
                }}
            />
            <div className={styles.carousel}>
                {videos?.map((video) => (
                    <div key={video.videoCode} className={styles.video}>
                        <ModuleImage
                            loading="lazy"
                            src={video.videoThumbnail}
                            alt={video.description || ''}
                            className={styles.thumbnail}
                            width={100}
                            height={100}
                        />
                        <div className={styles.detailsWrapper}>
                            <Icon name="playVideo" size={25} />
                            <p className={styles.description}>{video.description}</p>
                            {video.creator && <p className={styles.creator}>{video.creator?.name}</p>}
                        </div>
                    </div>
                ))}
            </div>
        </ModuleLayout>
    );
};

export default VideoModuleCarousel;
