import { getCmsStaticData } from '@nm-namshi-frontend/widgets/src/components/internal/ProfileProgressBarModule';
import { CustomerDataResponseV2 } from '@nm-namshi-frontend/services/models/CustomerDataResponseV2';
import { ProfileInfoResponse } from '@nm-namshi-frontend/services/models/ProfileInfoResponse';
import {
    getCustomerProfilePercentageWithSizeResponse,
    getInitials,
    isCMS,
} from '@nm-namshi-frontend/core/utils/helper';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import LoaderSpinner from './loaders/LoaderSpinner';
import styles from './AccountProfileIcon.module.scss';

type TProps = {
    width: number;
    height: number;
    isLoading?: boolean;
    userData?: CustomerDataResponseV2;
    userSizeProfileInfo?: ProfileInfoResponse;
};

const AccountProfileIcon = ({ height = 60, isLoading, userData, userSizeProfileInfo, width = 60 }: TProps) => {
    const { isArabic } = useAppContext();

    // Default CMS Data to preview for onSite team
    const {
        initials = userData && getInitials(userData.firstName, userData.lastName),
        percentage = getCustomerProfilePercentageWithSizeResponse(userData, userSizeProfileInfo),
    } = isCMS() ? getCmsStaticData() : {};

    const radius = width / 2;
    const strokeWidth = 4;
    const circumference = 2 * Math.PI * radius;
    const progress = (percentage / 100) * circumference;

    if (isLoading && !isCMS()) return <LoaderSpinner size={40} />;

    const strokeDashoffset = circumference - progress;

    return (
        <div className={styles.container} style={{ width, height }}>
            <div className={styles.iconWrapper}>
                <svg height={radius * 2 + strokeWidth} width={radius * 2 + strokeWidth}>
                    <circle
                        stroke="var(--clr-grey-l3)"
                        fill="none"
                        cx={radius + strokeWidth / 2}
                        cy={radius + strokeWidth / 2}
                        r={radius}
                        strokeWidth={strokeWidth}
                    />
                    <circle
                        stroke="#000000"
                        fill="var(--clr-brand-l3)"
                        cx={radius + strokeWidth / 2}
                        cy={radius + strokeWidth / 2}
                        r={radius}
                        strokeWidth={strokeWidth - 2}
                        strokeDasharray={`${circumference} ${circumference}`}
                        strokeDashoffset={isArabic ? -strokeDashoffset : strokeDashoffset}
                        strokeLinecap="round"
                        transform={`rotate(90 ${radius + strokeWidth / 2} ${radius + strokeWidth / 2})`}
                    />
                </svg>

                <div className={styles.textContainer}>
                    <span className={styles.initials}>{initials}</span>
                </div>
            </div>

            <div className={styles.percentageContainer}>
                <span className={styles.percentage}>{Number(percentage).toFixed(0)}%</span>
            </div>
        </div>
    );
};

export default AccountProfileIcon;
