import React from 'react';

import template from 'lodash/template';

import { getWidgetImageUrl } from '@nm-namshi-frontend/core/utils/images';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { CDN_BASE } from '@nm-namshi-frontend/core/config';

import { isExternalLink } from '../../utils/url';
import styles from './VideoModule.module.scss';
import { TVideoModule } from '../../types/VideoModule';
import MediaPreloader from './shared/MediaPreloader';
import { BannerTimer, ModuleLink } from './shared';
import ModuleImage from './shared/ModuleImage';
import { TModuleLayout } from '../../types/ModulesRenderer';

export type TProps = {
    cdnTemplate?: string;
    moduleData: TVideoModule;
    renderModuleLayout: TModuleLayout;
};

const VideoModule: React.FC<TProps> = ({
    // eslint-disable-next-line no-template-curly-in-string
    cdnTemplate = `${CDN_BASE}/\${path}`,
    moduleData,
    renderModuleLayout: ModuleLayout,
}) => {
    const {
        autoPlay,
        imagePath,
        linkUrl,
        posterUrl,
        repeat,
        timer: timerData,
        videoControls,
        videoPath,
    } = moduleData || {};
    let { imageUrl, videoUrl } = moduleData || {};

    const { isArabic } = useAppContext();

    if (imagePath && cdnTemplate) {
        const compileFromCdnTemplate = template(cdnTemplate);
        imageUrl = compileFromCdnTemplate({ path: imagePath });
    } else if (posterUrl) {
        imageUrl = posterUrl;
    }

    if (typeof videoPath === 'object' && cdnTemplate) {
        const compileFromCdnTemplate = template(cdnTemplate);
        videoUrl = compileFromCdnTemplate({ path: videoPath?.path });
    }

    const videoComponent = (
        <MediaPreloader>
            <>
                <video
                    autoPlay={autoPlay}
                    loop={repeat}
                    controls={videoControls}
                    poster={imageUrl}
                    data-qa="widget_videoModule"
                    muted={autoPlay}
                >
                    <track default kind="captions" />
                    <source src={videoUrl} type="video/mp4" />
                    {!!imageUrl && <ModuleImage src={getWidgetImageUrl(imageUrl)} alt="Video Module" loading="lazy" />}
                    {!videoControls && timerData && (
                        <div
                            dir="ltr"
                            className={styles.timerWrapper}
                            style={
                                timerData.coordinates
                                    ? {
                                          [isArabic ? 'right' : 'left']: `${timerData.coordinates.x}`,
                                          top: `${timerData.coordinates.y}`,
                                          transform: `translate(${isArabic ? '' : '-'}${timerData.coordinates.x}, -${
                                              timerData.coordinates.y
                                          })`,
                                      }
                                    : {}
                            }
                        >
                            <div className={styles.timerContainer}>
                                <BannerTimer {...timerData} />
                            </div>
                        </div>
                    )}
                </video>
                {!videoControls && timerData && (
                    <div
                        dir="ltr"
                        className={styles.timerWrapper}
                        style={
                            timerData.coordinates
                                ? {
                                      [isArabic ? 'right' : 'left']: `${timerData.coordinates.x}`,
                                      top: `${timerData.coordinates.y}`,
                                      transform: `translate(${isArabic ? '' : '-'}${timerData.coordinates.x}, -${
                                          timerData.coordinates.y
                                      })`,
                                  }
                                : {}
                        }
                    >
                        <div className={styles.timerContainer}>
                            <BannerTimer {...timerData} />
                        </div>
                    </div>
                )}
            </>
        </MediaPreloader>
    );

    if (!videoPath && !videoUrl) return null;

    return (
        <ModuleLayout>
            <div className={styles.container}>
                {linkUrl ? (
                    <ModuleLink href={linkUrl} external={isExternalLink(linkUrl)}>
                        {videoComponent}
                    </ModuleLink>
                ) : (
                    videoComponent
                )}
            </div>
        </ModuleLayout>
    );
};

export default VideoModule;
