import React from 'react';

import get from 'lodash/get';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './BannerModuleCarouselWidget.module.scss';
import { TBannerModuleCarousel } from '../../types/BannerModuleCarousel';
import { BannerContent, ModuleHeader } from './shared';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';

const sideSpace = 15;

type TBannerModuleCarouselProps = {
    moduleData: TBannerModuleCarousel;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const BannerModuleCarouselWidget: React.FC<TBannerModuleCarouselProps> = ({
    analyticsData,
    moduleData,
    renderModuleLayout: ModuleLayout,
}) => {
    const { banners = [], moduleHeader, numPerRow, numPerView, textColor, type, widgetId } = moduleData || {};

    const { isArabic } = useAppContext();
    const perView = numPerView || numPerRow || 2.5;

    const bannerContainer: React.CSSProperties = {
        flex: `0 0 calc(${(1 / perView) * 100}% - ${sideSpace}px)`,
        [isArabic ? 'marginRight' : 'marginLeft']: `${sideSpace / 2}px`,
    };

    const bannerWrapper: React.CSSProperties = {
        padding: `0 ${sideSpace / 2}px`,
    };

    const renderLabel = () => {
        if (!moduleData.label || (moduleData.label && !moduleData.label.text)) {
            return null;
        }

        const labelStyles = {
            position: 'absolute',
            padding: '0 4px',
            fontSize: '14px',
            ...get(moduleData, 'label.position', {}),
            backgroundColor: get(moduleData, 'label.bgColor', '#fff'),
            color: get(moduleData, 'label.textColor', '#828282'),
        } as React.CSSProperties;
        return (
            <div style={{ ...labelStyles }}>
                <span>{moduleData.label.text}</span>
            </div>
        );
    };

    const validBanners = banners?.filter((banner) => !!banner?.imageUrl || !!banner?.imagePath) || [];

    if (!validBanners.length) return null;

    return (
        <ModuleLayout>
            <ModuleHeader
                moduleHeader={moduleHeader}
                textColor={textColor}
                analyticsData={{
                    ...analyticsData,
                    contentModuleType: type,
                    widgetId,
                    column: 0,
                }}
            />
            <div className={styles.bannerCarouselWrapper} style={bannerWrapper}>
                {Array.isArray(validBanners) &&
                    validBanners.map((banner, index) => {
                        const bannerClassList =
                            typeof perView === 'number' && perView > 1 ? 'standardBanner' : 'featureBanner';

                        const bannerKey = `${banner.imagePath || banner.imageUrl}_${index}`;

                        return (
                            <div key={bannerKey} className={styles.bannerContainer} style={bannerContainer}>
                                <BannerContent
                                    widgetId={widgetId}
                                    bannerStyle={`${type} ${bannerClassList}`}
                                    bannerData={banner}
                                />
                            </div>
                        );
                    })}
            </div>
            {renderLabel()}
        </ModuleLayout>
    );
};

export default BannerModuleCarouselWidget;
