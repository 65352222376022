import React from 'react';

import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { TConfigIcons } from '@nm-namshi-frontend/core/theme/icon';
import Toggle from '@nm-namshi-frontend/core/components/forms/Toggle';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './BooleanFilter.module.scss';
import AlteredLink from '../shared/AlteredLink';

type TBooleanFilterProps = {
    facetCode: string;
    itemCode: string;
    iconCode?: string;
    name: string;
    isActive: boolean;
    trackFilter?: boolean;
    autoApply?: boolean;
    shouldRefreshFilters: boolean;
};

const BooleanFilter: React.FC<TBooleanFilterProps> = (props) => {
    const { autoApply, facetCode, iconCode, isActive, itemCode, name, shouldRefreshFilters, trackFilter } = props;

    const { locale } = useAppContext();

    const { getUpdatedFiltersLink, trackFilterClick, updateFilters } = useCatalog();
    const filterLink = getUpdatedFiltersLink({ [facetCode]: isActive ? '' : itemCode });

    const onSelect = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, facetValue: string) => {
        if (autoApply) {
            if (trackFilter) {
                trackFilterClick({ filterCode: facetCode, newValue: facetValue });
            }
        } else {
            e.preventDefault();
            e.stopPropagation();
            updateFilters(
                {
                    [facetCode]: facetValue,
                },
                {
                    apply: autoApply,
                    shouldRefresh: shouldRefreshFilters,
                    trackFilter,
                },
            );
        }
    };

    return (
        <AlteredLink
            locale={locale}
            href={filterLink}
            onClick={(e) => onSelect(e, isActive ? '' : itemCode)}
            disabled={!autoApply}
        >
            <Toggle containerClass={styles.toggleWrapper} checked={isActive} id={itemCode} iconPosition="after">
                <div className={styles.container}>
                    {iconCode && <Icon name={iconCode as TConfigIcons} size={15} />}
                    {name}
                </div>
            </Toggle>
        </AlteredLink>
    );
};

export default BooleanFilter;
