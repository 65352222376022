import React, { useEffect, useRef } from 'react';

import clsx from 'clsx';

import FieldLayout from '@nm-namshi-frontend/core/components/forms/FieldLayout';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './NumberInput.module.scss';

type TProps = {
    autofocus?: boolean;
    disabled?: boolean;
    error?: string | null;
    helperText?: string;
    label?: string;
    name?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClear?: () => void;
    placeholder?: string;
    currency?: string;
    tabIndex?: number;
    value: number;
};

const NumberInput = ({
    autofocus = false,
    currency,
    disabled = false,
    error,
    helperText,
    label,
    name = '',
    onBlur,
    onChange,
    onClear,
    onKeyDown,
    placeholder,
    tabIndex,
    value,
}: TProps) => {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (ref.current && autofocus) {
            ref.current.focus();
        }
    }, [autofocus]);

    const handleClear = () => {
        if (!onClear) return;

        onClear();
        if (ref.current) {
            ref.current.focus();
        }
    };

    return (
        <FieldLayout label={label} error={error} helperText={helperText}>
            <div className={styles.inputContainer}>
                <input
                    className={clsx(styles.input, (!!onClear || !!currency) && styles.hasClear)} // #TODO: Error state (?) @CLI -N
                    autoComplete="off"
                    disabled={disabled}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                    ref={ref}
                    tabIndex={tabIndex}
                    type="number"
                    value={value}
                    inputMode="numeric"
                />
                {currency && <span className={styles.currency}>{currency}</span>}
                {onClear && value && !disabled ? (
                    <button className={styles.clear} type="button" onClick={handleClear} tabIndex={-1}>
                        <Icon name="closeCircle" size={24} />
                    </button>
                ) : null}
            </div>
        </FieldLayout>
    );
};

export default NumberInput;
