import React from 'react';

import { TPageInclude } from '@nm-namshi-frontend/widgets/src/types/PageInclude';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './PageInclude.module.scss';

type TProps = {
    moduleData: TPageInclude;
};

const PageInclude: React.FC<TProps> = ({ moduleData }) => {
    const { name, pageCode } = moduleData;

    return (
        <div className={styles.container} dir="ltr">
            <div>
                <div className={styles.header}>Page Include</div>
                <Attribute label="Name" value={name || '-'} />
                <Attribute label="Page Code" value={pageCode || '-'} />
            </div>

            <div className={styles.footer}>
                <Icon name="infoCircle" /> <div>This will not show after publish</div>
            </div>
        </div>
    );
};

const Attribute: React.FC<{ label: string; value?: string }> = ({ label, value }) => (
    <div className={styles.attribute}>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>{value}</div>
    </div>
);

export default PageInclude;
