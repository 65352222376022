import { TModuleHeader } from '../types/common';

export const shouldRenderModuleHeader = (data?: TModuleHeader) => {
    if (!data) return false;

    const { couponCode, couponLabel, linkText, linkUrl, logoUrl, titleText } = data;

    if (titleText) return true;
    if (logoUrl) return true;
    if (linkText && linkUrl) return true;
    if (couponLabel && couponCode) return true;

    return false;
};
