import React from 'react';

import get from 'lodash/get';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './BannerModuleWidget.module.scss';
import { TBannerModule } from '../../types/BannerModule';
import { BannerContent, ModuleHeader } from './shared';
import { sanitizeDimension } from '../../utils/static';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';

const sideSpace = 12;

type TBannerModuleProps = {
    moduleData: TBannerModule;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const BannerModule: React.FC<TBannerModuleProps> = ({
    analyticsData,
    moduleData,
    renderModuleLayout: ModuleLayout,
}) => {
    const {
        banners,
        innerSpacing = 0,
        moduleHeader,
        numPerRow,
        numPerView,
        textColor,
        type,
        widgetId,
    } = moduleData || {};

    const { isArabic } = useAppContext();

    const perView = numPerView || numPerRow || 1;

    const validBanners = banners?.filter((banner) => !!banner?.imageUrl || !!banner?.imagePath) || [];

    let bannerContainer: React.CSSProperties = {
        ...(validBanners.length > 1 ? { flex: `0 0 calc(${(1 / perView) * 100}% - ${sideSpace / 2}px)` } : {}),
    };

    // SET CUSTOM PADDING FOR STANDARD GRID BANNERS
    if (perView > 1 && innerSpacing) {
        const paddingPx = sanitizeDimension(innerSpacing);

        bannerContainer = {
            ...bannerContainer,
            padding: isArabic ? `0 0 ${paddingPx}px 0` : `0 0 ${paddingPx}px 0`,
        };
    }

    const renderLabel = () => {
        if (/* !adContext || */ !moduleData.label || (moduleData.label && !moduleData.label.text)) {
            return null;
        }

        const labelStyles = {
            position: 'absolute',
            padding: '0 4px',
            fontSize: '14px',
            ...get(moduleData, 'label.position', {}),
            backgroundColor: get(moduleData, 'label.bgColor', '#fff'),
            color: get(moduleData, 'label.textColor', '#828282'),
        } as React.CSSProperties;
        return (
            <div style={{ ...labelStyles }}>
                <span>{moduleData.label.text}</span>
            </div>
        );
    };

    if (!validBanners.length) return null;

    return (
        <ModuleLayout>
            <ModuleHeader
                moduleHeader={moduleHeader}
                textColor={textColor}
                analyticsData={{
                    ...analyticsData,
                    contentModuleType: type,
                    widgetId,
                    column: 0,
                }}
            />
            <div
                className={styles.bannerWrapper}
                style={{ gap: `${sanitizeDimension(innerSpacing)}px` }}
                data-qa="widget_bannerModule_bannerWrapper"
            >
                {validBanners.map((banner, index) => {
                    const bannerClassList =
                        typeof perView === 'number' && perView > 1 ? 'standardBanner' : 'featureBanner';

                    return (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={`bannerModule-${banner.imagePath || banner.imageUrl}-${index}`}
                            className={styles.bannerContainer}
                            style={bannerContainer}
                            data-qa="widget_bannerModule"
                        >
                            <BannerContent
                                widgetId={widgetId}
                                bannerStyle={`${type} ${bannerClassList}`}
                                bannerData={banner}
                            />
                        </div>
                    );
                })}
            </div>
            {renderLabel()}
        </ModuleLayout>
    );
};

export default BannerModule;
