import React, { useState } from 'react';

import clsx from 'clsx';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

// eslint-disable-next-line import/no-cycle
import { Module } from '../ModulesRenderer';
import type { TTabsLayout } from '../../../types/TabsLayout';
import styles from './TabsLayout.module.scss';
import { TModuleAnalytics, TModuleLayout } from '../../../types/ModulesRenderer';

type TProps = {
    layout: TTabsLayout;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const TabsLayout: React.FC<TProps> = (props) => {
    const { layout, renderModuleLayout: ModuleLayout, ...moduleProps } = props;
    const { fontSize = '16px', layoutType = 'fixed', tabs, tabsBgColor, tabsSelectedBgColor } = layout;

    const { isMobile } = useAppContext();
    const [selectedTab, setSelectedTab] = useState(0);

    if (!tabs.length) return null;

    return (
        <ModuleLayout>
            <div className={styles.tabListContainer}>
                <div
                    className={clsx(styles.tabList, {
                        [styles.fixed]: layoutType === 'fixed',
                    })}
                    style={{
                        backgroundColor: tabsBgColor,
                    }}
                >
                    {tabs.map(
                        (
                            {
                                name,
                                selectedTabIcon,
                                tabIconHeight = '35px',
                                tabSpacing = { top: 0, bottom: 0 },
                                unSelectedTabIcon,
                            },
                            idx,
                        ) => {
                            const isSelected = idx === selectedTab;

                            return (
                                <button
                                    key={name}
                                    type="button"
                                    onClick={() => setSelectedTab(idx)}
                                    className={styles.tabItem}
                                    style={{
                                        fontSize,
                                        paddingTop: tabSpacing.top,
                                        paddingBottom: tabSpacing.bottom,
                                        ...(idx === selectedTab ? { backgroundColor: tabsSelectedBgColor } : {}),
                                    }}
                                >
                                    {selectedTabIcon && unSelectedTabIcon ? (
                                        <img
                                            className={styles.tabImg}
                                            style={{ height: tabIconHeight }}
                                            src={isSelected ? selectedTabIcon : unSelectedTabIcon}
                                            alt={name}
                                        />
                                    ) : (
                                        <div className={clsx(styles.tabName, isSelected && styles.selected)}>
                                            {name}
                                        </div>
                                    )}
                                </button>
                            );
                        },
                    )}
                </div>
            </div>

            <div key={selectedTab} className={styles.modulesContainer}>
                {tabs[selectedTab]?.modules.map((module, moduleIndex) => {
                    const moduleId = `module-${moduleIndex + 1}-type-${module.type}`;

                    return (
                        <Module
                            key={moduleId}
                            moduleId={moduleId}
                            moduleData={module}
                            isSiteWidth={!isMobile}
                            {...moduleProps}
                        />
                    );
                })}
            </div>
        </ModuleLayout>
    );
};

export default TabsLayout;
