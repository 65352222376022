import { CategoryTreeResponse, FilterType, SearchFilterResponse } from '@nm-namshi-frontend/services';

import CategoryFilterBreadcrumbs from './CategoryFilterBreadcrumbs';
import SelectableFacetBreadcrumbs from './SelectableFacetBreadcrumbs';
import GroupedFacetBreadcrumbs from './GroupedFacetBreadcrumbs';

type TFilterBreadcrumbsProps = {
    facetData: SearchFilterResponse;
    facets: SearchFilterResponse[];
    shouldRefresh?: boolean;
};

const FilterBreadcrumbs: React.FC<TFilterBreadcrumbsProps> = ({ facetData, facets, shouldRefresh = false }) => {
    const { code, type } = facetData;

    if (code === 'category') {
        const categoryFacetTree = facets.find((facet) => facet.code === 'category')?.data as CategoryTreeResponse[];

        return <CategoryFilterBreadcrumbs categoryData={categoryFacetTree} shouldRefresh={shouldRefresh} />;
    }

    if ((type as FilterType | 'grouped') === 'grouped') {
        return <GroupedFacetBreadcrumbs facetData={facetData} shouldRefresh={shouldRefresh} />;
    }

    if (type === 'multiselect') {
        return <SelectableFacetBreadcrumbs facetData={facetData} shouldRefresh={shouldRefresh} />;
    }

    return null;
};

export default FilterBreadcrumbs;
