import React from 'react';

import { CSSTransition } from 'react-transition-group';

import ShareOptions from '@nm-namshi-frontend/core/page_components/shared/ShareOptions';

import styles from './ShareDropdown.module.scss';

type TShareDropdownProps = {
    isOpen: boolean;
    shareUrl: string;
    referralMessage: string;
    onShare?: () => void;
};

const ShareDropdown: React.FC<TShareDropdownProps> = ({ isOpen, onShare, referralMessage, shareUrl }) => (
    <CSSTransition
        in={isOpen}
        timeout={400}
        classNames={{
            enter: styles.shareEnter,
            enterActive: styles.shareEnterActive,
            exit: styles.shareExit,
            exitActive: styles.shareExitActive,
        }}
        unmountOnExit
    >
        <div className={styles.shareIcons}>
            <ShareOptions shareUrl={shareUrl} referralMessage={referralMessage} onShare={onShare} />
        </div>
    </CSSTransition>
);

export default ShareDropdown;
