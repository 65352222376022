import React from 'react';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import Skeleton from './Skeleton';
import styles from './GroupedProductsSkeleton.module.scss';

const GroupedProductsSkeleton = () => {
    const { isMobile } = useAppContext();

    return (
        <div className={styles.container}>
            <div className={styles.imgSkeleton} style={{ height: isMobile ? 150 : 200 }}>
                <Skeleton height="100%" width="60%" />
                <div className={styles.smallImgSkeleton}>
                    {Array(2)
                        .fill(null)
                        .map((_, index) => (
                            <Skeleton
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                height="50%"
                                width="100%"
                            />
                        ))}
                </div>
            </div>
            <Skeleton width="150px" />
            <Skeleton width="100px" />
        </div>
    );
};

export default GroupedProductsSkeleton;
