import React, { useMemo } from 'react';

import clsx from 'clsx';

import { MultiselectFilter, SearchFilterResponse } from '@nm-namshi-frontend/services';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './SelectableFacetBreadcrumbs.module.scss';

const GroupedFacetBreadcrumbs: React.FC<{
    facetData: SearchFilterResponse;
    shouldRefresh?: boolean;
    className?: string;
}> = ({ className, facetData, shouldRefresh = false }) => {
    const { filters } = useCatalog();

    const hasSelection = useMemo(
        () =>
            (facetData.data as unknown as SearchFilterResponse[])?.some((facet) => {
                const selected = filters?.f?.[facet.code];

                if (!selected) return false;
                if (Array.isArray(selected) && !selected.length) return false;

                return true;
            }),
        [facetData, filters],
    );

    return (
        <div className={clsx(styles.container, className, { [styles.show]: hasSelection })}>
            {hasSelection &&
                (facetData.data as unknown as SearchFilterResponse[])?.map((facet) => (
                    <FacetItemBreadcrumbs key={facet.code} facetData={facet} shouldRefresh={shouldRefresh} />
                ))}
        </div>
    );
};

const FacetItemBreadcrumbs: React.FC<{ facetData: SearchFilterResponse; shouldRefresh?: boolean }> = ({
    facetData,
    shouldRefresh,
}) => {
    const { filters, updateFilters } = useCatalog();

    const selected = filters?.f?.[facetData.code];

    const isHidden = !selected || (Array.isArray(selected) && !selected.length);

    if (isHidden) return null;

    const selectedArr = Array.isArray(selected) ? selected : [selected];

    return (
        <>
            {(selectedArr as string[]).map((item) => {
                const name = (facetData?.data as MultiselectFilter[])?.find((b) => b.code === item)?.name;

                return (
                    <button
                        key={item}
                        dir="ltr"
                        type="button"
                        onClick={() => updateFilters({ [facetData.code]: item }, { shouldRefresh })}
                        className={styles.pill}
                    >
                        {name}
                        <Icon name="close" size={12} />
                    </button>
                );
            })}
        </>
    );
};

export default GroupedFacetBreadcrumbs;
