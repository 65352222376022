import React from 'react';

import styles from './Counter.module.scss';

type TCounterProps = {
    color?: string;
    size: string;
    value: number;
};

const Counter: React.FC<TCounterProps> = ({ color, size, value }) => (
    <span className={styles.count} style={{ '--value': value, '--size': size, color } as any} />
);

export default Counter;
