/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import Carousel from 'nuka-carousel/packages/nuka/lib/carousel';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import NativeImageSlider from '@nm-namshi-frontend/core/page_components/shared/product/NativeImageSlider';

import { ModuleHeader, BannerContent } from './shared';
import { TBannerSlider } from '../../types/BannerSlider';
import { TBanner } from '../../types/common';
import styles from './BannerSliderWidget.module.scss';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';

type TBannerSliderProps = {
    moduleData: TBannerSlider;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const BannerSliderWidget: React.FC<TBannerSliderProps> = ({
    analyticsData,
    moduleData,
    renderModuleLayout: ModuleLayout,
}) => {
    const {
        autoScroll = true,
        autoScrollInterval = 3000,
        banners = [],
        infinite = true,
        moduleHeader,
        numPerRow = 1,
        showArrow = false,
        sliderInnerSpacing = 0,
        textColor,
        type,
        widgetId,
    } = moduleData || {};

    const { isArabic, isMobile } = useAppContext();

    if (isEmpty(banners)) return null;

    const bannerList: (TBanner | null)[] = new Array<TBanner | null>().concat(banners || []);

    return (
        <ModuleLayout>
            <ModuleHeader
                moduleHeader={moduleHeader}
                textColor={textColor}
                analyticsData={{
                    ...analyticsData,
                    contentModuleType: type,
                    widgetId,
                    column: 0,
                }}
            />
            <div className={styles.sliderContainer}>
                {isMobile && (
                    <NativeImageSlider
                        data={(bannerList as TBanner[]).map((banner, index) => ({
                            banner,
                            key: `${moduleData.widgetId}-slide-${index + 1}`,
                        }))}
                        renderBottomControls={({ currentSlide, goToSlide, slideCount }) => (
                            <ul className={styles.pagingDots}>
                                {[...Array(slideCount)].map((_, index) => (
                                    <li key={`${index + 1}-dot`}>
                                        <button
                                            type="button"
                                            className={clsx(styles.dot, currentSlide === index && styles.active)}
                                            aria-label={`slide ${index} bullet`}
                                            onClick={() => goToSlide(index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                        renderSlide={(item) => (
                            <BannerContent widgetId={moduleData.widgetId} bannerData={item.banner} />
                        )}
                        goAround={infinite}
                        autoplay={autoScroll}
                        autoplayInterval={autoScrollInterval}
                        animationDuration={500}
                        rightAndLeftControls={!isMobile || showArrow}
                    />
                )}
                {!isMobile && (
                    <Carousel
                        isRtl={isArabic}
                        slidesToShow={numPerRow}
                        cellSpacing={Number(sliderInnerSpacing)}
                        slidesToScroll={1}
                        wrapAround={infinite}
                        pauseOnHover
                        autoplay={autoScroll}
                        autoplayInterval={autoScrollInterval}
                        speed={500}
                        dragging={isMobile}
                        renderBottomCenterControls={({ currentSlide, goToSlide, slideCount }) => (
                            <ul className={styles.pagingDots}>
                                {[...Array(slideCount)].map((_, index) => (
                                    <li key={`${index + 1}-dot`}>
                                        <button
                                            type="button"
                                            className={clsx(styles.dot, currentSlide === index && styles.active)}
                                            aria-label={`slide ${index} bullet`}
                                            onClick={() => goToSlide(index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                        renderCenterLeftControls={
                            !isMobile || showArrow
                                ? ({ previousSlide }) => (
                                      <button
                                          type="button"
                                          onClick={previousSlide}
                                          className={clsx(styles.buttonContainer)}
                                      >
                                          {/* Icon component auto flips the icons */}
                                          <Icon name="chevronBack" />
                                      </button>
                                  )
                                : null
                        }
                        renderCenterRightControls={
                            !isMobile || showArrow
                                ? ({ nextSlide }) => (
                                      <button
                                          type="button"
                                          onClick={nextSlide}
                                          className={clsx(styles.buttonContainer)}
                                      >
                                          {/* Icon component auto flips the icons */}
                                          <Icon name="chevronForward" />
                                      </button>
                                  )
                                : null
                        }
                    >
                        {(bannerList as TBanner[]).map((banner, index) => (
                            <BannerContent
                                key={`${moduleData.widgetId}-slide-${index + 1}`}
                                widgetId={moduleData.widgetId}
                                bannerData={banner}
                            />
                        ))}
                    </Carousel>
                )}
            </div>
        </ModuleLayout>
    );
};

export default BannerSliderWidget;
