import React from 'react';

import NukaCarousel from 'nuka-carousel/packages/nuka/lib/carousel';
import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import { sanitizeDimension } from '@nm-namshi-frontend/widgets/src/utils/static';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './Carousel.module.scss';

const sideSpace = 15;

type TProps = {
    numPerView?: number;
    innerSpacing?: number;
    children: React.ReactNode[];
    className?: string;
    disableNative?: boolean;
    isLoading?: boolean;
    renderLoader?: () => React.ReactNode;
    hidePagingDots?: boolean;
};

const Carousel: React.FC<TProps> = (props) => {
    const {
        children,
        className = '',
        disableNative = false,
        hidePagingDots = false,
        innerSpacing = 2,
        isLoading = false,
        numPerView = 5,
        renderLoader,
    } = props;

    const { isArabic, isMobile } = useAppContext();

    let slideContainer: React.CSSProperties = {
        flex: `0 0 calc(${(1 / numPerView) * 100}%)`,
    };

    let nativeContainer: React.CSSProperties = {
        padding: `0 ${sideSpace / 2}px`,
    };

    if (numPerView > 1 && innerSpacing) {
        const paddingPx = sanitizeDimension(innerSpacing);

        slideContainer = {
            ...slideContainer,
            padding: isArabic ? `0 0 0 ${paddingPx}px` : `0 ${paddingPx}px 0 0`,
        };

        nativeContainer = {
            ...nativeContainer,
            margin: isArabic ? `0 0 0 -${paddingPx}px` : `0 -${paddingPx}px 0 0`,
        };
    }

    const renderLoadingSlides = () =>
        [...Array(Math.ceil(numPerView))].map((_, index) => renderSlide(renderLoader?.(), index));

    const renderSlide = (node: React.ReactNode, idx: number) => (
        <div key={idx} style={slideContainer}>
            {node}
        </div>
    );

    if (isMobile && !disableNative)
        return (
            <div className={clsx(styles.nativeContainer, className)} style={nativeContainer}>
                {isLoading ? renderLoadingSlides() : children.map(renderSlide)}
            </div>
        );

    const isScrollable = children.length > numPerView;

    return (
        <div className={styles.container}>
            <NukaCarousel
                isRtl={isArabic}
                swiping
                slidesToShow={numPerView}
                cellSpacing={innerSpacing}
                dragging={isMobile}
                className={className}
                renderCenterLeftControls={({ previousDisabled, previousSlide }) =>
                    isScrollable &&
                    !isMobile && (
                        <button
                            type="button"
                            onClick={previousSlide}
                            disabled={previousDisabled}
                            className={styles.btn}
                        >
                            <Icon name="chevronBack" />
                        </button>
                    )
                }
                renderCenterRightControls={({ nextDisabled, nextSlide }) =>
                    isScrollable &&
                    !isMobile && (
                        <button type="button" onClick={nextSlide} disabled={nextDisabled} className={styles.btn}>
                            <Icon name="chevronForward" />
                        </button>
                    )
                }
                renderBottomCenterControls={({ currentSlide, goToSlide, slideCount }) =>
                    isScrollable &&
                    !isMobile &&
                    !hidePagingDots && (
                        <ul className={styles.dotsContainer}>
                            {[...Array(Math.ceil(slideCount - numPerView + 1))].map((_, index) => (
                                <li key={`${index + 1}-dot`}>
                                    <button
                                        type="button"
                                        className={clsx(styles.dot, currentSlide === index && styles.current)}
                                        aria-label={`slide ${index} bullet`}
                                        onClick={() => goToSlide(index)}
                                    />
                                </li>
                            ))}
                        </ul>
                    )
                }
            >
                {isLoading ? renderLoadingSlides() : children.map(renderSlide)}
            </NukaCarousel>
        </div>
    );
};

export default Carousel;
