import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'next/navigation';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { CategoryTreeResponse } from '@nm-namshi-frontend/services';
import { showPageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import { buildPathFromSelectedCategories } from '@nm-namshi-frontend/core/utils/filters';
import { resetPageNumber } from '@nm-namshi-frontend/core/utils/catalog';
import { isServer } from '@nm-namshi-frontend/core/utils/helper';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';

import styles from './CategoryFilterV2.module.scss';
import AlteredLink from '../shared/AlteredLink';

type TProps = {
    data: CategoryTreeResponse[];
    facetLocation?: 'drawer' | 'modal' | 'quickFilter';
    shouldRefreshFilters: boolean;
};

const CategoryFilter = ({ data: categoryData, facetLocation, shouldRefreshFilters }: TProps) => {
    const { t } = useTranslation('common');
    const shouldAutoApply = facetLocation === 'drawer';

    const { catalogResponse, localSelectedCategories, updateLocalCategories } = useCatalog();
    const { isArabic, locale } = useAppContext();
    const searchParams = useSearchParams();
    const { pathWithoutLocale } = useAppPathname();

    const canStack = !!catalogResponse.stack;
    const decodedPathWithoutLocale = decodeURIComponent(pathWithoutLocale);
    const splittedUrl = decodedPathWithoutLocale.split('/');
    const filterKey = splittedUrl && splittedUrl[splittedUrl.length - 1];

    const getParentCategoryName = (code = '', name = '') => {
        if (['men', 'women', 'kids'].includes(code)) return `${t('all-category')} ${isArabic ? 'ال' : ''}${name}`;
        return isArabic ? `${name} - ${t('all')}` : `${t('all')} ${name}`;
    };

    const updateSelection = (nextSelection: CategoryTreeResponse) => {
        let updated;

        if (localSelectedCategories?.find((cat) => cat.code === nextSelection.code)) {
            updated = localSelectedCategories.filter((cat) => cat.code !== nextSelection.code);
        } else {
            updated = [...localSelectedCategories, nextSelection];
        }

        if (shouldAutoApply) showPageLoader();

        updateLocalCategories({
            updatedCategories: updated,
            shouldRefresh: shouldRefreshFilters,
            apply: shouldAutoApply,
        });
    };

    const getUpdatedCategories = (nextSelection: CategoryTreeResponse) => {
        const foundIdx = localSelectedCategories?.findIndex((cat) => cat.code === nextSelection.code);

        if (foundIdx === -1) {
            return [...localSelectedCategories, nextSelection];
        }

        const isExpandable = !!nextSelection.children?.length;

        if (isExpandable) {
            return [...localSelectedCategories.slice(0, foundIdx)];
        }

        const newCats = [...localSelectedCategories];
        newCats.splice(foundIdx, 1);

        return newCats;
    };

    const getNodeHref = (path: string) => {
        const basePath = `/${locale}${path}/`;

        if (isServer()) {
            return canStack && filterKey ? `${basePath}${filterKey}` : basePath;
        }

        const searchedText = searchParams.get('q');

        return canStack && filterKey
            ? `${basePath}${filterKey}`
            : `${basePath}${searchedText ? 'search/' : ''}${window.location.search}`;
    };

    let catInView;
    if (localSelectedCategories.length > 0) {
        const last = localSelectedCategories?.[localSelectedCategories?.length - 1];
        catInView = localSelectedCategories?.find((cat) => {
            if ((last?.children || []).length > 0) {
                // women-clothing, we shouldnt see 'women' so we should itself, not its parent
                return cat.idCategory === last.idCategory;
            }
            // dresses-evening => dresses (show parent)
            return cat.idCategory === last.idCategoryParent;
        });
    } else {
        catInView = {
            children: categoryData,
        };
    }

    const localSelectedCategoriesCodes = localSelectedCategories?.map((cat) => cat.code);
    const hasParent = catInView?.children?.some((child) => child.idCategoryParent);

    if (!catInView) return null;

    return (
        <div
            className={clsx({
                [styles.containerDrawer]: facetLocation === 'drawer',
            })}
        >
            {hasParent && (
                <div className={styles.catRow}>
                    <div className={clsx(styles.cat, styles.catOverview)}>
                        <span className={styles.content}>
                            <span className={styles.name}>{getParentCategoryName(catInView.code, catInView.name)}</span>
                            <span className={styles.count}>({catInView.count?.toLocaleString()})</span>
                        </span>
                    </div>
                </div>
            )}
            {catInView?.children?.map((cat) => {
                const path = buildPathFromSelectedCategories(getUpdatedCategories(cat));
                const href = resetPageNumber(getNodeHref(path)); // used for SEO only

                return (
                    <div key={cat.idCategory} className={styles.catRow}>
                        <AlteredLink
                            locale={locale}
                            href={href}
                            disabled
                            className={styles.cat}
                            onClick={() => updateSelection(cat)}
                        >
                            {cat.imageUrl ? (
                                <img alt={cat.name} className={styles.thumb} src={cat.imageUrl} />
                            ) : (
                                <div className={styles.thumb}>{cat.name.slice(0, 1)}</div>
                            )}
                            <span className={styles.content}>
                                <span className={styles.name}>{cat.name}</span>
                                <span className={styles.count}>({cat.count?.toLocaleString()})</span>
                            </span>
                            {(cat.children || []).length > 0 ? (
                                <Icon name="chevronForwardBold" />
                            ) : (
                                localSelectedCategoriesCodes?.includes(cat.code) && <Icon name="check" size={16} />
                            )}
                        </AlteredLink>
                    </div>
                );
            })}
        </div>
    );
};

export default CategoryFilter;
