import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import StickyAlert from '@nm-namshi-frontend/core/components/StickyAlert';
import useAuthenticationStore from '@nm-namshi-frontend/core/stores/useAuthenticationStore';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

const NotCleanUserAlert = () => {
    const { t } = useTranslation();
    const { isNotCleanUser, setIsVerifyEmailOpen, v1LoginProvider } = useAuthenticationStore();
    const [alertMessage, setAlertMessage] = useState('');

    React.useEffect(() => {
        if (v1LoginProvider === 'facebook') {
            setAlertMessage(t('migration.facebook-login-nomore-supported'));
        } else if (v1LoginProvider === 'apple') {
            setAlertMessage(t('migration.apple-login-nomore-supported'));
        } else {
            setAlertMessage(t('migration.your-email-not-verified'));
        }
    }, [v1LoginProvider]);

    if (!isNotCleanUser) return null;

    return (
        <StickyAlert
            variant="error"
            icon="redWarning"
            title={t('migration.verify-email-address')}
            description={alertMessage}
            ctaLabel={t('migration.verify')}
            ctaClick={() => {
                trackEvent({
                    popupName: 'banner_verify_social',
                    event: 'popup',
                    popupAction: 'verify',
                });
                setIsVerifyEmailOpen(true);
            }}
        />
    );
};

export default NotCleanUserAlert;
