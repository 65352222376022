import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import GroupedProductsSkeleton from '@nm-namshi-frontend/core/components/loaders/GroupedProductsSkeleton';
import ContinueSearchProducts from '@nm-namshi-frontend/core/page_components/shared/product/ContinueSearchProducts';
import Carousel from '@nm-namshi-frontend/core/components/Carousel';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { ContinueSearchForModule, SearchDepartmentUrls } from '@nm-namshi-frontend/services';

import { TRecentlySearchedWidget } from '../../types/CarouselWidgets';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';
import { ModuleHeader } from './shared';

type TProps = {
    moduleData: TRecentlySearchedWidget;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const CONTENT_BASE_URL = 'catalog/recent/continue_search/';

const RecentlySearchedWidget = ({ analyticsData, moduleData, renderModuleLayout: ModuleLayout }: TProps) => {
    const { innerSpacing = 5, moduleHeader, numPerView, productUrl, textColor, type, widgetId } = moduleData;

    const { isMobile } = useAppContext();

    const nPerView = numPerView || (isMobile ? 1.8 : 3.5);

    const searchDepartment = (productUrl || '')?.replace(CONTENT_BASE_URL, '') as SearchDepartmentUrls;

    const { data, isLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_RECENTLY_SEARCHED, productUrl],
        () =>
            getApiInstance().recentCarousels.getRecentSearchesCarousel({
                searchDepartment,
            }),
        {
            enabled: !!productUrl,
        },
    );

    const items = data?.continueSearchModuleList || [];

    if (!productUrl) return null;
    if (!isLoading && !items?.length) return null;

    return (
        <ModuleLayout>
            <ModuleHeader
                isLoading={isLoading}
                moduleHeader={moduleHeader}
                textColor={textColor}
                analyticsData={{ ...analyticsData, contentModuleType: type, widgetId, column: 0 }}
            />

            <Carousel
                innerSpacing={innerSpacing}
                numPerView={nPerView}
                isLoading={isLoading}
                renderLoader={() => <GroupedProductsSkeleton />}
            >
                {items.map((result: ContinueSearchForModule) => (
                    <ContinueSearchProducts
                        key={result.uri}
                        products={result}
                        analyticsData={{
                            ...analyticsData,
                            contentClickUrl: result.uri,
                            contentModuleType: 'recentSearchCarousel',
                            widgetId,
                        }}
                    />
                ))}
            </Carousel>
        </ModuleLayout>
    );
};

export default RecentlySearchedWidget;
