import React, { useState } from 'react';

import clsx from 'clsx';

import { TModuleHeader } from '@nm-namshi-frontend/widgets/src/types/common';

import styles from './CouponHeader.module.scss';

type TProps = { moduleHeader: TModuleHeader };

const CouponHeader: React.FC<TProps> = ({ moduleHeader }) => {
    const { couponCode, couponCodeBgColor, couponCodeTextColor, couponLabel, couponLayoutInCenter, titleColor } =
        moduleHeader;

    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        if (!isCopied) {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
        navigator.clipboard.writeText(couponCode || '');
    };

    return (
        <div className={clsx(styles.couponHeader, couponLayoutInCenter && styles.centered)}>
            <div
                style={{
                    color: titleColor,
                }}
            >
                {couponLabel}
            </div>
            <button type="button" className={styles.couponCode} onClick={handleCopy}>
                <div
                    className={styles.code}
                    style={{
                        color: couponCodeTextColor || titleColor,
                        backgroundColor: couponCodeBgColor || 'white',
                    }}
                >
                    {couponCode}
                </div>

                <div className={styles.icon}>
                    {!isCopied ? <CopyIcon color={titleColor} /> : <CheckIcon color={titleColor} />}
                </div>
            </button>
        </div>
    );
};

const CopyIcon: React.FC<{ color?: string; size?: number }> = ({ color = 'black', size = 20 }) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.8346 17.4997H6.66797V5.83301H15.8346M15.8346 4.16634H6.66797C6.22594 4.16634 5.80202 4.34194 5.48946 4.6545C5.1769 4.96706 5.0013 5.39098 5.0013 5.83301V17.4997C5.0013 17.9417 5.1769 18.3656 5.48946 18.6782C5.80202 18.9907 6.22594 19.1663 6.66797 19.1663H15.8346C16.2767 19.1663 16.7006 18.9907 17.0131 18.6782C17.3257 18.3656 17.5013 17.9417 17.5013 17.4997V5.83301C17.5013 5.39098 17.3257 4.96706 17.0131 4.6545C16.7006 4.34194 16.2767 4.16634 15.8346 4.16634ZM13.3346 0.833008H3.33464C2.89261 0.833008 2.46868 1.0086 2.15612 1.32116C1.84356 1.63372 1.66797 2.05765 1.66797 2.49967V14.1663H3.33464V2.49967H13.3346V0.833008Z"
            fill={color}
        />
    </svg>
);

const CheckIcon: React.FC<{ color?: string; size?: number }> = ({ color = 'black', size = 20 }) => (
    <svg width={size} height={size} viewBox="0 0 14 11" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 5.99996L5 11L14 1.99996L12.59 0.579956L5 8.16996L3.205 6.37996L1.41 4.58996L0 5.99996Z"
            fill={color}
        />
    </svg>
);

export default CouponHeader;
