import { baseConfig } from '../baseConfig';

export const isExternalLink = (url = ''): boolean => {
    const sanitizedUrl = url.trim().toLowerCase();
    return baseConfig.externalLinkIdentifiers.reduce<boolean>(
        (itDoes, thisThing): boolean => itDoes || sanitizedUrl.startsWith(thisThing),
        false,
    );
};

export const removeStartingSlash = (href = ''): string => {
    if (href.startsWith('/')) {
        return href.slice(1);
    }
    return href;
};
