import React from 'react';

import { TMetaConfig } from '@nm-namshi-frontend/widgets/src/types/MetaConfig';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './MetaConfig.module.scss';

type TProps = {
    moduleData: TMetaConfig;
};

const MetaConfig: React.FC<TProps> = ({ moduleData }) => {
    const { content, desc, h1, title } = moduleData;

    return (
        <div className={styles.container} dir="ltr">
            <div>
                <div className={styles.header}>Meta Config</div>

                <Attribute label="H1" value={h1} />
                <Attribute label="Title" value={title} />
                <Attribute label="Description" value={desc} />
                <Attribute label="Content" value={content} />
            </div>

            <div className={styles.footer}>
                <Icon name="infoCircle" /> <div>This will not show after publish</div>
            </div>
        </div>
    );
};

const Attribute: React.FC<{ label: string; value?: string }> = ({ label, value }) => (
    <div className={styles.attribute}>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>{value || '-'}</div>
    </div>
);

export default MetaConfig;
