import React from 'react';

import clsx from 'clsx';

import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';

import styles from './MountedModal.module.scss';

type TProps = {
    open: boolean;
    contentClassName?: string;
    customHeader?: React.ReactNode;
    footer?: React.ReactNode;
    onClose?: () => void;
    children: React.ReactNode;
};

const IS_MOBILE = isSchmatalog();

const MountedModal: React.FC<TProps> = ({ children, contentClassName, customHeader, footer, onClose, open }) => {
    const bodyLock = 'body { overflow: hidden }';

    return (
        <>
            {open && <style>{bodyLock}</style>}
            {open && <div className={styles.scrim} onClick={onClose} aria-hidden="true" />}
            <div
                className={clsx(styles.modal, {
                    [styles.mobile]: IS_MOBILE,
                    [styles.show]: open,
                    [styles.hide]: !open,
                })}
            >
                <div className={styles.modalContent}>
                    {customHeader}
                    <div className={clsx(styles.content, contentClassName)}>{children}</div>
                    {footer && <footer className={styles.footer}>{footer}</footer>}
                </div>
            </div>
        </>
    );
};

export default MountedModal;
