import React from 'react';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { ModulesRenderer } from '@nm-namshi-frontend/widgets/src';
import { Module } from '@nm-namshi-frontend/services/models/Module';
import { TCMSPageType } from '@nm-namshi-frontend/core/utils/analytics.types';
import { TModulePosition, TSectionLayout } from '@nm-namshi-frontend/widgets/src/types/ModulesRenderer';

type TProps = {
    position?: TModulePosition; // Ideally we need this to come in from the service
    cmsData: Array<Module>;
    pageType?: TCMSPageType;
};

// No-frills CMS usage per position in each page
const PageCms: React.FC<TProps> = ({ cmsData = [], pageType = 'static', position }) => {
    const { device, lang } = useAppContext();

    const cmsDataFilteredByPosition = position
        ? cmsData.filter(
              ({ modules, position: currentCmsPosition }) =>
                  currentCmsPosition === position || modules?.[0]?.position === position,
          )
        : cmsData;

    return (
        <ModulesRenderer
            eventLocation={pageType}
            device={device}
            data={cmsDataFilteredByPosition as TSectionLayout[]}
            language={lang}
        />
    );
};

export default PageCms;
