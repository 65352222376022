import React, { useEffect, useRef } from 'react';

import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import { ContinueSearchForModule } from '@nm-namshi-frontend/services';
import { TContentModuleType } from '@nm-namshi-frontend/core/utils/analytics.types';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useIsInViewport from '@nm-namshi-frontend/core/hooks/useIsInViewport';
import { WIDGET_IDS } from '@nm-namshi-frontend/core/config';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import ProductImage from './ProductImage';
import styles from './ContinueSearchProducts.module.scss';

type TContinueSearchProducts = {
    products: ContinueSearchForModule;
    analyticsData: {
        contentModuleType: TContentModuleType;
        contentClickUrl: string;
        widgetId?: number;
    };
};

const ContinueSearchProducts = ({ analyticsData, products }: TContinueSearchProducts) => {
    const { images, noOfItemsFormatted, searchKeyword, uri } = products;
    const { contentClickUrl, contentModuleType, widgetId } = analyticsData;

    const { isMobile, locale } = useAppContext();

    const ref = useRef<HTMLDivElement>(null);
    const { isViewed } = useIsInViewport({ ref });

    useEffect(() => {
        if (isViewed) {
            trackEvent({
                event: 'impression_content',
                pageType: 'home',
                imagePath: images[0],
                contentClickUrl: uri,
                contentModuleType,
                widgetId: WIDGET_IDS.RECENTLY_SEARCHED,
            });
        }
    }, [isViewed]);

    const handleGroupedProductsClick = () => {
        trackEvent({
            event: 'contentClick',
            contentClickUrl,
            contentModuleType,
            widgetId,
        });
    };

    const renderImage = (index: number) => (
        <ProductImage
            key={index}
            imageKey={images?.[index]}
            altText={`img - ${index + 1}`}
            size={IMAGE_SIZE.ML}
            containerClassname={styles.imgContainer}
            imageClassname={styles.img}
        />
    );

    const renderImages = () => (
        <div className={styles.imagesWrapper}>
            <div className={styles.largeImage}>{renderImage(0)}</div>
            <div className={styles.smallImages}>
                {renderImage(1)}
                {renderImage(2)}
            </div>
        </div>
    );

    const renderTitleAndKeyWord = () => (
        <div className={styles.titleWrapper}>
            <span className={styles.title}>{searchKeyword}</span>
            <span className={styles.itemsCount}>{noOfItemsFormatted}</span>
        </div>
    );

    return (
        <AlteredLink
            locale={locale}
            href={uri}
            className={isMobile ? styles.mobileContainer : styles.desktopContainer}
            onClick={handleGroupedProductsClick}
        >
            <div ref={ref}>
                {renderImages()}
                {renderTitleAndKeyWord()}
            </div>
        </AlteredLink>
    );
};

export default ContinueSearchProducts;
