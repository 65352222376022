import { useState } from 'react';

import useOnclickOutside from 'react-cool-onclickoutside';
import clsx from 'clsx';

import { isBrowser } from '@nm-namshi-frontend/core/utils/helper';
import { getShareUrl } from '@nm-namshi-frontend/core/utils/shareUtils';
import { SHARE_URL_UTM_CAMPAIGN } from '@nm-namshi-frontend/core/config';
import { TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';
import ShareIcon from '@nm-namshi-frontend/core/components/ShareIcon';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import Loader from '@nm-namshi-frontend/core/components/loaders/Loader';

import styles from './ShareCatalog.module.scss';
import ShareDropdown from './ShareDropdown';
import NativeShare from './NativeShare';

type TShareCatalogProps = {
    pageType: TPageType;
    sku?: string;
    color?: string;
    size?: number;
    utmSource?: string;
    onShare: ({ url }: { url: string }) => void;
    referralMessage: string;
    outlined?: boolean;
};

export const ShareCatalog: React.FC<TShareCatalogProps> = (props) => {
    const { color = 'black', onShare, outlined = false, pageType, referralMessage, size, sku, utmSource } = props;

    const { isMobile } = useAppContext();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const ref = useOnclickOutside(() => setIsOpen(false));

    const url = isBrowser()
        ? getShareUrl({
              page: pageType,
              utmCampaign: SHARE_URL_UTM_CAMPAIGN,
              utmSource,
              term: sku,
          })
        : '';

    const renderIcon = () =>
        isLoading ? <Loader size={size} color={color} /> : <ShareIcon size={size} color={color} />;

    if (isMobile) {
        return (
            <NativeShare
                url={url}
                referralMessage={referralMessage}
                renderIcon={renderIcon}
                onShare={() => onShare({ url })}
                setIsLoading={setIsLoading}
            />
        );
    }

    return (
        <div ref={ref} className={styles.container}>
            <div
                className={clsx(styles.iconContainer, outlined && styles.outlined)}
                onMouseEnter={() => setIsOpen(true)}
            >
                {renderIcon()}
            </div>
            <ShareDropdown
                shareUrl={url}
                referralMessage={referralMessage}
                isOpen={isOpen}
                onShare={() => onShare({ url })}
            />
        </div>
    );
};
