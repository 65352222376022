// used in
// 1. schmatalog PLP
// 2. bothalogs > midroll filters > see all  > opens this modal

import React, { useEffect, useState } from 'react';

import { isArray, isEmpty, isUndefined, groupBy, map as lodashMap } from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Button from '@nm-namshi-frontend/core/components/Button';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import Facet from '@nm-namshi-frontend/core/page_components/productListingPage/Facet';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { showPageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import {
    FilterType,
    GroupedBooleanFilter,
    MultiselectFilter,
    SearchFilterResponse,
} from '@nm-namshi-frontend/services';
import MountedModal from '@nm-namshi-frontend/core/components/MountedModal';

import styles from './FiltersModal.module.scss';
import FilterBreadcrumbs from './FilterBreadcrumbs';
import MySizesToggle from './MySizesToggle';

type TFiltersModalProps = {
    filterKey?: string;
    initialOpened?: string | null;
    onClose: () => void;
    open: boolean;
    trackFilter?: boolean;
};

const FiltersModal: React.FC<TFiltersModalProps> = ({ filterKey, initialOpened, onClose, open, trackFilter }) => {
    const { t } = useTranslation(['catalog', 'common']);
    const { locale } = useAppPathname();
    const localeCurrency = getLocaleConfig(locale).currencyCode;
    const {
        applyFilters,
        catalogResponse,
        clearFilters,
        clearRefreshedFiltersResponse,
        filters,
        isRefreshedFiltersLoading,
        refreshedFiltersResponse,
        trackFilterClick,
    } = useCatalog();
    const [activeFilterGroup, setActiveFilterGroup] = useState('');

    useEffect(() => {
        if (initialOpened) setActiveFilterGroup(initialOpened);
    }, [initialOpened]);

    const areFiltersApplied = 'f' in filters && !isEmpty(filters.f);

    const onApply = () => {
        if (filterKey) trackFilterClick({ filterCode: filterKey, newValue: filters.f as any });

        applyFilters();
        onClose();
        showPageLoader();
    };

    const renderHeader = () => (
        <header className={styles.header}>
            <button
                type="button"
                disabled={!areFiltersApplied}
                className={clsx(styles.resetFilter, areFiltersApplied && styles.resetFilterActive)}
                onClick={() => {
                    showPageLoader();
                    clearFilters(null, true);
                    onClose();
                }}
            >
                {t('filters-reset-label')}
            </button>
            <h3>{t('all-filters')}</h3>
            <button
                type="button"
                onClick={() => {
                    onClose();
                    clearRefreshedFiltersResponse();
                }}
            >
                {t('cancel')}
            </button>
        </header>
    );

    const renderCount = (filter: SearchFilterResponse) => {
        const selected = filters.f?.[filter.code];

        switch (filter.type as FilterType | 'grouped') {
            case 'singleselect': {
                if (!selected) return null;

                const options = catalogResponse.nav?.filters.find(({ code }) => code === filter.code)
                    ?.data as MultiselectFilter[];
                const selectedLabel = options?.find(({ code }) => code === selected)?.name;

                if (!selectedLabel) return null;

                return <span className={styles.count}>{selectedLabel}</span>;
            }

            case 'multiselect': {
                if (!selected) return null;

                const count = isArray(selected) ? selected.length : 1;
                if (!count) return null;

                return (
                    <span className={styles.count}>
                        {count} {t('selected')}
                    </span>
                );
            }

            case 'range': {
                if (!selected) return null;

                return (
                    <>
                        {typeof selected === 'object' && !Array.isArray(selected) && (
                            <span className={styles.count}>
                                {selected.min} - {selected.max} {localeCurrency}
                            </span>
                        )}
                    </>
                );
            }

            case 'grouped': {
                const count = (filter.data as unknown as SearchFilterResponse[]).reduce((acc, { code }) => {
                    const value = filters.f?.[code];

                    if (!value) return acc;

                    const selectedCount = isArray(value) ? value.length : 1;

                    return acc + selectedCount;
                }, 0);

                if (!count) return null;

                return (
                    <span className={styles.count}>
                        {count} {t('selected')}
                    </span>
                );
            }

            default:
                return null;
        }
    };

    const facets = refreshedFiltersResponse?.filters || catalogResponse.nav?.filters;
    const nonEmptyFacets = facets?.filter(({ data }) => !isEmpty(data) && !isUndefined(data)) || null; // Guard clause to protect against messy BE data

    if (!nonEmptyFacets || nonEmptyFacets.length < 1) return null;

    // we put bools at the top
    const boolFacets = nonEmptyFacets.filter((facet) => facet.type === 'boolean');

    // we group sizes e.g. clothing size, shoe size, bra size, but only in this modal
    const facetsBelongingToGroups = nonEmptyFacets.filter((facet) => !!facet.groupCode);
    const groupedFacets = groupBy(facetsBelongingToGroups, (f) => f.groupCode);
    const finalGroupedFacets = lodashMap(
        groupedFacets,
        (data, code) =>
            ({
                code,
                data,
                name: data[0]?.groupName || '',
                sortKey: data[0].sortKey,
                type: 'grouped',
            } as unknown as SearchFilterResponse),
    );

    const otherFacets = nonEmptyFacets.filter(
        (facet) => !['boolean', 'group_boolean'].includes(facet.type) && !facet.groupCode,
    );
    const columnFacets = [...otherFacets, ...finalGroupedFacets].sort((a, b) => (a.sortKey ?? 0) - (b.sortKey ?? 0));

    const selectedGroupCode = activeFilterGroup || columnFacets[0].code;
    const filterInView = columnFacets.find((facet) => facet.code === selectedGroupCode) || columnFacets[0];

    const mySizesFacet = facets?.find(
        ({ code: facetCode, type: facetType }) => facetCode === 'my_sizes' && facetType === 'group_boolean',
    );

    return (
        <>
            <MountedModal
                customHeader={renderHeader()}
                open={open}
                onClose={onClose}
                contentClassName={styles.container}
                footer={
                    <Button variant="tertiary" onClick={onApply} loading={isRefreshedFiltersLoading}>
                        {t('filters-and-sort.apply-filter')}
                    </Button>
                }
            >
                <div className={styles.filterLayoutContainer}>
                    <div className={styles.filterLayoutHighlights}>
                        {boolFacets.map((bf) => (
                            <div key={bf.code}>
                                <Facet
                                    facetData={bf}
                                    facetLocation="modal"
                                    shouldRefreshFilters
                                    trackFilter={trackFilter}
                                />
                            </div>
                        ))}
                        {mySizesFacet && (
                            <div>
                                <MySizesToggle
                                    facet={mySizesFacet as SearchFilterResponse & { data: GroupedBooleanFilter }}
                                    onProfileSizesEdited={onClose}
                                />
                            </div>
                        )}
                    </div>
                    <FilterBreadcrumbs facetData={filterInView} facets={columnFacets} shouldRefresh />
                    <div className={styles.filterLayoutColumns}>
                        <div className={styles.filterLayoutMenu}>
                            {columnFacets.map((filter) => (
                                <button
                                    className={clsx(
                                        styles.menuItem,
                                        selectedGroupCode === filter.code && styles.menuItemSelected,
                                    )}
                                    key={filter.code}
                                    onClick={() => setActiveFilterGroup(filter.code)}
                                    type="button"
                                >
                                    <span className={styles.name}>{filter.name}</span>
                                    {renderCount(filter)}
                                </button>
                            ))}
                        </div>
                        <div className={styles.filterLayoutContent}>
                            {columnFacets.map((facet) => (
                                <div
                                    key={facet.code}
                                    style={selectedGroupCode !== facet.code ? { display: 'none' } : {}}
                                >
                                    <Facet
                                        facetData={facet}
                                        facetLocation="modal"
                                        shouldRefreshFilters
                                        trackFilter={trackFilter}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </MountedModal>
            {isRefreshedFiltersLoading && <div className={styles.blockingScrim} />}
        </>
    );
};

export default FiltersModal;
