import { TBaseConfig } from './types/baseConfig';

export const CDN_DOMAIN = 'https://f.nooncdn.com';
export const IMG_BASE = `${CDN_DOMAIN}/s/app/com`;

export const baseConfig: TBaseConfig = {
    urls: {
        productImageBase: `${CDN_DOMAIN}/products`,
    },
    externalLinkIdentifiers: ['http', 'https', 'www'],
};
