import React from 'react';

import clsx from 'clsx';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './TextLinksWidget.module.scss';
import { TTextLinks } from '../../types/TextLinks';
import { TLinkData } from '../../types/common';
import { isExternalLink } from '../../utils/url';
import { ModuleLink } from './shared';
import { TModuleLayout } from '../../types/ModulesRenderer';

export type TTextLinksProps = {
    moduleData: TTextLinks;
    renderModuleLayout: TModuleLayout;
};

const TextLinksWidget: React.FC<TTextLinksProps> = ({ moduleData, renderModuleLayout: ModuleLayout }) => {
    const { links = [], summaryText, titleText } = moduleData || {};
    const { isArabic, isMobile } = useAppContext();

    if (!links.length) return null;

    return (
        <ModuleLayout>
            <div className={styles.container}>
                <h3 className={clsx(styles.titleContainer, isMobile && styles.mobile)}>{titleText}</h3>
                <p className={styles.summaryContainer}>{summaryText}</p>
                {links && (
                    <ul
                        className={clsx(styles.titleContainer, styles.linksListContainer, isArabic && styles.isArabic)}
                        data-qa="widget_textLinks"
                    >
                        {titleText}
                        {links.map((link: TLinkData, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={`${i}-${link.linkUrl}`}>
                                <ModuleLink
                                    href={link.linkUrl}
                                    linkClass="--namshi-linkBody"
                                    external={isExternalLink(link.linkUrl)}
                                >
                                    {link.linkText}
                                </ModuleLink>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </ModuleLayout>
    );
};

export default TextLinksWidget;
