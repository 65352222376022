import React from 'react';

import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { MultiselectFilter, SearchFilterResponse } from '@nm-namshi-frontend/services';

import styles from './SelectableFacetBreadcrumbs.module.scss';

type TProps = {
    facetData: SearchFilterResponse;
    shouldRefresh?: boolean;
    className?: string;
};

const SelectableFacetBreadcrumbs: React.FC<TProps> = ({ className, facetData, shouldRefresh = true }) => {
    const { filters, updateFilters } = useCatalog();

    const selected = filters?.f?.[facetData.code];

    const isHidden = !selected || (Array.isArray(selected) && !selected.length);

    const selectedArr = Array.isArray(selected) ? selected : [selected];

    return (
        <div
            className={clsx(styles.container, className, {
                [styles.show]: !isHidden,
            })}
        >
            {!isHidden &&
                (selectedArr as string[]).map((item) => {
                    const name = (facetData?.data as MultiselectFilter[])?.find((b) => b.code === item)?.name;

                    return (
                        <button
                            key={item}
                            type="button"
                            onClick={() => updateFilters({ [facetData.code]: item }, { shouldRefresh })}
                            className={styles.pill}
                        >
                            {name}
                            <Icon name="close" size={12} />
                        </button>
                    );
                })}
        </div>
    );
};

export default SelectableFacetBreadcrumbs;
