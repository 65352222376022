import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import LoaderBounce from '@nm-namshi-frontend/core/components/loaders/LoaderBounce';
import Fader from '@nm-namshi-frontend/core/page_components/shared/fader/Fader';
import useGetProducts from '@nm-namshi-frontend/widgets/src/hooks/useGetProducts';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './ProductListWidget.module.scss';
import { ModuleHeader, ModuleLink } from './shared';
import { TProductList } from '../../types/ProductList';
import { isExternalLink } from '../../utils/url';
import ModuleProductBox from './shared/ModuleProductBox';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';

type TProps = {
    analyticsData: TModuleAnalytics;
    moduleData: TProductList;
    renderModuleLayout: TModuleLayout;
};

const ProductListWidget: React.FC<TProps> = ({ analyticsData, moduleData, renderModuleLayout: ModuleLayout }) => {
    const {
        initialNum = 0,
        moduleHeader,
        productUrl,
        showAllLabel,
        showMoreLabel,
        textColor,
        type,
        widgetId,
    } = moduleData || {};

    const { isMobile } = useAppContext();
    const { data, isLoading } = useGetProducts({ uri: productUrl });
    const [visibleProductCount, setVisibleProductCount] = useState(initialNum);
    const [isIncrementDone, setIsIncrementDone] = useState(visibleProductCount > initialNum);

    const products = data?.products || [];

    useEffect(() => {
        if (initialNum) {
            // Set visibleProductCount if an initialNum is provided (from moduleData) or...
            setVisibleProductCount(initialNum);
        } else if (products?.length) {
            // ... set visibleProductCount to the length of products fetched
            setVisibleProductCount(products.length);
        }
    }, [initialNum, products]);

    useEffect(() => {
        setIsIncrementDone(visibleProductCount > initialNum);
    }, [visibleProductCount, initialNum]);

    // Callbacks
    const showMore = () => {
        setVisibleProductCount(visibleProductCount + initialNum);
        setIsIncrementDone(true);
    };

    const areAllProductsVisible = visibleProductCount >= (products?.length || 0);
    const productsToBeDisplayed = products?.slice(0, visibleProductCount);

    if (isLoading) return <LoaderBounce size={14} />;

    if (isEmpty(productsToBeDisplayed)) return null;

    return (
        <Fader isActive={!!products?.length}>
            <ModuleLayout>
                <ModuleHeader
                    moduleHeader={moduleHeader}
                    textColor={textColor}
                    analyticsData={{
                        ...analyticsData,
                        contentModuleType: type,
                        widgetId,
                        column: 0,
                    }}
                />
                <div className={styles.list} data-qa="widget_productList">
                    {productsToBeDisplayed.map((product, index) => (
                        <span className={clsx(styles.listItem, isMobile && styles.mobile)} key={product?.sku}>
                            <ModuleProductBox
                                product={product}
                                analyticsData={{ ...analyticsData, row: 0, column: index }}
                            />
                        </span>
                    ))}
                </div>
                {!areAllProductsVisible && (
                    <div className={styles.ctaContainer}>
                        {isIncrementDone ? (
                            <ModuleLink
                                href={moduleData.productUrl || ''}
                                linkClass="--namshi-linkBody"
                                external={isExternalLink(moduleData.productUrl)}
                            >
                                {showAllLabel}
                            </ModuleLink>
                        ) : (
                            <button type="button" onClick={showMore} aria-label={showMoreLabel}>
                                {showMoreLabel}
                            </button>
                        )}
                    </div>
                )}
            </ModuleLayout>
        </Fader>
    );
};

export default ProductListWidget;
