import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { CategoryTreeResponse } from '@nm-namshi-frontend/services';
import { buildPathFromSelectedCategories, getCategoriesBreadcrumbPath } from '@nm-namshi-frontend/core/utils/filters';
import { showPageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './CategoryFilterBreadcrumbs.module.scss';
import AlteredLink from '../shared/AlteredLink';

type TProps = {
    categoryData: CategoryTreeResponse[];
    facetLocation?: 'drawer' | 'modal' | 'quickFilter';
    shouldRefresh?: boolean;
    className?: string;
};

const CategoryFilterBreadcrumbs = ({ categoryData, className, facetLocation, shouldRefresh = false }: TProps) => {
    const { locale } = useAppContext();
    const { localSelectedCategories, updateLocalCategories } = useCatalog();

    const shouldAutoApply = facetLocation === 'drawer';

    if (localSelectedCategories.length < 1) return null;

    const lastSelectedCategory = localSelectedCategories[localSelectedCategories.length - 1];
    const breadcrumbs = getCategoriesBreadcrumbPath(categoryData, lastSelectedCategory);

    // if its women > womens-clothing etc don't show L1
    // but if its women | men | kids > etc, then show
    const finalBreadcrumbs = categoryData.length > 1 ? breadcrumbs : breadcrumbs?.slice(1);

    const getUpdatedCategories = (id: number) => {
        const sliceIndex = localSelectedCategories.map((cat: CategoryTreeResponse) => cat.idCategory).indexOf(id);
        const updatedCategories = localSelectedCategories.slice(0, sliceIndex);

        return updatedCategories;
    };

    const remove = (id: number) => {
        if (shouldAutoApply) showPageLoader();
        updateLocalCategories({
            updatedCategories: getUpdatedCategories(id),
            shouldRefresh,
            apply: false,
        });
    };

    const isHidden = !finalBreadcrumbs || finalBreadcrumbs.length < 1;

    return (
        <div
            className={clsx(styles.container, className, {
                [styles.containerDrawer]: facetLocation === 'drawer',
                [styles.show]: !isHidden,
            })}
        >
            {!isHidden &&
                finalBreadcrumbs.map((crumb, idx) => {
                    const href = buildPathFromSelectedCategories(getUpdatedCategories(crumb.idCategory));

                    return (
                        <div key={crumb.code} className={styles.btnWrapper}>
                            <AlteredLink
                                className={styles.btn}
                                locale={locale}
                                href={href}
                                disabled={!shouldAutoApply}
                                onClick={() => remove(crumb.idCategory)}
                            >
                                {crumb.name}
                                <Icon name="close" size={12} />
                            </AlteredLink>

                            {idx < finalBreadcrumbs.length - 1 && <Icon name="chevronForwardBold" size={16} />}
                        </div>
                    );
                })}
        </div>
    );
};

export default CategoryFilterBreadcrumbs;
