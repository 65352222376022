import React from 'react';

import ProductCarousel from '@nm-namshi-frontend/core/page_components/shared/product/carousel/ProductCarousel';
import useGetRecommendedProducts from '@nm-namshi-frontend/widgets/src/hooks/useGetRecommendedProducts';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { isCMS } from '@nm-namshi-frontend/core/utils/helper';

import { ProductBoxVariant } from '../../types/common';
import { ModuleHeader } from './shared';
import { TRecommendedProductsWidget } from '../../types/CarouselWidgets';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';

type TProps = {
    analyticsData: TModuleAnalytics;
    moduleData: TRecommendedProductsWidget;
    renderModuleLayout: TModuleLayout;
};

const RecommendedProductsWidget: React.FC<TProps> = ({
    analyticsData,
    moduleData,
    renderModuleLayout: ModuleLayout,
}) => {
    const {
        excludeParentBrandCode,
        excludeTags,
        moduleHeader,
        placement = 'home-page',
        previewSku = '',
        tags,
        textColor,
        title,
        type,
        widgetId,
    } = moduleData;

    const { catalogResponse } = useCatalog() || {};

    const excTags = excludeTags || [];
    let sku = isCMS() ? previewSku : '';

    if (catalogResponse && catalogResponse.type === 'details' && 'product' in catalogResponse.data) {
        const { product } = catalogResponse.data;

        if (excludeParentBrandCode) excTags.push(product.brandCode);

        sku = product.sku;
    }

    const { data, isLoading } = useGetRecommendedProducts({
        placement,
        tags,
        excludeTags: excTags,
        ...(sku ? { sku } : {}),
    });

    const products = data?.products || [];
    if (!isLoading && !products.length) return null;

    return (
        <ModuleLayout>
            <ModuleHeader
                isLoading={isLoading}
                moduleHeader={
                    moduleHeader || {
                        titleText: title,
                    }
                }
                textColor={textColor}
                analyticsData={{ ...analyticsData, contentModuleType: type, widgetId, column: 0 }}
            />
            <ProductCarousel
                isLoading={isLoading}
                analyticsData={{ widgetId, pageType: analyticsData.pageType }}
                products={products}
                variant={ProductBoxVariant.FULL}
            />
        </ModuleLayout>
    );
};

export default RecommendedProductsWidget;
