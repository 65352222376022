import React from 'react';

import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './YamlEditorModule.module.scss';

type TProps = {
    moduleData: {
        name: string;
        type: string;
    };
};

const PageInclude: React.FC<TProps> = ({ moduleData }) => {
    const { name } = moduleData;

    return (
        <div className={styles.container} dir="ltr">
            <div>
                <div className={styles.header}>YAML Editor Widget</div>
                <Attribute label="Name" value={name} />
            </div>

            <div className={styles.footer}>
                <Icon name="infoCircle" /> <div>This will not show after publish</div>
            </div>
        </div>
    );
};

const Attribute: React.FC<{ label: string; value?: string }> = ({ label, value }) => (
    <div className={styles.attribute}>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>{value}</div>
    </div>
);

export default PageInclude;
