import React from 'react';

import { THeaderWidget } from '../../types/HeaderWidget';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';
import { ModuleHeader } from './shared';

type THeaderWidgetProps = {
    moduleData: THeaderWidget;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const HeaderWidget: React.FC<THeaderWidgetProps> = ({
    analyticsData,
    moduleData,
    renderModuleLayout: ModuleLayout,
}) => {
    const { textColor, type, widgetId } = moduleData;

    return (
        <ModuleLayout>
            <ModuleHeader
                moduleHeader={moduleData}
                textColor={textColor}
                analyticsData={{
                    ...analyticsData,
                    contentModuleType: type,
                    widgetId,
                }}
            />
        </ModuleLayout>
    );
};

export default HeaderWidget;
