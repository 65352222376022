import React, { useState, useEffect } from 'react';

import get from 'lodash/get';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import { readFromLocalStorage, saveToLocalStorage } from '@nm-namshi-frontend/core/utils/helper';
import { FIREBASE_APP_DYNAMIC_LINK } from '@nm-namshi-frontend/core/config';
import { LOCALSTORAGE_ID } from '@nm-namshi-frontend/core/constants/uiConstants';
import { getWidgetImageUrl } from '@nm-namshi-frontend/core/utils/images';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import { TBannerModal } from '../../types/BannerModal';
import ModuleImage from './shared/ModuleImage';

type TProps = {
    moduleData: TBannerModal;
};

const redirectTo = (url: string) => {
    window.location.href = url;
};

const BannerModalWidget: React.FC<TProps> = ({ moduleData }) => {
    const { isMobile } = useAppContext();
    const [isVisible, setIsVisible] = useState(false);
    const bannerUrl = get(moduleData, 'banners[0].imageUrl');
    const assetId = get(moduleData, 'banners[0].assetId');
    const isVisibleFromConfig = get(moduleData, 'isVisible');

    const MODAL_WIDTH = isMobile ? '300px' : '400px';

    useEffect(() => {
        const isPromoHidden = readFromLocalStorage(LOCALSTORAGE_ID.IS_PROMO_HIDDEN);
        if (isPromoHidden || !isVisibleFromConfig) return;
        setIsVisible(true);
    }, [isVisibleFromConfig]);

    const closeModal = () => {
        saveToLocalStorage(LOCALSTORAGE_ID.IS_PROMO_HIDDEN, true);
        setIsVisible(false);
    };

    const handleModalBannerClick = () => {
        if (!isMobile) return;

        redirectTo(FIREBASE_APP_DYNAMIC_LINK);
    };

    if (!bannerUrl || !isVisible) return <></>;

    return (
        <Modal onClose={closeModal} desktopWidth={MODAL_WIDTH} open={isVisible} isContentCovering>
            <div tabIndex={0} role="button" onClick={handleModalBannerClick} onKeyPress={handleModalBannerClick}>
                <ModuleImage
                    {...(assetId ? { id: assetId.toString() } : {})}
                    src={getWidgetImageUrl(bannerUrl)}
                    alt="Promotional Banner"
                    loading="lazy"
                    width={isMobile ? 300 : 400}
                    height={isMobile ? 330 : 450}
                />
            </div>
        </Modal>
    );
};

export default BannerModalWidget;
