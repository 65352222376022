import { isBrowser } from '@nm-namshi-frontend/core/utils/helper';

import { TPageType } from './analytics.types';

export const FACEBOOK_SHARE = 'https://www.facebook.com/sharer/sharer.php?u=';
export const WHATSAPP_SHARE = 'https://web.whatsapp.com/send?text=';

export const getShareUrl = ({
    page,
    search,
    shareUrl,
    term,
    utmCampaign,
    utmSource,
}: {
    page: TPageType;
    search?: Record<string, string>;
    utmCampaign: string;
    term?: string; // eg. SKU in case of PDP
    shareUrl?: string;
    utmSource?: string;
}) => {
    const searchObject = search ? JSON.parse('{"a":"a"}') : null;

    const utm_campaign = `utm_campaign=${utmCampaign}`;
    const utm_medium = 'utm_medium=web';
    let utm_source = 'utm_source=';
    let utm_term = 'utm_term=';

    // default to browser url if no shareUrl is provided
    let url = shareUrl || global.location?.href;
    const utmSourceIndex = url.indexOf('utm_source');
    if (utmSourceIndex > -1) {
        url = url.substring(0, utmSourceIndex - 1);
    }

    let url_separator = '?';
    if (url.indexOf('?') > -1) {
        url_separator = '&';
    }

    if (page === 'listing') {
        let category = '';
        if (searchObject && searchObject.category) {
            category = searchObject.category[0] || '';
        }
        utm_source += 'share_url';

        // For now - utm_term for listing pages arent of much use .. lets revisit this if there is a real requirement for it
        if (category) {
            utm_term += category;
        } else {
            utm_term = '';
        }
    } else if (page === 'pdp') {
        const sku = term;
        utm_source += 'share_product';
        if (sku) {
            utm_term += sku;
        } else {
            utm_term = '';
        }
    } else if (page === 'static') {
        utm_source += utmSource || '';
    }

    return `${url}${url_separator}${utm_source}&${utm_medium}&${utm_campaign}&${utm_term || ''}`;
};

export const shareOnFacebook = ({ referralMessage, shareUrl }: { referralMessage: string; shareUrl: string }) => {
    if (isBrowser()) {
        window.open(
            `${FACEBOOK_SHARE}${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(referralMessage)}`,
            '_blank',
        );
    }
};

export const copyShareText = ({ referralMessage, shareUrl }: { referralMessage: string; shareUrl: string }) => {
    navigator.clipboard.writeText(`${referralMessage} ${shareUrl}`);
};

export const shareOnWhatsapp = ({ referralMessage, shareUrl }: { referralMessage: string; shareUrl: string }) => {
    if (isBrowser()) {
        window.open(
            `${WHATSAPP_SHARE}${encodeURIComponent(referralMessage)}%0A%0A${encodeURIComponent(shareUrl)}`,
            '_blank',
        );
    }
};

export const shareOnEmail = ({
    referralMessage,
    shareUrl,
    subject,
}: {
    subject: string;
    referralMessage: string;
    shareUrl: string;
}) => {
    if (isBrowser()) {
        window.open(`mailto:?subject=${subject}&body=${referralMessage} ${shareUrl}`, '_blank');
    }
};

export const nativeShare = (args: { referralMessage?: string; url: string }) => {
    const { referralMessage, url } = args;

    const shareData = { title: referralMessage, text: referralMessage, url };
    if (navigator.canShare && navigator.canShare(shareData)) {
        navigator.share(shareData).catch(() => {
            /* */
        });
    }
};
