/**
 * Catalog Context
 *
 * [To be eventually replaced by zustand]
 *
 * Responsible for:
 *  - Holding catalog response from SSR
 *  - Handling sorting (essentially redirecting to proper URL when applied)
 *  - Handling filtering (background refresh in filter modal & redirecting to proper URL when applied)
 *  - Handling filter categories which now support multiselect and background refresh
 *
 * Values:
 *      -> catalogResponse
 *      -> filters
 *      -> refreshedFiltersResponse
 *      -> isRefreshedFiltersLoading
 *      -> localSelectedCategories
 * Functions:
 *      -> updateFilters
 *      -> clearFilters
 *      -> changePage
 *      -> applySort
 *      -> clearRefreshedFiltersResponse
 *      -> updateLocalCategories
 *      -> resetFilterToInitialValue
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import QueryString from 'qs';
import { cloneDeep, get, isArray, mergeWith, omit, pick, set, union, without } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import {
    ApiError,
    CatalogResponse,
    CategoryTreeResponse,
    PlpFiltersResponse,
    MultiselectFilter,
    SearchSortBy,
    SearchSortDir,
} from '@nm-namshi-frontend/services';
import { RESET_PAGE_NUMBER } from '@nm-namshi-frontend/core/constants/uiConstants';
import useCatalogStore from '@nm-namshi-frontend/core/stores/useCatalogStore';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import {
    buildPathFromSelectedCategories,
    extractFilterKeyFromPath,
    extractSelectedCategoriesFromTree,
} from '@nm-namshi-frontend/core/utils/filters';
import { NavDepartmentsResponse } from '@nm-namshi-frontend/core/types';

import useAppContext from './AppContext';

type TProps = {
    children: React.ReactNode;
    catalogResponse: CatalogResponse;
    searchParams?: string | URLSearchParams | string[][] | Record<string, string> | undefined;
    navigationData: NavDepartmentsResponse;
    selectedDepartmentId: string;
    selectedSubDepartmentId: string;
};

// We assume filters object depth to be always <2. Though technically, this approach applies to more
type TIncomingFilter = {
    f?: Record<string, string | Array<string> | Record<string, number>>;
    page?: string;
    q?: string;
    sort?: { by: SearchSortBy; dir: SearchSortDir };
};

type TNewFilters = Record<string, string | string[] | [number, number]>;

type TUpdateFilterOptions = {
    apply?: boolean;
    shouldRefresh?: boolean;
    trackFilter?: boolean;
    mode?: 'toggle' | 'add';
};

type TContext = Omit<TProps, 'children'> & {
    updateFilters: (filters: TNewFilters, options?: TUpdateFilterOptions) => void;
    getUpdatedFiltersLink: (filters: TNewFilters, options?: TUpdateFilterOptions) => string;
    clearFilters: (code: string | null, autoApply?: boolean) => void;
    applyFilters: () => void;
    changePage: (page: string) => string;
    applySort: (by: SearchSortBy, dir: SearchSortDir) => void;
    filters: TIncomingFilter;
    searchParams?: string | URLSearchParams | string[][] | Record<string, string> | undefined;
    listingPagehasNoProducts: boolean | undefined;
    navigationData: NavDepartmentsResponse;
    selectedDepartmentId: string;
    selectedSubDepartmentId: string;
    refreshedFiltersResponse: null | PlpFiltersResponse;
    clearRefreshedFiltersResponse: () => void;
    isRefreshedFiltersLoading: boolean;
    localSelectedCategories: Array<CategoryTreeResponse>;
    updateLocalCategories: ({
        apply,
        shouldRefresh,
        updatedCategories,
    }: {
        apply?: boolean;
        shouldRefresh?: boolean;
        updatedCategories: Array<CategoryTreeResponse>;
    }) => void;
    resetFilterToInitialValue: (filterCode: string) => void;
    trackFilterClick: (args: { filterCode: string; newValue: TNewFilters[string] }) => void;
    trackFilterUnclick: (args: { filterCode: string; newValue: TNewFilters[string] }) => void;
};

const CatalogContext = React.createContext<TContext | null>(null);

export const CatalogContextProvider = ({
    catalogResponse,
    children,
    navigationData,
    searchParams,
    selectedDepartmentId,
    selectedSubDepartmentId,
}: TProps) => {
    const { t } = useTranslation('catalog');
    // Context data
    const { locale, router } = useAppContext();

    const { setPageType } = useCatalogStore();

    // Locally stored query object (an alternative is to use catalogResponse's active filters)
    const initialFilters = QueryString.parse((searchParams as string) || '', {
        comma: true,
    }) as TIncomingFilter;

    const getCorrectedUrlFilters = useCallback(
        (filters: TIncomingFilter) => {
            if (!filters?.f) return {};

            const newFilters = Object.entries(filters.f).reduce((acc, [code, value]) => {
                const filterResponse = catalogResponse.nav?.filters?.find((option) => option.code === code);

                if (!filterResponse) return acc;
                if (filterResponse.type !== 'multiselect') return { ...acc, [code]: value };

                const options = filterResponse.data as MultiselectFilter[];

                if (!Array.isArray(options)) return acc;
                if (!options.length) return acc;

                const arrayedValue = Array.isArray(value) ? value : [value];
                const correctedValues = arrayedValue.filter(
                    (item) => !!options.find((option) => option?.code === item),
                );

                return { ...acc, [code]: correctedValues };
            }, {});

            return newFilters;
        },
        [catalogResponse],
    );

    const correctedUrlFilters = useMemo(() => getCorrectedUrlFilters(initialFilters), [initialFilters]);

    initialFilters.f = correctedUrlFilters;

    const [filtersFromUrl, setFiltersFromUrl] = useState(initialFilters); // { sort: by: 'price", f:{minprice: 123} }
    const [doApply, setDoApply] = useState(false);

    const [refreshedFiltersResponse, setRefreshedFiltersResponse] = useState<null | PlpFiltersResponse>(null);

    const initializeSelectedCategories = (response: CatalogResponse['nav'] | PlpFiltersResponse) => {
        const categoryTreeFromResponse = response?.filters?.find((facet) => facet.code === 'category')
            ?.data as Array<CategoryTreeResponse>;

        const selectedCatsFromResponse =
            categoryTreeFromResponse && extractSelectedCategoriesFromTree(categoryTreeFromResponse);

        if (selectedCatsFromResponse?.length > 0) {
            return selectedCatsFromResponse;
        }

        return [];
    };

    const [localSelectedCategories, setLocalSelectedCategories] = useState<Array<CategoryTreeResponse>>(
        initializeSelectedCategories(catalogResponse?.nav),
    );

    const setInitialSelectedCategories = (response: CatalogResponse['nav'] | PlpFiltersResponse) => {
        const selectedCatsFromResponse = initializeSelectedCategories(response);

        if (selectedCatsFromResponse?.length > 0) {
            setLocalSelectedCategories(selectedCatsFromResponse);
        }
    };

    // Because we need to route _after_ the state updates
    useEffect(() => {
        if (doApply) {
            applyFilters();
            setDoApply(false);
        }
    }, [doApply]);

    // Header part
    useEffect(() => {
        setPageType(catalogResponse?.type || '');

        return () => {
            setPageType('');
        };
    }, [catalogResponse]);

    useEffect(() => {
        const updatedFilters = QueryString.parse((searchParams as string) || '', { comma: true }) as TIncomingFilter;
        const correctedFiltersFromUrl = getCorrectedUrlFilters(updatedFilters);

        updatedFilters.f = correctedFiltersFromUrl;

        setFiltersFromUrl(updatedFilters);
        setRefreshedFiltersResponse(null);
    }, [searchParams, catalogResponse.canonicalUrl]);

    const trackFilterClick = ({ filterCode, newValue }: { filterCode: string; newValue: TNewFilters[string] }) => {
        trackEvent({
            event: 'filterClicked',
            filterKey: filterCode,
            filterValue: typeof newValue === 'string' ? newValue : JSON.stringify(newValue),
            pageType: 'listing',
        });
    };

    const trackFilterUnclick = ({ filterCode, newValue }: { filterCode: string; newValue: TNewFilters[string] }) => {
        trackEvent({
            event: 'filterUnclicked',
            filterKey: filterCode,
            filterValue: typeof newValue === 'string' ? newValue : JSON.stringify(newValue),
            pageType: 'listing',
        });
    };

    const updateSingleSelect = (
        currentFiltersFromUrl: TIncomingFilter,
        filterCode: string,
        newValue: string,
        trackFilter = false,
    ) => {
        // add filter if it doesn't already exist
        if (!currentFiltersFromUrl?.f?.[filterCode]) {
            if (trackFilter) trackFilterClick({ filterCode, newValue });

            return { [filterCode]: newValue };
        }

        const currentFilterValue = currentFiltersFromUrl.f[filterCode];

        // if the value exists --> clear the filter
        if (currentFilterValue === newValue) return { [filterCode]: undefined };

        if (trackFilter) trackFilterClick({ filterCode, newValue });
        return { [filterCode]: newValue };
    };

    const updateMultiSelect = (
        currentFiltersFromUrl: TIncomingFilter,
        filterCode: string,
        newValue: string | string[],
        { mode = 'toggle', trackFilter = false }: TUpdateFilterOptions = { trackFilter: false, mode: 'toggle' },
    ) => {
        // add filter if it doesn't already exist
        if (!currentFiltersFromUrl?.f?.[filterCode]) {
            if (trackFilter) trackFilterClick({ filterCode, newValue });

            return { [filterCode]: newValue };
        }

        const currentValue = currentFiltersFromUrl.f[filterCode];

        if (typeof currentValue !== 'string' && !Array.isArray(currentValue)) return currentFiltersFromUrl.f;

        const arrayedOldValue = Array.isArray(currentValue) ? currentValue : [currentValue];
        const arrayedNewValue = Array.isArray(newValue) ? newValue : [newValue];

        // eslint-disable-next-line no-param-reassign
        delete currentFiltersFromUrl?.f?.[filterCode];

        if (mode === 'add') {
            const uniqueMerge = Array.from(new Set([...arrayedOldValue, ...arrayedNewValue]));
            if (trackFilter) trackFilterClick({ filterCode, newValue });

            return { [filterCode]: uniqueMerge };
        }

        // removes duplicates and keeps unique ones
        const toggledUniqueMerge = [...arrayedOldValue, ...arrayedNewValue].filter(
            (item) => arrayedOldValue.includes(item) !== arrayedNewValue.includes(item),
        );

        if (toggledUniqueMerge.length && trackFilter) {
            trackFilterClick({ filterCode, newValue });
        }

        return { [filterCode]: toggledUniqueMerge };
    };

    const getUpdatedFiltersLink = (
        filters: TNewFilters,
        { mode = 'toggle' }: TUpdateFilterOptions = { mode: 'toggle' },
    ) => {
        // INFO: Deep clone because the initialFilters was getting mutated.
        const clonedInitialFilters = cloneDeep(initialFilters);
        const filterResponse = refreshedFiltersResponse?.filters || catalogResponse.nav?.filters;
        let result;

        Object.entries(filters).forEach(([filterCode, newValue]) => {
            const { type } = filterResponse?.find((_filter) => filterCode === _filter.code) || {};

            // 1. Single-select
            if (type === 'singleselect' && typeof newValue === 'string') {
                const newFilter = updateSingleSelect(clonedInitialFilters, filterCode, newValue);

                result = mergeWith(
                    { ...omit(clonedInitialFilters, `f.${filterCode}`), page: RESET_PAGE_NUMBER },
                    { f: newFilter },
                    handleArrays,
                );
            }

            // 2. Multi-select
            else if (type === 'multiselect' && (typeof newValue === 'string' || Array.isArray(newValue))) {
                const newFilter = updateMultiSelect(clonedInitialFilters, filterCode, newValue as string | string[], {
                    mode,
                });

                result = mergeWith(
                    { ...clonedInitialFilters, page: RESET_PAGE_NUMBER },
                    { f: newFilter },
                    handleArrays,
                );
            }

            // 3. Range, (newValue should be [min, max])
            else if (type === 'range' && newValue.length === 2) {
                const newFilter = { [filterCode]: { min: newValue[0], max: newValue[1] } };
                result = mergeWith({ ...clonedInitialFilters, page: RESET_PAGE_NUMBER }, { f: newFilter });
            }

            // 4. Bool, strange that the value is string not bool, but this is from BE
            else if (type === 'boolean' && typeof newValue === 'string') {
                if (newValue) {
                    result = mergeWith(
                        { ...clonedInitialFilters, page: RESET_PAGE_NUMBER },
                        { f: { [filterCode]: newValue } },
                    );
                } else {
                    result = omit(clonedInitialFilters, [`f.${filterCode}`, 'page']);
                }
            }
        });

        const url = getURL({ nextFilters: result });
        const urlWithLocale = `/${locale}${url}`;

        return urlWithLocale;
    };

    const updateFilters = (
        filters: TNewFilters,
        { apply = false, mode = 'toggle', shouldRefresh = false, trackFilter = true }: TUpdateFilterOptions = {
            apply: false,
            shouldRefresh: false,
            trackFilter: true,
            mode: 'toggle',
        },
    ) => {
        const filterResponse = refreshedFiltersResponse?.filters || catalogResponse.nav?.filters;
        const currentFiltersClone = structuredClone(filtersFromUrl);

        let result = {};

        Object.entries(filters).forEach(([filterCode, newValue]) => {
            const { type } = filterResponse?.find((_filter) => filterCode === _filter.code) || {};

            // 1. Single-select
            if (type === 'singleselect' && typeof newValue === 'string') {
                const newFilter = updateSingleSelect(currentFiltersClone, filterCode, newValue, trackFilter);

                result = mergeWith({ ...currentFiltersClone, page: RESET_PAGE_NUMBER }, { f: newFilter }, handleArrays);
            }

            // 2. Multi-select
            if (type === 'multiselect' && (typeof newValue === 'string' || Array.isArray(newValue))) {
                const newFilter = updateMultiSelect(currentFiltersClone, filterCode, newValue as string | string[], {
                    trackFilter,
                    mode,
                });

                result = mergeWith({ ...currentFiltersClone, page: RESET_PAGE_NUMBER }, { f: newFilter }, handleArrays);
            }

            // 3. Range
            else if (type === 'range' && newValue.length === 2) {
                if (trackFilter) trackFilterClick({ filterCode, newValue });

                const newFilter = { [filterCode]: { min: newValue[0], max: newValue[1] } };

                result = mergeWith({ ...currentFiltersClone, page: RESET_PAGE_NUMBER }, { f: newFilter });
            }

            // 4. Boolean, strange that the value is string not bool, but this is from BE
            else if (type === 'boolean' && typeof newValue === 'string') {
                if (newValue) {
                    if (trackFilter) trackFilterClick({ filterCode, newValue });

                    const newFilter = { [filterCode]: newValue };

                    result = mergeWith({ ...currentFiltersClone, page: RESET_PAGE_NUMBER }, { f: newFilter });
                } else {
                    result = omit(currentFiltersClone, [`f.${filterCode}`, 'page']);
                }
            }
        });

        setFiltersFromUrl(result);

        if (shouldRefresh) {
            fetchRefreshedFilters({
                uri: getURL({ nextFilters: result, shouldEncode: false }),
                prevFilters: currentFiltersClone,
            });
        }

        if (apply) {
            setDoApply(true);
        }
    };

    const changePage = (page: string): string => getURL({ nextFilters: { ...filtersFromUrl, page } });

    const applySort = (by: SearchSortBy, dir: SearchSortDir) => {
        setFiltersFromUrl((currentFiltersFromUrl) => ({
            ...currentFiltersFromUrl,
            sort: { by, dir },
            page: RESET_PAGE_NUMBER,
        }));
        setDoApply(true);
    };

    // This is a bit complex because I need to check and work with arrays / multi-select
    const clearFilters = (code: string | null, apply = false) => {
        if (code) {
            const target = code.split('.').slice(0, -1).join('.');
            const targetValue = get(filtersFromUrl, target);

            if (isArray(targetValue)) {
                const valueToRemove = code.split('.').at(-1);
                setFiltersFromUrl((currentFilters) => {
                    const currentFiltersClone = { ...currentFilters };
                    const newValue = without(get(currentFilters, target), valueToRemove);
                    set(currentFiltersClone, target, newValue);

                    return currentFiltersClone;
                });
            } else {
                setFiltersFromUrl((currentFilters) => omit(currentFilters, [code, 'page']));
            }
        } else {
            // When everything is cleared, only leave the search query
            setFiltersFromUrl((currentFilters) => pick(currentFilters, 'q'));
        }

        if (apply) {
            setDoApply(true);
        }
    };

    const { isLoading: isRefreshedFiltersLoading, mutate: fetchRefreshedFilters } = useMutation(
        [REACT_QUERY_KEYS.GET_PLP_FILTERS],
        ({ uri }: { uri: string; prevFilters: TIncomingFilter }) => getApiInstance().product.getFilters({ uri }),
        {
            onSuccess: (data, { prevFilters }) => {
                if (data.filters.length > 0) {
                    setRefreshedFiltersResponse(data);
                    setInitialSelectedCategories(data);
                } else {
                    toast.error(t('refresh-filters-no-result-error-toast'));
                    setFiltersFromUrl(prevFilters);
                }
            },
            onError: (error: ApiError) => {
                // just fail silently here
                console.error(error);
            },
        },
    );

    const resetFilterToInitialValue = (filterCode: string) => {
        const originalValue = initialFilters?.f?.[filterCode];
        setFiltersFromUrl((currentFilters) => {
            const currentFiltersClone = { ...currentFilters };
            set(currentFiltersClone, `f.[${filterCode}]`, originalValue);
            return currentFiltersClone;
        });
    };

    const getURL = ({
        nextFilters,
        nextPath = '',
        shouldEncode = true,
    }: {
        nextFilters?: TIncomingFilter;
        nextPath?: string;
        shouldEncode?: boolean;
    }) => {
        const newQueryString = QueryString.stringify(nextFilters || filtersFromUrl, {
            encode: shouldEncode, // ENCODE IS FALSE WHEN WE WANT TO FETCH, BUT TRUE WHEN WE WANT TO REPLACE URL
            indices: false,
            arrayFormat: 'comma',
        });

        const hasSearch = !!filtersFromUrl.q;

        const localPath = getPathFromSelectedCategoriesOrResponse(localSelectedCategories, hasSearch);
        let url = nextPath || localPath;
        if (newQueryString) {
            url = `${url}?${newQueryString}`;
        }
        return url;
    };

    const getPathFromSelectedCategoriesOrResponse = (
        selectedCategories: CategoryTreeResponse[],
        hasSearch?: boolean,
    ) => {
        if (selectedCategories.length > 0) {
            const canStack = refreshedFiltersResponse ? refreshedFiltersResponse.stack : catalogResponse.stack;
            const filterKey = extractFilterKeyFromPath(
                refreshedFiltersResponse?.canonicalUrl || catalogResponse.canonicalUrl,
            );

            const newPath = buildPathFromSelectedCategories(selectedCategories, canStack ? filterKey : '');

            return `${newPath}${hasSearch ? '/search/' : ''}`;
        }

        const [path] = (refreshedFiltersResponse?.canonicalUrl || catalogResponse.canonicalUrl).split('?');

        return path;
    };

    const updateLocalCategories = ({
        apply = false,
        shouldRefresh = false,
        updatedCategories,
    }: {
        apply?: boolean;
        updatedCategories: Array<CategoryTreeResponse>;
        shouldRefresh?: boolean;
    }) => {
        setLocalSelectedCategories(updatedCategories);

        const resetPage = { ...filtersFromUrl, page: RESET_PAGE_NUMBER };
        setFiltersFromUrl(resetPage);

        if (shouldRefresh) {
            const newPath = getPathFromSelectedCategoriesOrResponse(updatedCategories);
            const uri = getURL({
                nextFilters: resetPage,
                nextPath: newPath,
                shouldEncode: false,
            });
            fetchRefreshedFilters({ uri, prevFilters: filtersFromUrl });
        }

        if (apply) {
            setDoApply(true);
        }
    };

    const applyFilters = () => {
        router.replace(getURL({ shouldEncode: true }));
    };

    const clearRefreshedFiltersResponse = () => {
        setFiltersFromUrl(initialFilters);
        setRefreshedFiltersResponse(null);
        setInitialSelectedCategories(catalogResponse?.nav);
    };

    return (
        <CatalogContext.Provider
            value={{
                catalogResponse,
                updateFilters,
                clearFilters,
                getUpdatedFiltersLink,
                applyFilters,
                changePage,
                filters: filtersFromUrl,
                applySort,
                searchParams,
                listingPagehasNoProducts: 'hasNoProduct' in catalogResponse ? catalogResponse.hasNoProduct : undefined,
                navigationData,
                selectedDepartmentId,
                selectedSubDepartmentId,
                refreshedFiltersResponse,
                clearRefreshedFiltersResponse,
                isRefreshedFiltersLoading,
                localSelectedCategories,
                updateLocalCategories,
                resetFilterToInitialValue,
                trackFilterClick,
                trackFilterUnclick,
            }}
        >
            {children}
        </CatalogContext.Provider>
    );
};

// Convenience function
const handleArrays = (
    incomingValue: Array<string> | object,
    existingValue: Array<string> | object,
): Array<string> | undefined => {
    if (isArray(incomingValue) && isArray(existingValue)) {
        return union(incomingValue, existingValue);
    }
    return undefined;
};

const useCatalog = () => React.useContext(CatalogContext as React.Context<TContext>);

export default useCatalog;
