/* eslint-disable no-console */
import React, { useCallback } from 'react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import clsx from 'clsx';
import RenderIfVisible from 'react-render-if-visible';

import RenderOnView from '@nm-namshi-frontend/core/components/RenderOnView';
import { TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { isCMS } from '@nm-namshi-frontend/core/utils/helper';

import ModuleProductBox from '../internal/shared/ModuleProductBox';
import { TBannerModuleStrip } from '../../types/BannerModuleStrip';
import {
    TModule,
    TModuleProps,
    TModulesRendererProps,
    TPageCmsLayout,
    TSectionLayout,
} from '../../types/ModulesRenderer';
import styles from './ModulesRenderer.module.scss';
import ProductCarouselWidget from '../internal/ProductCarouselWidget';
import BannerModuleWidget from '../internal/BannerModuleWidget';
import BannerModuleStripWidget from '../internal/BannerModuleStripWidget';
import BannerSliderWidget from '../internal/BannerSliderWidget';
import BannerModalWidget from '../internal/BannerModalWidget';
import CrossSearchWidget from '../internal/CrossSearchWidget';
import ProductListWidget from '../internal/ProductListWidget';
import BannerModuleGroupWidget from '../internal/BannerModuleGroupWidget';
import RecentlyViewedWidget from '../internal/RecentlyViewedWidget';
import RecommendedProductsWidget from '../internal/RecommendedProductsWidget';
import SponsoredProductsWidget from '../internal/SponsoredProductsWidget';
import TextLinksWidget from '../internal/TextLinksWidget';
import VideoModule from '../internal/VideoModule';
import VideoModuleCarousel from '../internal/VideoModuleCarousel';
import HtmlModule from '../internal/HtmlModule';
import BannerModuleCarouselWidget from '../internal/BannerModuleCarouselWidget';
import RecentlySearchedWidget from '../internal/RecentlySearchedWidget';
import MidrollFilter from '../internal/MidrollFilter';
import BannerModuleScroller from '../internal/BannerModuleScroller';
// eslint-disable-next-line import/no-cycle
import CarouselLayout from './CarouselLayout';
// eslint-disable-next-line import/no-cycle
import TabsLayout from './TabsLayout';
import EasyBannerScroller from '../internal/EasyBannerScroller';
import { sanitizeDimension } from '../../utils/static';
import HeaderWidget from '../internal/HeaderWidget';
import PageInclude from '../internal/PageInclude';
import MetaConfig from '../internal/MetaConfig';
import YamlEditorModule from '../internal/YamlEditorModule';
import ProfileProgressBarModule from '../internal/ProfileProgressBarModule';

const DEFAULT_ITEM_HEIGHT = 200;
const INITIAL_RENDER_WIDGET_COUNT = 6;

// Box Diagram of the layers in ModulesRenderer with SectionLayouts

//           -----------------
//          | ModulesRenderer |
//          -------------------------------------------------------------------------------------------
//          |   ---------------                                                                         |
//          |  | SectionLayout |                                                                        |
//          |   -------------------------------------------------------------------------------------   |
//          |  |    --------                                 --------                                |  |
//          |  |   | Column |                               | Column |                               |  |
//          |  |    -------------------------------------    -------------------------------------   |  |
//          |  |   |   --------                          |  |   --------                          |  |  |
//          |  |   |  | Module |                         |  |  | Module |                         |  |  |
//          |  |   |   --------                          |  |   --------                          |  |  |
//          |  |   |                                     |  |                                     |  |  |
//          |  |   |                                     |  |                                     |  |  |
//          |  |   |                                     |  |                                     |  |  |
//          |  |    -------------------------------------    -------------------------------------   |  |
//          |   -------------------------------------------------------------------------------------   |
//           -------------------------------------------------------------------------------------------

// Box Diagram of the layers in ModulesRenderer with PageCmsLayout

//           -----------------
//          | ModulesRenderer |
//          -------------------------------------------------------------------------------------------
//          |   ---------------                                                                         |
//          |  | PageCmsLayout |                                                                        |
//          |   --------------------------------------------------------------------------------------  |
//          |  |    --------                                                                          | |
//          |  |   | Module |                                                                         | |
//          |  |    --------                                                                          | |
//          |  |                                                                                      | |
//          |  |    --------                                                                          | |
//          |  |   | Module |                                                                         | |
//          |  |    --------                                                                          | |
//          |  |                                                                                      | |
//          |   --------------------------------------------------------------------------------------  |
//           -------------------------------------------------------------------------------------------

const ModulesRenderer = ({
    analyticsData,
    catalogData,
    data,
    eventLocation,
    innerContainerStyles = {},
    language,
    renderModuleWrapper: ModuleWrapper,
}: TModulesRendererProps) => {
    const { isMobile } = useAppContext();
    const isPLP = eventLocation === 'plp';
    const isPDP = eventLocation === 'pdp';

    // The job of <ModulesRenderer> , explained in 3 steps:
    // 1. Check for empty catalog data
    if (!data || (Array.isArray(data) && data.length === 0)) {
        console.warn('ModulesRenderer: NO WIDGETS TO RENDER');
        return null;
    }

    // 2. Filter out data that are not renderer-compliant
    // NOTE: Data can be
    //  a. SectionLayouts (typically for catalog pages(eg. PLP, static) ie. a response of /_svc/catalog)
    //  b. PageCmsLayout (typically for non-catalog pages(eg. banners in /bag page) ie. a response of /_svc/catalog/cms)

    const layoutData = (data as TSectionLayout[])?.filter((renderData) => 'sectionLayout' in renderData);
    const pageCmsData = (data as TPageCmsLayout[])?.filter(
        (renderData) => 'modules' in renderData && Array.isArray(renderData.modules),
    );

    const renderDataArray = [...layoutData, ...pageCmsData];

    if (!renderDataArray?.length) return null;

    // 3. Map module data to ready-to-be-rendered components
    const renderedComponents = renderDataArray.map((renderData, renderDataIndex) => {
        const isSectionLayout = 'sectionLayout' in renderData;
        const isPageCmsLayout =
            'modules' in renderData && Array.isArray(renderData.modules) && renderData.modules.length > 0;

        if (isSectionLayout) {
            const sectionLayoutData = { ...renderData };
            const sectionLayoutIndex = renderDataIndex;
            const sectionLayoutType = sectionLayoutData?.sectionLayout;

            // As of now sectionLayoutType is always 'columns', but this structure ensures support for different types in the future
            if (sectionLayoutType === 'columns' && Array.isArray(sectionLayoutData.columns)) {
                const sectionLayoutId = `sectionLayout-${sectionLayoutIndex + 1}-type-${sectionLayoutType}`;

                // These styles can be onsite defined, hence we process them inline
                const sectionLayoutContainerStyle: React.CSSProperties = {
                    backgroundImage: sectionLayoutData.bgImage ? `url(${sectionLayoutData.bgImage})` : '',
                    backgroundColor: sectionLayoutData.bgColor,

                    // @TODO : Ehab - Revisit this
                    // opacity: sectionLayoutData.disabled ? 0.5 : 'unset',
                    // marginLeft: `${sectionLayoutData.sectionOuterSpacing?.left ?? 0}px`,
                    // marginRight: `${sectionLayoutData.sectionOuterSpacing?.right ?? 0}px`,
                };

                const isMultiColumn = sectionLayoutData.columns?.length > 1;

                const innerContainerStyle: React.CSSProperties = {
                    display: 'flex',
                    paddingTop: `${sectionLayoutData.outerSpacing?.top ?? 0}px`,
                    paddingBottom: `${sectionLayoutData.outerSpacing?.bottom ?? 0}px`,
                    overflow: 'hidden',
                    width: (sectionLayoutData.width && `${sectionLayoutData.width}%`) || '100%',
                    marginTop: `${sectionLayoutData.sectionOuterSpacing?.top ?? 0}px`,
                    marginBottom: `${sectionLayoutData.sectionOuterSpacing?.bottom ?? 0}px`,
                    ...(isMultiColumn ? innerContainerStyles : {}),
                };

                // NOTE: For sectionLayout: columns
                // Existing behavior is to take bgColor and bgImage for the entire sectionLayout, from the first column within it
                // This is inherited from widgets-v2
                // @TODO  - Might be a good idea to realign this with BE, to allow this to be defined by onsite on a sectionLayout level
                const firstColumnBgImage = sectionLayoutData.columns[0]?.bgImage;
                const firstColumnBgColor = sectionLayoutData.columns[0]?.bgColor;

                // Refer comment above - not ideal to pull sectionLayout styles from its column, but this can be as it is for CMS MVP
                if (firstColumnBgImage) {
                    sectionLayoutContainerStyle.backgroundImage = `url(${firstColumnBgImage})`;
                }
                if (firstColumnBgColor) {
                    sectionLayoutContainerStyle.backgroundColor = firstColumnBgColor;
                }

                return (
                    <RenderIfVisible
                        key={sectionLayoutId}
                        defaultHeight={DEFAULT_ITEM_HEIGHT}
                        initialVisible={sectionLayoutIndex < INITIAL_RENDER_WIDGET_COUNT}
                        stayRendered
                    >
                        <div className={sectionLayoutId} style={sectionLayoutContainerStyle}>
                            <div style={innerContainerStyle}>
                                {sectionLayoutData.columns.map((columnData, columnIndex) => {
                                    const { columnGap, padding, width } = columnData;
                                    const columnId = `column-${columnIndex + 1}`;

                                    // Inheriting from ComponentColumn in widgets-v2
                                    const sanitizedGap = sanitizeDimension(columnGap);
                                    const columnStyles: React.CSSProperties = {
                                        flex: ' 0 1 auto',
                                        position: 'relative',
                                        width: width || '100%',
                                        padding: `${sanitizedGap} 0`,
                                        columnGap: padding,
                                        marginTop: sanitizedGap,
                                        marginBottom: sanitizedGap,
                                    };

                                    return (
                                        <div key={columnId} className={clsx(columnId)} style={columnStyles}>
                                            {columnData.modules?.map((moduleData: TModule, moduleIndex) => {
                                                const { type } = moduleData;
                                                const moduleId = `module-${moduleIndex + 1}-type-${type}`;

                                                const analytics = {
                                                    pageType: eventLocation as TPageType,
                                                    row: sectionLayoutIndex,
                                                    column: columnIndex,
                                                    ...analyticsData,
                                                };

                                                return (
                                                    <Module
                                                        key={moduleId}
                                                        analyticsData={analytics}
                                                        moduleData={moduleData}
                                                        moduleId={moduleId}
                                                        catalogData={catalogData}
                                                        language={language}
                                                        isSiteWidth={!isPLP && !isMobile && !isPDP}
                                                    />
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </RenderIfVisible>
                );
            }
        } else if (isPageCmsLayout) {
            const { modules } = renderData as TPageCmsLayout;

            return (
                <div key={`${renderDataIndex + 1}`}>
                    {modules.map((moduleData: TModule, moduleIndex) => {
                        const { type } = moduleData;
                        const moduleId = `module-${moduleIndex + 1}-type-${type}`;

                        const renderModule = () => (
                            <Module
                                key={moduleId}
                                analyticsData={{
                                    pageType: eventLocation as TPageType,
                                    row: moduleIndex,
                                    column: 0,
                                }}
                                moduleData={moduleData}
                                moduleId={moduleId}
                                catalogData={catalogData}
                                language={language}
                                isSiteWidth={!isPLP && !isMobile && !isPDP}
                            />
                        );

                        if (ModuleWrapper)
                            return (
                                <ModuleWrapper key={moduleId} moduleData={moduleData}>
                                    {renderModule()}
                                </ModuleWrapper>
                            );

                        return renderModule();
                    })}
                </div>
            );
        }

        return <></>;
    });

    return (
        <div className={clsx(styles.modulesRendererWrapper, { [styles.mobile]: isMobile })}>{renderedComponents}</div>
    );
};

export const Module: React.FC<TModuleProps> = ({ analyticsData, isSiteWidth, moduleData }) => {
    // This component encapsulates WidgetContainer(from widgets-v2) and the Module itself
    const { bgColor, bgImage, outerSpacing, type } = moduleData;
    const { isMobile } = useAppContext();

    const renderModuleLayout: React.FC<{ children: React.ReactNode }> = useCallback(({ children }) => {
        const outerStyles: React.CSSProperties = {
            ...(bgImage ? { background: `url(${bgImage})`, backgroundSize: 'cover' } : {}),
            ...(bgColor ? { backgroundColor: bgColor } : {}),
        };

        const innerStyles: React.CSSProperties = {
            ...(outerSpacing?.top
                ? { paddingTop: outerSpacing?.top && `${sanitizeDimension(outerSpacing?.top)}px` }
                : {}),
            ...(outerSpacing?.bottom
                ? { paddingBottom: outerSpacing?.bottom && `${sanitizeDimension(outerSpacing?.bottom)}px` }
                : {}),
        };

        return (
            <div style={outerStyles}>
                <div
                    className={clsx({
                        siteWidthContainer: !isCMS() && isSiteWidth,
                    })}
                    style={innerStyles}
                >
                    {children}
                </div>
            </div>
        );
    }, []);

    // Renderer
    const renderModule = () => {
        switch (type) {
            case 'productBox':
                return <ModuleProductBox product={moduleData.product} analyticsData={analyticsData} />;

            case 'midrollFilter':
                return <MidrollFilter moduleData={moduleData} />;

            case 'productCarousel': {
                return (
                    <ProductCarouselWidget
                        analyticsData={analyticsData}
                        moduleData={moduleData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );
            }

            case 'bannerModule': {
                return (
                    <BannerModuleWidget
                        analyticsData={analyticsData}
                        moduleData={moduleData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );
            }

            case 'bannerModuleStrip':
            case 'bannerModuleStripTimer':
                return (
                    <BannerModuleStripWidget
                        analyticsData={analyticsData}
                        moduleData={moduleData as TBannerModuleStrip}
                        renderModuleLayout={renderModuleLayout}
                    />
                );

            case 'bannerSlider': {
                return (
                    <BannerSliderWidget
                        analyticsData={analyticsData}
                        moduleData={moduleData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );
            }

            case 'bannerModuleCarousel': {
                return (
                    <BannerModuleCarouselWidget
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );
            }

            case 'productList': {
                return (
                    <ProductListWidget
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );
            }

            case 'profileProgressBar': {
                return (
                    <ProfileProgressBarModule
                        showEmail
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                        moduleSize={isMobile ? 'badge' : 'desktop'}
                    />
                );
            }

            case 'bannerModal': {
                return <BannerModalWidget moduleData={moduleData} />;
            }

            case 'videoModule': {
                return <VideoModule moduleData={moduleData} renderModuleLayout={renderModuleLayout} />;
            }

            case 'bannerModuleGroup': {
                return (
                    <BannerModuleGroupWidget
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );
            }

            case 'recentlyViewed': {
                return (
                    <RecentlyViewedWidget
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );
            }

            case 'recommendedProducts':
                return (
                    <RecommendedProductsWidget
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );

            case 'sponsoredProducts':
                return (
                    <RenderOnView placeholderHeight="400px">
                        <SponsoredProductsWidget
                            moduleData={moduleData}
                            analyticsData={analyticsData}
                            renderModuleLayout={renderModuleLayout}
                        />
                    </RenderOnView>
                );

            case 'textLinks':
                return <TextLinksWidget moduleData={moduleData} renderModuleLayout={renderModuleLayout} />;

            case 'html':
                return <HtmlModule moduleData={moduleData} renderModuleLayout={renderModuleLayout} />;

            case 'videoCarousel':
                return isCMS() ? (
                    <VideoModuleCarousel
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                ) : null;

            case 'recentSearchCarousel':
                return (
                    <RecentlySearchedWidget
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );

            case 'bannerModuleScroller':
                return (
                    <BannerModuleScroller
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );

            case 'carouselLayout':
                return <CarouselLayout layout={moduleData} analyticsData={analyticsData} />;

            case 'tabsLayout':
                return (
                    <TabsLayout
                        layout={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );

            case 'crossSearchCarousel':
                return <CrossSearchWidget moduleData={moduleData} analyticsData={analyticsData} />;

            case 'easyBannerScroller':
                return (
                    <EasyBannerScroller
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );

            case 'header':
                return (
                    <HeaderWidget
                        moduleData={moduleData}
                        analyticsData={analyticsData}
                        renderModuleLayout={renderModuleLayout}
                    />
                );

            case 'pageInclude':
                return isCMS() ? <PageInclude moduleData={moduleData} /> : null;

            case 'metaConfig':
                return isCMS() ? <MetaConfig moduleData={moduleData} /> : null;

            default:
                console.warn('ModulesRenderer: UNKNOWN MODULEID REQUESTED', type);

                if (isCMS()) return <YamlEditorModule moduleData={moduleData} />;
                return <></>;
        }
    };

    return renderModule();
};

export default ModulesRenderer;
