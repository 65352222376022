import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { GroupedBooleanFilter, SearchFilterResponse } from '@nm-namshi-frontend/services';
import Toggle from '@nm-namshi-frontend/core/components/forms/Toggle';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useCatalogStore from '@nm-namshi-frontend/core/stores/useCatalogStore';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './MySizesToggle.module.scss';
import AlteredLink from '../shared/AlteredLink';
import EmptySizesModal from './EmptySizesModal';

type TGroupedBooleanFilter = SearchFilterResponse & { data: GroupedBooleanFilter };

type TProps = {
    facet: TGroupedBooleanFilter;
    onProfileSizesEdited: () => void;
};

const MySizesToggle: React.FC<TProps> = ({ facet, onProfileSizesEdited }) => {
    const { t } = useTranslation('catalog');
    const { locale } = useAppContext();
    const { getUpdatedFiltersLink, isRefreshedFiltersLoading, trackFilterClick, trackFilterUnclick, updateFilters } =
        useCatalog();
    const { setEditSizesModal } = useCatalogStore();

    const [isEmptySizesModalOpen, setIsEmptySizesModalOpen] = useState(false);
    const [isEmptySizesAlertOpen, setIsEmptySizesAlertOpen] = useState(false);

    const { code, data, isSelected, name } = facet;
    const { selections } = data;

    const isEmpty = !selections.length;

    const filters = selections.reduce((acc, item) => ({ ...acc, [item.code]: item.selectionData }), {});
    const filterLink = getUpdatedFiltersLink(filters);

    const onSelect = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (isEmpty) {
            setIsEmptySizesAlertOpen(true);
            return;
        }

        if (isSelected) {
            trackFilterUnclick({
                filterCode: `quickFilter-${code}`,
                newValue: `quickFilter-${code}`,
            });
        } else {
            trackFilterClick({
                filterCode: `quickFilter-${code}`,
                newValue: `quickFilter-${code}`,
            });
        }

        updateFilters(filters, {
            apply: false,
            shouldRefresh: true,
            trackFilter: false,
            mode: isSelected ? 'toggle' : 'add',
        });
    };

    const handleEditPreferences = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setEditSizesModal({
            show: true,
            onSuccess: () => {
                setIsEmptySizesAlertOpen(false);
                onProfileSizesEdited();
            },
        });
    };

    const renderPreferencesButton = () => (
        <button className={styles.editPreferencesBtn} type="button" onClick={handleEditPreferences} />
    );

    return (
        <>
            <EmptySizesModal open={isEmptySizesModalOpen} onClose={() => setIsEmptySizesModalOpen(false)} />
            <div>
                <AlteredLink locale={locale} href={filterLink} onClick={onSelect} disabled className={styles.container}>
                    <Toggle
                        id={`${facet.code}-facet`}
                        checked={!!isSelected}
                        disabled={isRefreshedFiltersLoading}
                        containerClass={styles.toggle}
                        iconPosition="after"
                        inline
                    >
                        {name}
                        <div className={styles.editPreferences}>
                            {isEmpty ? (
                                <Trans
                                    t={t}
                                    i18nKey="addSizes"
                                    components={{
                                        button: renderPreferencesButton(),
                                        icon: <Icon name="editDark" size={12} />,
                                    }}
                                />
                            ) : (
                                <Trans
                                    t={t}
                                    i18nKey="manageSizes"
                                    components={{
                                        button: renderPreferencesButton(),
                                        icon: <Icon name="editDark" size={12} />,
                                    }}
                                />
                            )}
                        </div>
                    </Toggle>
                </AlteredLink>
                {isEmptySizesAlertOpen && (
                    <div className={styles.emptySizesAlert}>
                        <Icon name="dsInfoSquare" size={18} /> {t('emptySizesAlert')}
                    </div>
                )}
            </div>
        </>
    );
};

export default MySizesToggle;
