import { toast } from 'react-toastify';

import { Brand } from '@nm-namshi-frontend/services';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import useDepartmentStoreV2 from '@nm-namshi-frontend/core/stores/useDepartmentStoreV2';
import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';

type TArgs = {
    invalidate?: QueryClient['invalidateQueries'];
    onSuccess?: (response: Brand) => void;
};

const useToggleFollowBrand = ({ invalidate, onSuccess }: TArgs) => {
    const { setAuthModal } = useLayoutStore();
    const customerData = useCustomerStore((store) => store.customerData);

    const selectedDepartmentOrSubDepartmentId = useDepartmentStoreV2(
        (store) => store.selectedDepartmentOrSubDepartmentId,
    );

    const { isLoading, mutateAsync: doToggleFollow } = useMutation(
        [REACT_QUERY_KEYS.TOGGLE_FOLLOW_BRAND],
        (requestBody: { isFollowing: boolean; brandCode: string }) =>
            getApiInstance().brand.updateFollow({
                requestBody: {
                    brandCode: requestBody.brandCode,
                    isFollowing: requestBody.isFollowing,
                    category: selectedDepartmentOrSubDepartmentId,
                },
            }),
        {
            onSuccess: (response) => {
                onSuccess?.(response);
                return invalidate?.();
            },
            onError: (err: any) => {
                toast.error(err?.body.error);
            },
        },
    );

    const toggleFollow = (args: { brandCode: string; isFollowing: boolean }): Promise<any> => {
        if (!customerData) {
            setAuthModal({ show: true, onSuccess: () => doToggleFollow(args) });
            return undefined as any;
        }

        return doToggleFollow(args);
    };

    return { toggleFollow, isLoading };
};

export default useToggleFollowBrand;
