/* eslint-disable css-modules/no-unused-class */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './InformationDrawer.module.scss';

type TInformationDrawer = {
    children: JSX.Element;
    className?: string;
    headingClassName?: string;
    heading: string;
    headingCta?: string | null;
    headingCtaAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isOpen?: boolean;
};

const InformationDrawer: React.FC<TInformationDrawer> = ({
    children,
    className = '',
    heading,
    headingClassName = '',
    headingCta,
    headingCtaAction,
    isOpen = false,
}: TInformationDrawer) => {
    const [isExpanded, setIsExpanded] = useState(isOpen);

    useEffect(() => {
        setIsExpanded(isOpen);
    }, [isOpen]);

    return (
        <section className={clsx(className, styles.container)}>
            <div className={styles.headerContainer}>
                <button
                    type="button"
                    className={clsx(styles.header, headingClassName)}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <h2 className={styles.headingText}>{heading}</h2>

                    <Icon size={14} name={isExpanded ? 'chevronUp' : 'chevronDown'} />
                </button>
                {headingCta && headingCtaAction && (
                    <button className={styles.headingCta} onClick={headingCtaAction}>
                        {headingCta}
                    </button>
                )}
            </div>
            <div className={clsx(styles.content, { [styles.visible]: isExpanded })}>{isExpanded && children}</div>
        </section>
    );
};

export default InformationDrawer;
