import React from 'react';

import Carousel from '@nm-namshi-frontend/core/components/Carousel';

import { sanitizeDimension } from '../../utils/static';
import { BannerContent, ModuleHeader } from './shared';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';
import { TBannerModuleScroller } from '../../types/BannerModuleScroller';
import { TBanner } from '../../types/common';

type TProps = {
    moduleData: TBannerModuleScroller;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const BannerModuleScroller: React.FC<TProps> = (props) => {
    const { analyticsData, moduleData, renderModuleLayout: ModuleLayout } = props;

    const {
        banners = [],
        innerSpacing = 0,
        moduleHeader,
        numPerView = 5,
        rowsPerGroup = 2,
        textColor,
        type,
        widgetId,
    } = moduleData || {};

    const renderGroup = (group: TBanner[], idx: number) => (
        <div key={idx}>
            {group.map((banner, bannerIndex) => {
                const paddingBottom = bannerIndex >= banners.length - numPerView ? 0 : sanitizeDimension(innerSpacing);

                return (
                    <div key={banner.imageUrl} style={paddingBottom ? { paddingBottom } : {}}>
                        <BannerContent widgetId={widgetId} bannerData={banner} />
                    </div>
                );
            })}
        </div>
    );

    if (!banners || !banners.length) return null;

    const groups = banners
        .map((_banner, index) => (index % rowsPerGroup === 0 ? banners.slice(index, index + rowsPerGroup) : null))
        .filter(Boolean) as TBanner[][];

    return (
        <ModuleLayout>
            <ModuleHeader
                moduleHeader={moduleHeader}
                textColor={textColor}
                analyticsData={{
                    ...analyticsData,
                    contentModuleType: type,
                    widgetId,
                    column: 0,
                }}
            />
            <Carousel innerSpacing={innerSpacing} numPerView={numPerView}>
                {groups.map(renderGroup)}
            </Carousel>
        </ModuleLayout>
    );
};

export default BannerModuleScroller;
