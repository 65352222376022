import React from 'react';

import clsx from 'clsx';

import styles from './LoaderBounce.module.scss';

type TProps = {
    color?: 'dark' | 'mid' | 'light' | 'theme';
    size?: number;
};

const LoaderBounce = ({ color = 'theme', size = 8 }: TProps): JSX.Element => {
    let dotColor;
    switch (color) {
        case 'light':
            dotColor = 'var(--clr-white)';
            break;
        case 'mid':
            dotColor = 'var(--color-grey2)';
            break;
        case 'theme':
            dotColor = 'var(--clr-brand)';
            break;
        default:
            dotColor = 'var(--clr-dark)';
    }

    const dotStyle = { width: `${size}px`, height: `${size}px`, margin: `${size / 4}px`, backgroundColor: dotColor };
    return (
        <div className={styles.loader}>
            <div className={clsx(styles.dot, styles.bounce1)} style={dotStyle} />
            <div className={clsx(styles.dot, styles.bounce2)} style={dotStyle} />
            <div className={styles.dot} style={dotStyle} />
        </div>
    );
};

export default LoaderBounce;
