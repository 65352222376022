import React from 'react';

import ProductCarousel from '@nm-namshi-frontend/core/page_components/shared/product/carousel/ProductCarousel';
import useGetProducts from '@nm-namshi-frontend/widgets/src/hooks/useGetProducts';

import { ProductBoxVariant } from '../../types/common';
import { ModuleHeader } from './shared';
import { TProductCarouselWidget } from '../../types/CarouselWidgets';
import { TModuleAnalytics, TModuleLayout } from '../../types/ModulesRenderer';

type TProps = {
    moduleData: TProductCarouselWidget;
    analyticsData: TModuleAnalytics;
    renderModuleLayout: TModuleLayout;
};

const ProductCarouselWidget: React.FC<TProps> = ({ analyticsData, moduleData, renderModuleLayout: ModuleLayout }) => {
    const { moduleHeader, productUrl, textColor, type, widgetId } = moduleData;

    const { data, isLoading } = useGetProducts({ uri: productUrl });
    const products = data?.products || [];

    if (!productUrl) return null;
    if (!isLoading && !products.length) return null;

    return (
        <ModuleLayout>
            <ModuleHeader
                isLoading={isLoading}
                moduleHeader={moduleHeader}
                textColor={textColor}
                analyticsData={{
                    ...analyticsData,
                    contentModuleType: type,
                    widgetId,
                    column: 0,
                }}
            />
            <ProductCarousel
                analyticsData={{ ...analyticsData, widgetId }}
                products={products}
                variant={ProductBoxVariant.FULL}
                isLoading={isLoading}
                textColor={textColor}
            />
        </ModuleLayout>
    );
};

export default ProductCarouselWidget;
